import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	CircularProgress,
	Container,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import {
	selectOrganizationSsoAndScim
} from "../../../redux/userSlice";
import DataGridCustomNoRowsOverlay from "../../global/components/datagrid-custom-norows-overlay";
import SearchInput from "../../global/components/search-input";
import ThreeDotMenu from "../../global/components/three-dot-menu";
import {
	CoAppButtonGreenBg,
	CoAppDataGrid,
	CoAppGroupList,
	CoAppH2,
	CoAppMainContainer,
} from "../../global/styled/global.styled";
import { SubMenuContainer } from "../styled/settings.styled";

import CreateGroupDialog from "./create-group-dialog";

export default function GroupManagement() {
	let authHeader = useAuthHeader();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [groupSearchInput, setGroupSearchInput] = useState("");
	const [groupSeed, setGroupSeed] = useState(0);
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const [organizationGroupsLoaded, setOrganizationGroupsLoaded] = useState(false);
	const [organizationGroups, setOrganizationGroups] = useState([]);
	const [groupCreateDialogOpen, setGroupCreateDialogOpen] = useState(false);
	const [createGroupErrorMessage, setCreateGroupErrorMessage] = useState("");
	const [groupName, setGroupName] = useState("");

	const columns = [
		{
			field: "name",
			headerClassName: "data-grid-header",
			headerName: "NAME",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<div>
					<Typography sx={{ fontWeight: "600" }}>{params.value}</Typography>
				</div>
			),
		},
		{
			field: "users",
			headerClassName: "data-grid-header",
			headerName: "USERS",
			editable: false,
			flex: .5,
			renderCell: (params) => <div>{params.value.length}</div>,
		},
		{
			field: "rules",
			headerClassName: "data-grid-header",
			headerName: "RULES",
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<div>
					{params.value && params.value.length > 0 ? (
						<>
							<CoAppGroupList sx={{ width: "450px" }} title={params.value.map(item => item.name).join(", ")}>
								{params.value.map(item => item.name).join(", ")}
							</CoAppGroupList>
						</>
					) : (
						<Typography>No Rules</Typography>
					)}
				</div>
			),
		},
		{
			field: "plugins",
			headerClassName: "data-grid-header",
			headerName: "PLUGINS",
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<div>
					{params.value && params.value.length > 0 ? (
						<>
							<CoAppGroupList sx={{ width: "450px" }} title={params.value.map(item => item.name).join(", ")}>
								{params.value.map(item => item.name).join(", ")}
							</CoAppGroupList>
						</>
					) : (
						<Typography>No Plugins</Typography>
					)}
				</div>
			),
		},
		...(organizationSsoAndScim
			? [
				{
					field: "idpgroups",
					headerClassName: "data-grid-header",
					headerName: "IDP GROUPS",
					editable: false,
					flex: 1.25,
					renderCell: (params) => (
						<div>
							{params.value && params.value.length > 0 ? (
								<>
									<CoAppGroupList sx={{ width: "450px" }} title={params.value.map(item => item.name).join(", ")}>
										{params.value.map(item => item.name).join(", ")}
									</CoAppGroupList>
								</>
							) : (
								<Typography>No Mapped Groups</Typography>
							)}
						</div>
					),
				},
			]
			: []),
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Edit",
								optionClickHandler: () => {
									handleOpenGroupEditPage({ params });
								},
							},
							{
								name: "Duplicate",
								optionClickHandler: () => {
									duplicateGroup(params.row.id);
								},
							},
							{
								name: "Delete",
								optionClickHandler: () => {
									deleteGroup(params.row.id);
								},
							},
						]}
					/>
				</div>
			),
		},
	];

	const createNewGroup = () => {
		let groupJSON = {
			"groupName": groupName,
		};
		axios
			.post(apiRoutes.createGroup, groupJSON, { headers: authHeader })
			.then(() => {
				setGroupSeed(Math.random());
				setGroupCreateDialogOpen(false);
				setGroupName("");
				setCreateGroupErrorMessage("");
				dispatch(setMessage(messages.GROUP_CREATED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((err) => {
				console.log(err);
				if (err.response.data.message.includes("already exists")) {
					setCreateGroupErrorMessage(messages.GROUP_NAME_ALREADY_EXISTS_ERROR_MSG);
					return;
				}
				dispatch(setMessage(messages.GROUP_CREATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const handleToggleGroupCreateDialog = () => {
		setGroupCreateDialogOpen(!groupCreateDialogOpen);
		setGroupName("");
		setCreateGroupErrorMessage("");
	};

	const handleGroupNameChange = (e) => {
		setGroupName(e.target.value);
		setCreateGroupErrorMessage("");

	};

	const deleteGroup = (groupId) => {
		axios
			.delete(apiRoutes.deleteGroup + "/" + groupId, {
				headers: authHeader,
			})
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(messages.GROUP_DELETED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setGroupSeed(Math.random());
				} else {
					dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const duplicateGroup = (groupId) => {
		let group = organizationGroups.find((group) => group.id === groupId);
		let groupJson = {
			"name": group.name + " (copy)" + Math.floor(Math.random() * 1000),
		};
		axios
			.post(apiRoutes.copyGroup + "/" + groupId, groupJson, { headers: authHeader })
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(messages.GROUP_CREATED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setGroupSeed(Math.random());
				} else {
					dispatch(setMessage(messages.GROUP_CREATION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch(setMessage(messages.GROUP_CREATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR
				));
			});
	};

	const handleOpenGroupEditPage = (rowObject) => {
		navigate(pages.group(rowObject.params.id));
	};

	const initGroupManagement = () => {
		let groupQueryObject = {
			name: groupSearchInput,
		};
		axios
			.get(apiRoutes.getGroups, {
				headers: authHeader,
				params: groupQueryObject,
			})
			.then((res) => {
				setOrganizationGroups(res.data);
				setOrganizationGroupsLoaded(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		initGroupManagement();
	}, [groupSearchInput, groupSeed]);

	if (organizationGroupsLoaded) {
		return (
			<>
				<CoAppMainContainer sx={{ p: 2, width: "95%" }}>
					<SubMenuContainer>
						<CoAppH2 sx={{ display: "inline", marginRight: "50px" }}>Groups</CoAppH2>

						{
							organizationGroups.length > 0 && (
								<SearchInput
									placeholder="Search groups"
									onChangeHandler={setGroupSearchInput} />
							)
						}

						<CoAppButtonGreenBg
							onClick={handleToggleGroupCreateDialog}
							sx={{ float: "right", minWidth: "120px" }}
						>
							ADD GROUP
						</CoAppButtonGreenBg>
					</SubMenuContainer>

					{
						organizationGroups.length > 0 && (
							<Grid container spacing={1} justifyContent="stretch">
								<CoAppDataGrid
									columnHeaderHeight={45}
									columns={columns}
									disableRowSelectionOnClick
									getRowId={(row) => row.id}
									getRowClassName={(params) =>
										params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
									}
									initialState={{
										pagination: {
											paginationModel: { pageSize: 25, page: 0 },
										},
										pinnedColumns: { right: ["actions"] },
									}}
									pagination
									rows={organizationGroups}
								/>
							</Grid>
						)
					}

					{
						organizationGroups.length === 0 && (
							<DataGridCustomNoRowsOverlay message="No groups yet!" />
						)
					}
				</CoAppMainContainer>
				<CreateGroupDialog
					open={groupCreateDialogOpen}
					setOpen={setGroupCreateDialogOpen}
					errorMessage={createGroupErrorMessage}
					createGroup={createNewGroup}
					handleGroupNameChange={handleGroupNameChange}
					groupName={groupName}
					handleCancel={handleToggleGroupCreateDialog}
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}
