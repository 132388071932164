import { useState } from "react";
import { useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment, TextField } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import useAuthHeader from "../../helpers/useAuthHeader";
import useRuleFilter from "../../helpers/useRuleFilter";
import { setRules, setRulesDidLoadFlag, setRulesLoadError,setTotalPages } from "../../redux/ruleSlice";

export default function RuleSearchBar() {
	let authHeader = useAuthHeader();
	const updateAndGetRuleFilters = useRuleFilter();

	const [searchInput, setSearchInput] = useState("");
	const dispatch = useDispatch();

	const handleSearchSubmit = (e) => {
		e.preventDefault();
		let queryObj = updateAndGetRuleFilters("search", searchInput);

		dispatch(setRulesDidLoadFlag(false));
		axios.get(apiRoutes.getRules, {
			headers: authHeader,
			params: queryObj, paramsSerializer: { indexes: null }
		}).then(res => {
			dispatch(setRules(res.data.rules));
			dispatch(setTotalPages(res.data.pager.pages));
			dispatch(setRulesDidLoadFlag(true));
			if (res.data.rules.length === 0) {
				dispatch(setRulesLoadError("No rules found."));
			}
		}).catch(err => dispatch(setRulesLoadError(err.message)));
	};

	const handleSearchInputChange = (e) => {
		setSearchInput(e.target.value);
		let queryObj = updateAndGetRuleFilters("search", e.target.value);
		axios.get(apiRoutes.getRules, {
			headers: authHeader,
			params: queryObj, paramsSerializer: { indexes: null }
		}).then(res => {
			dispatch(setRulesLoadError(""));
			dispatch(setRules(res.data.rules));
			dispatch(setTotalPages(res.data.pager.pages));
			dispatch(setRulesDidLoadFlag(true));
			if (res.data.rules.length === 0) {
				dispatch(setRulesLoadError("No rules found."));
			}
		}).catch(err => {
			dispatch(setRulesLoadError(err.message));
		});

	};

	return (
		<Grid item xs={4} >
			<form onSubmit={handleSearchSubmit}>
				<TextField
					placeholder="Search Rules..."
					size="small"
					sx={{ float: "right", backgroundColor: "#FFFFFF", borderRadius: "20px" }}
					value={searchInput}
					onChange={handleSearchInputChange}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						),
						style: {
							borderRadius: "20px"
						}
					}}
					inputProps={{ maxLength: 500 }}
				/>
			</form>
		</Grid>
	);
}