import { Link } from "react-router-dom";
import { Container, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SettingMenuContainer = styled(Container)(() => ({
	maxWidth: "1800px!important",
}));

export const SubMenuContainer = styled("div")(() => ({
	display: "inline-flex",
	justifyContent: "space-between",
	verticalAlign: "top",
	width: "100%"
}));

export const SettingsDivider = styled(Divider)(() => ({
	width: "100%",
	margin: "10px auto",
}));

export const MainMenuContainer = styled("div")(() => ({
	display: "inline-flex",
	verticalAlign: "top",
}));

export const MainMenuItem = styled(Link)(({ theme }) => ({
	...theme.typography.body2,
	padding: "10px 20px 10px 20px",
	fontSize: "1rem",
	fontWeight: "600",
	textDecoration: "none",
	marginTop: "10px",
	color: "#7482A0",
	"&:hover": {
		color: "#CFD5DF",
		cursor: "pointer"
	},
	"&.active": {
		fontWeight: 700,
		color: "#2FBD70"
	}
}));