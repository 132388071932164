import { Button, Checkbox, IconButton, Input, InputLabel, Select, Stack, styled, Switch, TextField, Typography } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const CoAppMultiDropdownMenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const CoAppButtonGreenBg = styled(Button)(() => ({
	backgroundColor: "#2FBD70",
	color: "#FFFFFF",
	margin: "0px 10px 10px 0px",
	float: "right",
	":hover": {
		color: "#2FBD70",
		backgroundColor: "#E3E6EC",
	},
	textTransform: "capitalize",
	fontSize: "1rem",

	borderRadius: "10px",
	marginLeft: { "xl": "-25px" }
}));

export const CoAppButtonWhiteBg = styled(Button)(() => ({
	backgroundColor: "#FFFFFF",
	color: "#2FBC70",
	padding: "5px",
	margin: "0px 10px 10px 0px",
	float: "right",
	borderRadius: "10px",
	paddingBottom: "1px",
	paddingTop: "1px",
	":hover": {
		backgroundColor: "#E3E6EC",
	},
	textTransform: "capitalize"
}));

export const CoAppH1 = styled(Typography)(({ theme }) => ({
	backgroundColor: "#F8F9FA",
	...theme.typography.body2,
	textAlign: "left",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "2.3rem",
	fontWeight: "600",
}));

export const CoAppH2 = styled(Typography)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "left",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "1.3rem",
	fontWeight: "600",
	display: "inline"
}));

export const CoAppH4 = styled(Typography)(() => ({
	margin: "0",
	textAlign: "center",
	fontWeight: "400",
	fontSize: "1.25rem",
	lineHeight: "1.334",
	letterSpacing: "0em",
	padding: "5px",
	color: "rgba(0, 0, 0, 0.6)"
}));

export const CoAppH5 = styled(Typography)(() => ({
	margin: "0",
	textAlign: "center",
	fontWeight: "400",
	fontSize: ".75rem",
	lineHeight: "1.334",
	letterSpacing: "0em",
	padding: "5px",
	color: "rgba(0, 0, 0, 0.6)"
}));

export const CoAppDataGrid = styled(DataGridPremium)(() => ({
	backgroundColor: "#FFFFFF",
	width: "100%",
	minHeight: "500px",
	flexGrow: 1,
	"&.Mui-checked": {
		color: "#62DF99",
	},
	".MuiDataGrid-row.Mui-even": {
		backgroundColor: "#F9FAFB"
	},
	".data-grid-header": {
		color: "#000000",
		opacity: "60%",
		fontSize: "12px",
	},
	".MuiDataGrid-columnSeparator": {
		display: "none",
	},
	".MuiDataGrid-pinnedColumns--right": {
		boxShadow: "none",
	},
	".MuiDataGrid-pinnedColumnHeaders--right": {
		boxShadow: "none",
	},
}));

export const CoAppDataGridToolbarButton = styled(Button)(() => ({
	backgroundColor: "#FFFFFF",
	color: "#2FBC70",
	textDecoration: "underline",
	margin: "0px 10px 0px 0px",
	float: "right",
	borderRadius: "10px",
	padding: "5px",
	":hover": {
		backgroundColor: "#E3E6EC",
	},
}));

export const CoAppGroupList = styled("p")(() => ({
	fontSize: "12px",
	marginTop: "0px",
	color: "grey",
	width: "175px",
	overflow: "hidden",
	whiteSpace: "nowrap",
	textOverflow: "ellipsis",
}));

export const CoAppSearchContainer = styled("div")(({ theme }) => ({
	marginTop: "5px",
	marginLeft: "10px",
	width: "15%",
	[theme.breakpoints.up(1700)]: {
		marginLeft: "45px",
		marginRight: "77px"
	}
}));

export const CoAppSelect = styled(Select)(() => ({
	"&.MuiOutlinedInput-root": {
		"& fieldset": {
			border: "1px solid #A9A9A9"
		},
		"&:hover fieldset": {
			border: "1px solid #A9A9A9"
		},
		"&.Mui-focused fieldset": {
			border: "1px solid #A9A9A9"
		}
	},
	backgroundColor: "#FFFFFF",
	width: "250px",
	height: "50px"
}));

export const CoAppStack = styled(Stack)(() => ({
	margin: "auto",
	width: "95%"
}));

export const CoAppStackItem = styled("div")(() => ({
	borderRadius: "5px",
	backgroundColor: "#FFFFFF",
	boxShadow: "0px 2px 2px 2px #00000026",
	width: "auto",
	justifyContent: "flex-start",
	display: "flex",
	fontSize: "1rem",
	alignItems: "center",
	height: "69px",
	rowGap: "50px",
}));

export const CoAppInputLabel = styled(InputLabel)(() => ({
	"&.MuiInputLabel-root": {
		color: "#A9A9A9"
	}
}));

export const CoAppInlineInput = styled(Input)(() => ({
	color: "#A9A9A9",
	fontSize: "1.5rem",
	fontWeight: "600",
	marginRight: "50px",
}));

export const CoAppInlineInputHeader = styled(Typography)(({ theme }) => ({
	backgroundColor: "#F8F9FA",
	...theme.typography.body2,
	textAlign: "left",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "2.3rem",
	fontWeight: "600",
	"&:hover": {
		cursor: "pointer",
		color: "#7482A0"
	}
}));

export const CoAppMainContainer = styled("div")(({ theme }) => ({
	backgroundColor: "#F8F9FA",
	...theme.typography.body2,
	textAlign: "left",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "2.5rem",
	p: 2,
	margin: "auto",
	flexGrow: 1,
}));

export const CoAppCheckbox = styled(Checkbox)(() => ({
	color: "black",
	"&.Mui-checked": {
		color: "#2FBD70",
	}
}));

export const CoAppDialogTextField = styled(TextField)(() => ({
	margin: "10px",
	"& .MuiOutlinedInput-root": {
		"&.Mui-focused fieldset": {
			border: "1px solid #A9A9A9"
		},
	},
	"& label.Mui-focused": {
		color: "#A9A9A9"
	},
	"&:hover fieldset": {
		border: "1px solid #A9A9A9"
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "1px solid #A9A9A9",
		"&:hover fieldset": {
			border: "1px solid #A9A9A9"
		}
	}
}));

export const CoAppSaveButton = styled(Button)(({ theme }) => ({
	color: "white",
	backgroundColor: "#2FBC70",
	textTransform: "capitalize",
	height: "40px",
	margin: "10px",
	borderRadius: "10px",
	padding: "5px",
	fontWeight: "500",
	border: "1px solid #2FBC70",
	"&:hover": {
		backgroundColor: "white",
		border: "1px solid #2FBC70",
		color: "black",
	}
}));

export const CoAppCancelButton = styled(Button)(() => ({
	color: "#7482A0",
	backgroundColor: "#E3E6EC",
	textTransform: "capitalize",
	margin: "10px",
	height: "40px",
	fontWeight: "500",
	borderRadius: "10px",
	padding: "5px",
	border: "1px solid white",
	"&:hover": {
		backgroundColor: "white",
		color: "black",
		border: "1px solid #7482A0",
		fontWeight: "400"
	}
}));

export const CoAppDialogIconButton = styled(IconButton)(() => ({
	color: "#2FBC70"
}));

export const CoAppConfirmStackItemGroup = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	margin: "auto",
	borderRadius: "5px",
}));

export const CoAppConfirmStackItemGroupTitle = styled("div")((props) => ({
	fontSize: "1.5rem",
	fontWeight: "bold",
	marginBottom: "20px",
	marginTop: props.isfirst ? "0px" : "30px"
}));

export const CoAppConfirmContainer = styled("div")(() => ({
	display: "block",
	justifyContent: "center",
	alignItems: "center",
	width: "100%"
}));

export const CoAppConfirmStack = styled(Stack)(() => ({
	margin: "auto",
	borderRadius: "5px",
	boxShadow: "0px 2px 2px 2px #00000026",
	width: "100%",
	border: "1px solid #00000026",
}));

export const CoAppConfirmStackItemContent = styled("p")((props) => ({
	width: !props.hasvalue ? "700px" : "200px",
	textOverflow: !props.hasvalue ? "none" : "ellipsis",
	whiteSpace: "nowrap",
	overflow: "hidden"
}));

export const CoAppConfirmStackItem = styled("div")(({ itemtype }) => ({
	borderRadius: itemtype === "header" ? "5px 5px 0px 0px" : itemtype === "footer" ? "0px 0px 5px 5px" : "0px",
	backgroundColor: itemtype === "header" ? "#F7F8F9" : "#FFFFFF",
	width: "auto",
	position: "relative",
	display: "flex",
	fontSize: "1rem",
	alignItems: "center",
	height: "50px",
	borderBottom: "1px solid #00000026",
	paddingLeft: "15px",
	color: ["header", "footer"].includes(itemtype) ? "#000000" : "#A2A2A2",
}));

export const CoAppSettingsBackButton = styled(Button)(() => ({
	color: "#7482A0",
	textTransform: "none",
	width: "125px",
	height: "40px",
	"&:hover": {
		backgroundColor: "#EAF8F1",
		borderRadius: "10px"
	}
}));

export const CoAppStatusToggle = styled(Switch)(() => ({
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: "#2FBD70",
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: "#2FBD70",
	},
	pointerEvents: "auto"
}));

export const CoAppOpenItemRightArrow = styled(IconButton)(() => ({
	marginTop: "5px",
	":hover": {
		backgroundColor: "#DADCE0",
		cursor: "pointer",
		borderRadius: "50%",
		p: "5px"
	}
}));

export const CoAppBasicContainer = styled("div")(() => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
}));