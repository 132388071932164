import { Outlet } from "react-router";

import ResponsiveAppBar from "./components/header";

const WithNavRoute = () => {
	return (
		<>
			<ResponsiveAppBar />
			<Outlet />
		</>
	);
};

export default WithNavRoute;