import { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Tooltip } from "@mui/material";

import messages from "../../../constants/messages";
import { setRuleLocation } from "../../../redux/ruleWizardSlice";
import { CoAppButtonGreenBg } from "../../global/styled/global.styled";
import {
	RuleLocationCancel,
	RuleLocationCardDesc,
	RuleLocationCardPreview,
	RuleLocationCardStructure,
	RuleLocationCardTitle,
	RuleLocationPopover,
	RuleLocationPopoverContent,
	RuleLocationPopoverDesc,
	RuleLocationPopoverImage
} from "../styled/rule-location-card.styled";

export default function RuleLocationCard(props) {

	const dispatch = useDispatch();
	/**
	 * Location popover open/close state.
	 */
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [previewLocation, setPreviewLocation] = useState({});
	const [popoverAnchorElement, setPopoverAnchorElement] = useState(null);

	const setSelectedCard = (e) => {
		let locationState = { ...props };
		dispatch(setRuleLocation(locationState));
	};

	/**
	 * Handles state related to showing preview of location image. 
	 */
	const handlePopoverOpen = (e) => {
		e.stopPropagation();
		const locationToPreview = props.locations.filter(location => location.id === parseInt(e.target.id))[0];
		setPreviewLocation(locationToPreview);
		setPopoverOpen(true);
		setPopoverAnchorElement(e.currentTarget);
	};
	const handlePopoverClose = (e) => {
		e.stopPropagation();
		setPopoverOpen(false);
		setPopoverAnchorElement(null);
	};
	/**
	 * Handles location selection from popover for step 3 (trigger configuration). 
	 */
	const handleTriggerLocationSelection = (e) => {
		props.setLocationHandler(e);
		setPopoverOpen(false);
		setPopoverAnchorElement(null);
	};

	return (
		<Grid item xs={6} id={props.id} >
			<RuleLocationCardStructure selectedCard={props.selectedCard ? true : false} id={props.id} onClick={props.isTrigger ? handleTriggerLocationSelection : setSelectedCard}>
				<Tooltip title={messages.LOCATION_LARGE_PREVIEW_TOOLTIP} placement="top" arrow>
					<RuleLocationCardPreview
						id={props.id}
						component="img"
						alt="Location Preview"
						image={props.thumbnailUrl}
						onClick={handlePopoverOpen}
						hasTriggers={props.triggerCount ? true : false}
					>
					</RuleLocationCardPreview>
				</Tooltip>
				<RuleLocationPopover
					open={popoverOpen}
					onClose={handlePopoverClose}
					anchorReference="none"
					anchorEl={popoverAnchorElement}
				>
					<RuleLocationCancel onClick={handlePopoverClose} />
					<RuleLocationPopoverContent>
						<RuleLocationPopoverImage src={previewLocation.previewUrl} alt={props.description} onClick={(e) => e.stopPropagation()} />
						<RuleLocationPopoverDesc>
							Location Description: {props.description}
						</RuleLocationPopoverDesc>
						<CoAppButtonGreenBg sx={{ marginTop: "-20px" }} id={props.id}>Select Location</CoAppButtonGreenBg>
					</RuleLocationPopoverContent>
				</RuleLocationPopover>
				<RuleLocationCardTitle id={props.id}>{props.name}</RuleLocationCardTitle>
				<RuleLocationCardDesc id={props.id}>{props.description}</RuleLocationCardDesc>
			</RuleLocationCardStructure>
		</Grid>
	);
}