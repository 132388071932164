import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mui/material";

import {
	selectCurrentPage, selectRowsPerPage,
	selectRuleFilters, 	selectTotalPages, setCurrentPage, setRowsPerPage, setRuleFilters,
	setRuleGridUpdateSeed,
	setRulesDidLoadFlag
} from "../../redux/ruleSlice";

import { RuleLibraryPaginationContainer, RuleLibraryPaginationTotalRows, RuleLibraryTotalRowsContainer } from "./styled/rule-library.styled";

export default function RulePagination(props) {
	const dispatch = useDispatch();
	const currentPage = useSelector(selectCurrentPage);
	const rowsPerPage = useSelector(selectRowsPerPage);
	const totalPages = useSelector(selectTotalPages);
	const ruleFilters = useSelector(selectRuleFilters);

	const handlePaginationChange = (e, page) => {
		e.preventDefault();
		let queryObj = {
			...ruleFilters,
			page: page,
			pageSize: rowsPerPage
		};
		dispatch(setCurrentPage(page));
		dispatch(setRuleFilters(queryObj));
		dispatch(setRulesDidLoadFlag(false));
		dispatch(setRuleGridUpdateSeed(Math.random()));
	};

	const handleChangeRowsPerPage = (e) => {
		e.preventDefault();
		const rowsPerPage = parseInt(e.target.value);
		setRowsPerPage(rowsPerPage);
		dispatch(setRowsPerPage(rowsPerPage));
		dispatch(setCurrentPage(1));
		dispatch(setRulesDidLoadFlag(false));

		let queryObj = {
			...ruleFilters,
			pageSize: rowsPerPage
		};
		setRuleFilters(queryObj);
		dispatch(setRuleFilters(queryObj));
		dispatch(setRuleGridUpdateSeed(Math.random()));
	};

	return (
		<RuleLibraryPaginationContainer>
			{totalPages && totalPages.length > 1 &&
				<RuleLibraryTotalRowsContainer>
					<RuleLibraryPaginationTotalRows>Items Per Page:</RuleLibraryPaginationTotalRows>
					<select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
						<option value={10}>10</option>
						<option value={20}>20</option>
						<option value={50}>50</option>
					</select>
				</RuleLibraryTotalRowsContainer>
			}

			{totalPages && totalPages.length > 1 &&
				<Pagination
					count={totalPages.length}
					onChange={handlePaginationChange}
					page={parseInt(currentPage)}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage} />
			}
		</RuleLibraryPaginationContainer>
	);
}