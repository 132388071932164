import { useSelector } from "react-redux";
import { CircularProgress, Container, Grid, Stack, Typography } from "@mui/material";

import { selectRuleDidLoadFlag, selectRuleLoadError, selectRules } from "../../redux/ruleSlice";

import RuleCard from "./rule-card";

export default function RuleGrid(props) {

	const rules = useSelector(selectRules);
	const ruleLoadError = useSelector(selectRuleLoadError);
	const rulesDidLoadFlag = useSelector(selectRuleDidLoadFlag);

	if (ruleLoadError) {
		return <Grid item xs={8}><Typography variant="h4" sx={{ margin: "auto" }}>{ruleLoadError}</Typography></Grid>;
	} else if (!rulesDidLoadFlag) {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	} else if (!rules || rules.length === 0) {
		return <Grid item xs={8}><Typography variant="h4" sx={{ margin: "auto" }}>No rules found.</Typography></Grid>;
	} else {
		return (
			<Grid item xs={8}>
				<Grid container spacing={6}>
					{rules.map(item =>
						<RuleCard
							key={item.id}
							id={item.id}
							ruleTitle={item.name}
							ruleText={item.description}
							state={item.active}
							tags={item.tags}
							groups={item.groups}
							draftStatus={item.isDraft}
							trashedStatus={item.isTrashed}
						/>
					)}
				</Grid>
			</Grid>
		);
	}
}