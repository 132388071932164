import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectOrganizationSsoAndScim } from "../../../../redux/userSlice";
import HeaderWithInlineEdit from "../../../global/components/header-with-inline-edit";
import { CoAppButtonWhiteBg, CoAppMainContainer } from "../../../global/styled/global.styled";
import SettingsMenuBack from "../../settings-menu-back";
import { MainMenuContainer, MainMenuItem, SettingMenuContainer, SettingsDivider } from "../../styled/settings.styled";

import GroupPlugins from "./group-plugins";
import GroupRules from "./group-rules";
import GroupSSO from "./group-sso";
import GroupUsers from "./group-users";

export default function Group() {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id } = useParams();

	const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] = useState(false);
	const [group, setGroup] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [groupNameInput, setGroupNameInput] = useState("");
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const [selectedMenuItem, setSelectedMenuItem] = useState(null);

	const menuItems = [
		{ key: "rules", label: "RULES", value: pages.groupRules },
		{ key: "plugins", label: "PLUGINS", value: pages.groupPlugins },
		{ key: "users", label: "USERS", value: pages.groupUsers },
		organizationSsoAndScim ? { key: "sso", label: "SSO/SCIM CONFIGURATION", value: pages.groupSSO } : null
	];

	const confirmGroupDeletion = () => {
		axios.delete(apiRoutes.deleteGroup + "/" + group.id, {
			headers: authHeader
		}).then((res) => {
			if (res.status === 200) {
				dispatch(setMessage(messages.GROUP_DELETED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				navigate(pages.settings);
			} else {
				console.log(res);
				dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			}
		}).catch((err) => {
			console.log(err);
			dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
		});
	};

	const handleMenuItemClick = (menuItem) => {
		setSelectedMenuItem(menuItem);
	};

	const handleGroupNameEdit = () => {
		setIsEditing(true);
		setGroupNameInput(group.name);
	};

	const handleGroupNameChange = (e) => {
		let value = e.target.value;

		if (value.trim() === "") {
			return;
		}

		setGroupNameInput(value);
		axios.put(apiRoutes.updateGroup(id), { name: value }, { headers: authHeader })
			.then(() => {
				dispatch(setMessage(messages.GROUP_NAME_UPDATED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch(() => {
				dispatch(setMessage(messages.GROUP_NAME_UPDATE_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const handleGroupNameSubmit = () => {
		setIsEditing(false);
		setGroup({ ...group, name: groupNameInput });
	};

	const initGroup = () => {
		if (id) {
			axios.get(apiRoutes.getGroupById(id), { headers: authHeader })
				.then((response) => {
					setGroup(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const toggleDeleteGroupDialog = () => {
		if (deleteGroupDialogOpen) {
			setDeleteGroupDialogOpen(false);
		} else {
			setDeleteGroupDialogOpen(true);
		}
	};

	useEffect(() => {
		initGroup();
		setSelectedMenuItem(pages.groupRules);
	}, []);

	if (Object.keys(group).length > 0) {
		return (
			<>
				<CoAppMainContainer sx={{ p: 2 }}>
					<SettingsMenuBack buttonText="All Groups" navigateTo={pages.settings + "?from=groups"} />
				</CoAppMainContainer>
				<CoAppMainContainer sx={{ p: 2 }}>
					<SettingMenuContainer>
						<MainMenuContainer>
							<HeaderWithInlineEdit
								isEditing={isEditing}
								handleOnClickEvent={handleGroupNameEdit}
								handleValueChange={handleGroupNameChange}
								handleValueSubmit={handleGroupNameSubmit}
								headerValue={group.name}
								inputValue={groupNameInput} />

							{menuItems.map((menuItem) => (
								menuItem && menuItem.value &&
								<MainMenuItem
									key={menuItem.key}
									className={selectedMenuItem === menuItem.value ? "active" : ""}
									onClick={() => handleMenuItemClick(menuItem.value)}
								>
									{menuItem.label}
								</MainMenuItem>
							))}

						</MainMenuContainer>
						<CoAppButtonWhiteBg onClick={toggleDeleteGroupDialog}><Delete /> Delete Group</CoAppButtonWhiteBg>
						<SettingsDivider />
					</SettingMenuContainer>
				</CoAppMainContainer>

				<CoAppMainContainer sx={{ p: 2 }}>
					{selectedMenuItem === pages.groupRules && <GroupRules group={group} />}
					{selectedMenuItem === pages.groupPlugins && <GroupPlugins group={group} />}
					{selectedMenuItem === pages.groupUsers && <GroupUsers group={group} />}
					{selectedMenuItem === pages.groupSSO && <GroupSSO group={group} />}
				</CoAppMainContainer>

				<Dialog open={deleteGroupDialogOpen}>
					<DialogTitle sx={{ p: 2 }}>
						Are you sure you want to delete this group?
					</DialogTitle>
					<DialogContent>
						<CoAppButtonWhiteBg onClick={toggleDeleteGroupDialog}>Cancel</CoAppButtonWhiteBg>
						<CoAppButtonWhiteBg onClick={confirmGroupDeletion}>Confirm</CoAppButtonWhiteBg>
					</DialogContent>
				</Dialog>
			</>
		);
	} else {
		<></>;
	}
}