import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectUser } from "../../../../redux/userSlice";
import AddItemsToCollectionDialog from "../../../global/components/add-items-to-collection-dialog";
import DataGridCustomToolbar from "../../../global/components/datagrid-custom-toolbar";
import ThreeDotMenu from "../../../global/components/three-dot-menu";
import { CoAppButtonGreenBg, CoAppDataGrid, CoAppH2, CoAppMainContainer } from "../../../global/styled/global.styled";
import { SubMenuContainer } from "../../styled/settings.styled";
import { UserNameCell } from "../../users/styled/user-management.styled";

export default function RoleSSO(props) {
	const authHeader = useAuthHeader();
	const currentUser = useSelector(selectUser);
	const dispatch = useDispatch();

	const role = props.role;

	const [addIdPGroupsIsToggled, setAddIdPGroupsIsToggled] = useState(false);
	const [addIdpGroupsSearchInput, setAddIdpGroupsSearchInput] = useState("");
	const [idpGroups, setIdpGroups] = useState([]);
	const [availableIdpGroups, setAvailableIdpGroups] = useState([]);
	const [roleIdpGroups, setRoleIdpGroups] = useState([]);
	const [selectedIdPGroups, setSelectedIdpGroups] = useState([]);

	const columns = [
		{	
			field: "name",
			headerName: "IDP GROUP",
			disableExport: true,
			editable: true,
			flex: 1,
			renderCell: (params) => (
				<UserNameCell>
					<Typography variant="body2">{params.value}</Typography>
				</UserNameCell>
			),
		},
		{	
			field: "createdAt",
			headerName: "DATE MAPPED",
			disableExport: true,
			editable: true,
			flex: 1,
			renderCell: (params) => (
				<div>
					<Typography sx={{ fontSize: ".8rem", color: "grey" }}>
						{params.value &&
							new Date(params.value).toLocaleString(undefined, {
								timeZone: "America/New_York",
								year: "numeric",
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
								second: "numeric",
								hour12: true,
							})}
					</Typography>
				</div>
			),
		},
		{
			field: "actions",
			disableExport: true,
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Remove IdP Group",
								optionClickHandler: () => {
									removeIdPGroupFromRole(params.row.id);
								},
							},
						]}
					/>
				</div>
			),
		},
	];

	const addIdPGroupsToRole = () => {
		axios.put(apiRoutes.updateRoleIdpGroups(role.id), { idpgroups: selectedIdPGroups }, { headers: authHeader })
			.then(() => {
				init();
				toggleAddNewDialog();
				dispatch(setMessage(messages.IDP_GROUPS_ADDITION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.IDP_GROUPS_ADDITION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));

			});
	};

	const addIdPGroupsToSelectedIdpGroups = (idpGroup) => {
		if (selectedIdPGroups.some((group) => group.name === idpGroup.name)) {
			setSelectedIdpGroups(selectedIdPGroups.filter((group) => group.name !== idpGroup.name));
		} else {
			setSelectedIdpGroups([...selectedIdPGroups, idpGroup]);
		}
	};

	const init = () => {
		axios.get(apiRoutes.getRoleIdpGroups(role.id), { headers: authHeader })
			.then((response) => {
				setRoleIdpGroups(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const initAvailableIdpGroups = () => {
		let available = [];
		if (idpGroups && idpGroups.length > 0) {
			idpGroups.map((idpGroup) => {
				if (!roleIdpGroups.some((roleIdpGroup) => roleIdpGroup.name === idpGroup.externalGroupName)) {
					available.push({ externalGroupId: idpGroup.externalGroupId, name: idpGroup.externalGroupName });
				}
			});
		}
		setAvailableIdpGroups(available);
	};

	const initIdpGroups = () => {
		axios.get(apiRoutes.getExternalGroups(currentUser.organizationId), { headers: authHeader })
			.then((response) => {
				setIdpGroups(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeIdPGroupFromRole = (idpGroupId) => {
		axios.delete(apiRoutes.removeIdpGroupFromRole(role.id, idpGroupId), { headers: authHeader })
			.then(() => {
				init();
				dispatch(setMessage(messages.GROUP_REMOVAL_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.GROUP_REMOVAL_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const toggleAddNewDialog = () => {
		setAddIdPGroupsIsToggled(!addIdPGroupsIsToggled);

		if (!addIdPGroupsIsToggled) {
			initAvailableIdpGroups();
			setAddIdPGroupsIsToggled(true);
		} else {
			setAddIdPGroupsIsToggled(false);
			setAvailableIdpGroups([]);
			setSelectedIdpGroups([]);
		}
	};

	useEffect(() => {
		init();
		initIdpGroups();
	}, []);

	return (
		<CoAppMainContainer sx={{ p: 2 }}>
			<SubMenuContainer>
				<CoAppH2 sx={{ marginRight: "50px" }}>SSO/SCIM Configuration</CoAppH2>
				<CoAppButtonGreenBg
					onClick={toggleAddNewDialog}
					sx={{ float: "right", minWidth: "120px" }}
				>
					ADD IDP GROUP
				</CoAppButtonGreenBg>
			</SubMenuContainer>

			<Box sx={{ width: "100%" }}>
				<CoAppDataGrid
					columns={columns}
					disableAggregation
					disableColumnSelector
					disableDensitySelector
					disableColumnFilter
					disableRowGrouping
					disableRowSelectionOnClick
					experimentalFeatures={{ columnGrouping: false}}
					getRowId={(row) => row.id}
					rows={roleIdpGroups}
					slots={{toolbar: DataGridCustomToolbar}}
					slotProps={{ }}
				/>
			</Box>

			<AddItemsToCollectionDialog
				addButtonTitle="IDP GROUP(S)"
				addItemsIsToggled={addIdPGroupsIsToggled}
				addItemsToCollection={addIdPGroupsToRole}
				addItemsToNewArray={addIdPGroupsToSelectedIdpGroups}
				dialogSearchPlaceholder="Search IdP Groups"
				dialogTitle={`Add IdP Group(s) to ${role.name} role`}
				items={availableIdpGroups}
				itemsToAdd={selectedIdPGroups}
				searchInput={addIdpGroupsSearchInput}
				setSearchInput={setAddIdpGroupsSearchInput}
				toggleDialog={toggleAddNewDialog}
			/>
		</CoAppMainContainer>
	);

}