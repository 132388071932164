import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "@mui/icons-material";
import { Divider, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../helpers/useAuthHeader";
import {
	selectDescription, selectGroups,
	selectItemsInFormula, selectName, selectReactions, selectRuleLocation,
	selectTags, setGroups
} from "../../../redux/ruleWizardSlice";
import {
	RuleFormulaSummary, RuleFormulaSummaryItem, RuleSummary,
	RuleWizardStepTitle,
	WizardHeaderRuleDesc, WizardHeaderRuleName,
	WizardHeaderTag
} from "../styled/rule-wizard.styled";

/**
 * Step 5 in Rule Creation Wizard
 * User confirms Rule prior to persistence
*/
export default function WizardStep5(props) {
	const dispatch = useDispatch();
	let authHeader = useAuthHeader();

	const name = useSelector(selectName);
	const description = useSelector(selectDescription);
	const groups = useSelector(selectGroups);
	const tags = useSelector(selectTags);
	const ruleLocation = useSelector(selectRuleLocation);
	const reactions = useSelector(selectReactions);
	const itemsInFormula = useSelector(selectItemsInFormula);

	const [groupOptions, setGroupOptions] = useState([]);
	const [ruleGroups, setRuleGroups] = useState([]);

	const handleGroupsChange = (event) => {
		setRuleGroups(event.target.value);
		dispatch(setGroups(event.target.value));
	};

	const initGroups = () => {
		axios.get(apiRoutes.getGroups, {
			headers: authHeader,
			params: { name: "" }
		}).then(res => {
			setGroupOptions(res.data);
		}).catch(err => {
			console.log(err);
		});
	};

	useEffect(() => {
		initGroups();
		if (props.editedId) {
			setRuleGroups(groups);
		}
	}, []);

	return (
		<Grid container spacing={2} sx={{ marginTop: "25px" }}>
			<RuleWizardStepTitle sx={{ margin: "auto", width: "100%", padding: "25px", textAlign: "center" }}>Confirm the details.</RuleWizardStepTitle>
			<Grid item xs={12}>
				<RuleSummary>
					<WizardHeaderRuleName>{name}</WizardHeaderRuleName>
					<WizardHeaderRuleDesc>{description}</WizardHeaderRuleDesc>

					<Divider />

					{
						!ruleLocation || !ruleLocation.name || ruleLocation.name === "" ?
							<Typography>No location selected.</Typography>
							:
							<Typography>{ruleLocation.name} - {reactions.length} Reactions</Typography>
					}

					<RuleFormulaSummary>
						{
							itemsInFormula.map((item, index) =>
								<RuleFormulaSummaryItem key={index}>
									{item.triggerName}
								</RuleFormulaSummaryItem>
							)}
					</RuleFormulaSummary>
				</RuleSummary>

				<RuleSummary>
					<Typography>What groups do you want to assign the rule to?</Typography>
					<FormControl fullWidth>
						<Select id="user-select-group" value={ruleGroups} label="Groups"
							onChange={handleGroupsChange} multiple renderValue={(selected) => selected.join(", ")}>
							{
								groupOptions.map(group =>
									<MenuItem key={group.id} value={group.name}>
										<Checkbox checked={ruleGroups.indexOf(group.name) > -1} sx={{
											[`&.${checkboxClasses.checked}`]: {
												color: "#A9A9A9",
											}
										}} />
										{group.name}
									</MenuItem>
								)
							}
						</Select>
					</FormControl>
				</RuleSummary>

				<RuleSummary>
					<Typography>Tags</Typography>
					{
						tags.map((tag, index) =>
							<WizardHeaderTag key={index}>
								<Label fontSize="small" sx={{ paddingRight: 1 }} />
								{tag.toUpperCase()}
							</WizardHeaderTag>
						)
					}
				</RuleSummary>
			</Grid>
		</Grid>
	);
}