import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDescope } from "@descope/react-sdk";
import { Logout, PersonSharp, Settings } from "@mui/icons-material";
import { AppBar, Box, Container, IconButton, Toolbar, Tooltip } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import pages from "../../constants/pages";
import useAuthorization from "../../helpers/useAuthorization";
import {
	selectUser, setEmail, setFirstName, setGroups,
	setLastName, setOrgId, setPermissions,
	setPhone, setRoles, setUserId
} from "../../redux/userSlice";

import { DevContainer } from "./styled/header.styled";

function ResponsiveAppBar() {
	const isAuthorizedForSettings = useAuthorization(["ACCESS_MP", "MANAGE_USERS_AND_GROUPS", "MANAGE_WORKSTATIONS", "MANAGE_PLUGINS", "MANAGE_ORG_KILLSWITCH", "MANAGE_ORG_FORCELOGIN", "MANAGE_SSOSCIM"], false);
	const isAuthorizedForManagementPortal = useAuthorization(["ACCESS_MP"], true);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { logout } = useDescope();
	const user = useSelector(selectUser);

	const [servicesVersion, setServicesVersion] = useState("");

	const handleUserLogout = useCallback(async () => {
		await logout();
		dispatch(setEmail(""));
		dispatch(setUserId(""));
		dispatch(setFirstName(""));
		dispatch(setGroups(""));
		dispatch(setLastName(""));
		dispatch(setPermissions([]));
		dispatch(setPhone(""));
		dispatch(setRoles([]));
		dispatch(setOrgId(""));
		navigate(pages.login);
	}, [logout]);

	const initHeader = () => {
		if (process.env.REACT_APP_NAME) {
			axios.get(apiRoutes.getPackageVersion).then(res => setServicesVersion(res.data.version));
		}
	};

	useEffect(() => {
		initHeader();
	}, [user]);

	return (
		<AppBar position="static">
			{process.env.REACT_APP_VERSION ?
				<DevContainer>
					<h4>Dev Environment UI: {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}</h4>
					<h4>Dev Environment Backend: {servicesVersion}</h4>
				</DevContainer>
				:
				null
			}

			<Container maxWidth="false" disableGutters>
				<Toolbar sx={{ minHeight: "85px!important" }} >
					<a href="/library">
						{process.env.REACT_APP_VERSION ?
							<img href="/library" src="https://coapp-public-assets.s3.amazonaws.com/CoApp-Logo.svg" alt="CoApp Logo" style={{ height: "50px" }} />
							:
							<img href="/library" src="/images/CoApp-Logo.svg" alt="CoApp Logo" style={{ height: "50px" }} />
						}
					</a>

					<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
					<Box sx={{ flexGrow: 0, margin: "15px" }}>
						<Tooltip title="Logout">
							<IconButton onClick={handleUserLogout} sx={{ p: 0 }}>
								<Logout />
							</IconButton>
						</Tooltip>
					</Box>
					{isAuthorizedForManagementPortal ? (
						<Box sx={{ flexGrow: 0, margin: "15px" }}>
							<Tooltip title="My Profile">
								<IconButton href={`${pages.profile}/${user.userId}`} sx={{ p: 0 }}>
									<PersonSharp />
								</IconButton>
							</Tooltip>
						</Box>
					) : (
						<div></div>
					)}

					{isAuthorizedForSettings ? (
						<React.Fragment>
							<Box sx={{ flexGrow: 0, margin: "15px" }}>
								<Tooltip title="Settings">
									<IconButton href={pages.settings} sx={{ p: 0 }}>
										<Settings />
									</IconButton>
								</Tooltip>
							</Box>
						</React.Fragment>
					) : (
						<div></div>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default ResponsiveAppBar;