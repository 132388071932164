import { InputLabel, Select, styled } from "@mui/material";


export const RuleLibrarySelect = styled(Select)(() => ({
	"&.MuiOutlinedInput-root": {
		"& fieldset": {
			border: "1px solid #A9A9A9"
		},
		"&:hover fieldset": {
			border: "1px solid #A9A9A9"
		},
		"&.Mui-focused fieldset": {
			border: "1px solid #A9A9A9"
		}
	},
	backgroundColor: "#FFFFFF"
}));

export const RuleLibraryInputLabel = styled(InputLabel)(() => ({
	"&.MuiInputLabel-root": {
		color: "#A9A9A9"
	}
}));