import { Cancel, Check } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { CoAppDialogIconButton } from "../../global/styled/global.styled";

export default function UserDeletionDialog(props) {
	return (
		<Dialog open={props.deletionDialogOpen} onClose={props.deletionDialogCloseHandler}>
			<DialogTitle>Are you sure you want to delete this user?</DialogTitle>
			<DialogContent>Email: {props.deletedUser.email}</DialogContent>
			<DialogActions>
				<CoAppDialogIconButton onClick={props.deletionDialogCloseHandler}><Cancel /></CoAppDialogIconButton>
				<CoAppDialogIconButton onClick={props.deleteUserHandler}><Check /></CoAppDialogIconButton>
			</DialogActions>
		</Dialog>
	);
}