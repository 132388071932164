import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Container, Stack, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import AddItemsToCollectionDialog from "../../../global/components/add-items-to-collection-dialog";
import DataGridCustomNoRowsOverlay from "../../../global/components/datagrid-custom-norows-overlay";
import DataGridCustomToolbar from "../../../global/components/datagrid-custom-toolbar";
import SearchInput from "../../../global/components/search-input";
import ThreeDotMenu from "../../../global/components/three-dot-menu";
import { CoAppButtonGreenBg, CoAppDataGrid, CoAppH2, CoAppMainContainer } from "../../../global/styled/global.styled";
import { SubMenuContainer } from "../../styled/settings.styled";
import { UserNameCell } from "../../users/styled/user-management.styled";

export default function GroupRules(props) {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const group = props.group;

	const [addRulesIsToggled, setAddRulesIsToggled] = useState(false);
	const [addRulesSearchInput, setAddRulesSearchInput] = useState("");
	const [availableRules, setAvailableRules] = useState([]);
	const [rulesToAdd, setRulesToAdd] = useState([]);
	const [groupRules, setGroupRules] = useState([]);
	const [groupRulesLoaded, setGroupRulesLoaded] = useState(false);
	const [ruleSearchInput, setRuleSearchInput] = useState("");
	const [selectedRules, setSelectedRules] = useState([]);

	const columns = [
		{
			editable: false,
			field: "name",
			flex: 1,
			headerClassName: "data-grid-header",
			headerName: "NAME",
			pinnable: false,
			renderCell: (params) => (
				<UserNameCell>
					<Typography sx={{ fontWeight: "600" }}>{params.value}</Typography>
				</UserNameCell>
			),
		},
		{
			editable: true,
			field: "active",
			flex: 1,
			headerClassName: "data-grid-header",
			headerName: "STATUS",
			pinnable: false,
			renderCell: (params) => (
				<div>
					{params.value ?
						<Typography sx={{ fontSize: ".8rem", fontWeight: "600", color: "#2fbd70" }}>Active</Typography>
						: <Typography sx={{ fontSize: ".8rem", fontWeight: "600", color: "grey" }}>Inactive</Typography>}
				</div>
			),
		},
		{
			editable: false,
			field: "updatedAt",
			flex: 1,
			headerClassName: "data-grid-header",
			headerName: "LAST MODIFIED",
			pinnable: false,
			renderCell: (params) => (
				<div>
					<Typography sx={{ fontSize: ".8rem", color: "grey" }}>
						{params.value &&
							new Date(params.value).toLocaleString(undefined, {
								timeZone: "America/New_York",
								year: "numeric",
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
								second: "numeric",
								hour12: true,
							})}
					</Typography>
				</div>
			),
		},
		{
			editable: false,
			field: "createdAt",
			flex: 0.5,
			headerClassName: "data-grid-header",
			headerName: "CREATED ON",
			pinnable: false,
			renderCell: (params) => (
				<div>
					<Typography sx={{ fontSize: ".8rem", color: "grey" }}>
						{params.value &&
							new Date(params.value).toLocaleString(undefined, {
								timeZone: "America/New_York",
								year: "numeric",
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
								second: "numeric",
								hour12: true,
							})}
					</Typography>
				</div>
			),
		},
		{
			field: "actions",
			disableExport: true,
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Remove From Group",
								optionClickHandler: () => {
									removeRuleFromGroup(params.row.id);
								},
							},
							{
								name: "View Rule",
								optionClickHandler: () => {
									navigate(pages.editRule(params.row.id));
								},
							},
						]}
					/>
				</div>
			),
		},
	];

	const addRulesToGroup = () => {
		let requestJson = {
			ruleIds: rulesToAdd.map((rule) => rule.id)
		};
		axios.post(apiRoutes.addRulesToGroup(group.id), requestJson, { headers: authHeader })
			.then((response) => {
				if (response.status === 200) {
					init();
					setAddRulesIsToggled(false);
					dispatch(setMessage(messages.RULE_ADDED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
				} else {
					dispatch(setMessage(messages.RULE_ADDED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.RULE_ADDED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const addRuleToRulesToAdd = (rule) => {
		let rulesToAddRows = [...rulesToAdd];
		let ruleIndex = rulesToAddRows.findIndex((row) => row.id === rule.id);
		if (ruleIndex === -1) {
			rulesToAddRows.push(rule);
		} else {
			rulesToAddRows.splice(ruleIndex, 1);
		}
		setRulesToAdd(rulesToAddRows);
	};

	const addRuleToRuleSelection = (rule) => {
		let selectedRuleRows = [...selectedRules];
		let ruleIndex = selectedRuleRows.findIndex((row) => row.id === rule.id);
		if (ruleIndex === -1) {
			selectedRuleRows.push(rule);
		} else {
			selectedRuleRows.splice(ruleIndex, 1);
		}
		setSelectedRules(selectedRuleRows);
	};

	const init = () => {
		let ruleQueryObject = {
			name: ruleSearchInput ? ruleSearchInput : null,
			groupId: group.id,
		};
		axios.get(apiRoutes.getRules, {
			headers: authHeader,
			params: ruleQueryObject,
		})
			.then((response) => {
				setGroupRules(response.data.rules);
				setGroupRulesLoaded(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const initAvailableRules = () => {
		axios.get(apiRoutes.getRules, { headers: authHeader })
			.then((response) => {
				let availableRules = response.data.rules.filter((rule) => {
					let ruleIndex = groupRules.findIndex((groupRule) => groupRule.id === rule.id);
					return ruleIndex === -1;
				});
				setAvailableRules(availableRules);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeRuleFromGroup = (ruleId) => {
		axios.delete(apiRoutes.removeRuleFromGroup(group.id, ruleId), { headers: authHeader })
			.then((response) => {
				if (response.status === 200) {
					init();
					dispatch(setMessage(messages.RULE_REMOVED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
				} else {
					dispatch(setMessage(messages.RULE_REMOVED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.RULE_REMOVED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const removeRulesFromGroup = () => {
		selectedRules.forEach((rule) => {
			removeRuleFromGroup(rule.id);
		});
	};

	const setBulkActions = (rowIds) => {
		if (rowIds.length > 0) {
			rowIds.forEach((rowId) => {
				let rule = groupRules.find((rule) => rule.id === rowId);
				addRuleToRuleSelection(rule);
			});
		} else {
			setSelectedRules([]);
		}
	};

	const toggleAddNewDialog = () => {
		if (!addRulesIsToggled) {
			initAvailableRules();
			setAddRulesIsToggled(true);
		} else {
			setAddRulesIsToggled(false);
			setAvailableRules([]);
			setRulesToAdd([]);
		}
	};

	useEffect(() => {
		init();
	}, [group, ruleSearchInput]);

	if (groupRulesLoaded) {
		return (
			<>
				<CoAppMainContainer sx={{ p: 2, width: "95%" }}>
					<SubMenuContainer>
						<CoAppH2 sx={{ marginRight: "50px" }}>Rules</CoAppH2>

						<SearchInput
							placeholder="Search rules"
							onChangeHandler={setRuleSearchInput} />

						<CoAppButtonGreenBg
							onClick={toggleAddNewDialog}
							sx={{ float: "right", minWidth: "120px" }}
						>
							ADD RULE
						</CoAppButtonGreenBg>
					</SubMenuContainer>

					{
						groupRules.length === 0 && (
							<Box sx={{ width: "100%" }}>
								<DataGridCustomNoRowsOverlay message={"No Rules Yet!"} />
							</Box>
						)
					}

					{
						groupRules.length > 0 && (
							<Box sx={{ width: "100%" }}>
								<CoAppDataGrid
									checkboxSelection
									columnHeaderHeight={45}
									columns={columns}
									disableAggregation
									disableColumnSelector
									disableDensitySelector
									disableRowGrouping
									disableRowSelectionOnClick
									experimentalFeatures={{ columnGrouping: false }}
									getRowClassName={(params) =>
										params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
									}
									getRowId={(row) => row.id}
									initialState={{
										pagination: {
											paginationModel: { pageSize: 25, page: 0 },
										},
										pinnedColumns: { right: ["actions"] },
									}}
									onRowSelectionModelChange={(params) => {
										setBulkActions(params);
									}}
									pagination
									rows={groupRules}
									slots={{ toolbar: DataGridCustomToolbar }}
									slotProps={{ toolbar: { removeAction: removeRulesFromGroup, removeActionDisplay: selectedRules.length > 0 ? true : false, removeActionLabel: `Remove ${selectedRules.length} Rule(s)` } }}
								/>
							</Box>
						)
					}
				</CoAppMainContainer>

				<AddItemsToCollectionDialog
					addButtonTitle="RULE(S)"
					addItemsIsToggled={addRulesIsToggled}
					addItemsToCollection={addRulesToGroup}
					addItemsToNewArray={addRuleToRulesToAdd}
					dialogSearchPlaceholder="Search rules"
					dialogTitle={`Add rule(s) to ${group.name} group`}
					items={availableRules}
					itemsToAdd={rulesToAdd}
					searchInput={addRulesSearchInput}
					setSearchInput={setAddRulesSearchInput}
					toggleDialog={toggleAddNewDialog}
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}