import { Person } from "@mui/icons-material";
import { Box, Button, InputLabel, styled, TextField, Typography } from "@mui/material";

export const UserProfileStructure = styled("div")(({ theme }) => ({
	alignItems: "flex-start",
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	borderRadius: "5px",
	boxShadow: "0 1px 3px grey",
	color: theme.palette.text.secondary,
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "flex-start",
	margin: "50px auto 50px auto",
	padding: theme.spacing(5),
	width: "80%",
}));

export const UserProfileHeaderStructure = styled("div")(() => ({
	alignItems: "flex-start",
	display: "flex",
	flex: 1,
	justifyContent: "space-between",
	width: "100%"
}));

export const UserProfileToggleStatusContainer = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	textAlign: "left",
	display: "flex",
	fontSize: ".75rem",
	fontWeight: "400",
	justifyContent: "flex-start",
	minWidth: "150px",
	marginTop: "30px",
	marginLeft: "-10px",

	"& p": {
		margin: "auto 0",
	},
}));

export const UserProfileSaveChangesButton = styled(Button)(({ theme }) => ({
	...theme.typography.body2,
	textAlign: "center",
	color: "#4DC685",
	fontSize: ".75rem",
	fontWeight: "600",
	marginTop: "30px",
	marginLeft: "110px"
}));

export const UserProfileCancelButton = styled(Button)(({ theme }) => ({
	...theme.typography.body2,
	textAlign: "center",
	color: "#A2A2A2",
	fontSize: ".75rem",
	fontWeight: "600",
	marginTop: "30px",
}));


export const UserProfileEdit = styled("div")(() => ({
	color: "#4DC685",
	display: "inline",
	position: "relative",
	margin: "auto",

	"&:hover": {
		cursor: "pointer",
		fontWeight: "700"
	},
}));

export const UserProfileBox = styled(Box)(() => ({
	flexGrow: 1,
	padding: "20px",
}));

export const UserProfileTextField = styled(TextField)(({ isemail }) => ({
	width: isemail ? "580px" : "254px",
	"& .MuiOutlinedInput-root": {
		"&.Mui-focused fieldset": {
			border: "1px solid #A9A9A9"
		},
	},
	"& label.Mui-focused": {
		color: "#A9A9A9"
	},
	"&:hover fieldset": {
		border: "1px solid #A9A9A9"
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "1px solid #A9A9A9",
		"&:hover fieldset": {
			border: "1px solid #A9A9A9"
		}
	}
}));

export const UserProfilePersonIcon = styled(Person)(() => ({
	color: "#2FBD70",
	borderRadius: "50%",
	backgroundColor: "#EAF8F1",
	fontSize: "5rem",
	padding: "10px",
	margin: "0px 50px 0px 0px"
}));

export const UserProfileTypography = styled(Typography)(() => ({
	fontSize: "0.7rem",
	fontWeight: "700",
	color: "#A1A1A1",
	width: "275px",
	marginTop: "37px",
	marginLeft: "110px"
}));

export const UserProfileTitle = styled(Typography)(() => ({
	fontSize: "34px",
	fontWeight: "400",
	color: "#000000",
	lineHeight: "41.00px",
	marginBottom: "20px",
	padding: "0px 20px"
}));

export const UserProfileInputLabel = styled(InputLabel)(() => ({
	"&.MuiInputLabel-root": {
		color: "#A9A9A9"
	},
	marginLeft: "-15px",
	marginTop: "12px"
}));

export const UserProfileNotAuthorized = styled("div")(() => ({
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	fontSize: "2.5rem",
}));