import { Clear } from "@mui/icons-material";
import {
	Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, MenuItem
} from "@mui/material";

import {
	CancelTagButton, SaveTagButton, TagDialogInputLabel,
	TagDialogSelect, TagDialogTextField
} from "./styled/tag-dialog.styled";

export default function TagDialog(props) {
	if (!props.deleteTagFlag && props.openDialogFlag) {
		return (
			<Dialog open={props.openDialogFlag} onClose={props.cancelDialogHandler} >
				<DialogActions sx={{ justifyContent: "space-between" }}>
					<DialogTitle sx={{ fontWeight: "500" }}> {props.editingTagFlag ? "Edit Tag" : "Add New Tag"}</DialogTitle>
					<IconButton sx={{ color: "#2FBC70" }} onClick={props.cancelDialogHandler}><Clear /></IconButton>
				</DialogActions>
				<DialogContent sx={{ width: "350px" }} >
					<TagDialogTextField
						error={props.tagError.errorState}
						helperText={props.tagError.errorMessage}
						label="Tag name"
						fullWidth
						variant="outlined"
						value={props.tagName}
						onChange={props.tagNameChangeHandler}
						inputProps={{ maxLength: 20 }}
					/>
					<FormControl sx={{ display: "block", marginTop: "20px" }}>
						<TagDialogInputLabel id="parentTag">Parent tag <em>({"optional"})</em></TagDialogInputLabel>
						<TagDialogSelect
							data-testid="add-tag-select"
							labelId="parentTag"
							label={"Parent tag (optional)"}
							onChange={props.parentTagSelectionChangeHandler}
							value={props.parentTagId}
						>
							<MenuItem value=""><em>None</em></MenuItem>
							{props.parentTags.map(pTag => <MenuItem key={pTag.id} value={pTag.id} data-testid={`parent_${pTag.name}`}>{pTag.name}</MenuItem>)}
						</TagDialogSelect>
					</FormControl>
				</DialogContent>
				<Divider sx={{ width: "100%" }} />
				<DialogActions sx={{ justifyContent: "space-between" }}>
					<CancelTagButton onClick={props.cancelDialogHandler}>Cancel</CancelTagButton>
					<SaveTagButton variant="outlined" data-testid="create-tag-submit" onClick={props.editingTagFlag ? props.updateTagHandler : props.saveNewTagHandler}> {props.editingTagFlag ? "Save" : "Add Tag"}</SaveTagButton>
				</DialogActions>
			</Dialog>
		);
	} else {
		return null;
	}
}