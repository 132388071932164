import { Clear, Delete } from "@mui/icons-material";
import {
	Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText,
	IconButton, MenuItem
} from "@mui/material";

import { CoAppButtonGreenBg, CoAppDialogTextField, CoAppInputLabel, CoAppSelect } from "../../global/styled/global.styled";

export default function ReactionDialog(props) {
	let reactionTypes = [...props.reactionTypes];
	const hasPopupReaction = props.currentReactions.filter(reaction => reaction.reactionType.includes("popup")).length > 0;
	if (hasPopupReaction && !props.isEdit) {
		reactionTypes = reactionTypes.filter(reaction => !reaction.name.includes("popup"));
	} else if (hasPopupReaction && props.isEdit && !props.typeValue.includes("popup")) {
		reactionTypes = reactionTypes.filter(reaction => !reaction.name.includes("popup"));
	}

	return (
		<Dialog open={props.isOpen || props.isEdit} onClose={props.dialogCancelHandler}>
			<DialogActions sx={{ justifyContent: "space-between" }} >
				<DialogTitle>{props.isEdit ? "Edit Reaction" : "Add New Reaction"}</DialogTitle>
				<IconButton sx={{ color: "#2FBC70" }} onClick={props.isEdit ? props.editCancelHandler : props.dialogCancelHandler}><Clear /></IconButton>
			</DialogActions>

			<Divider sx={{ width: "100%" }} />

			<DialogContent sx={{ width: "325px" }}>

				<FormControl sx={{ display: "block", marginTop: "12px", marginBottom: "12px" }} error={props.typeValueError !== ""}>
					<CoAppInputLabel>Type of Reaction</CoAppInputLabel>
					<CoAppSelect
						sx={{ width: "100%" }}
						labelId="reactionType"
						id="reactionType"
						label="Type of Reaction"
						value={props.typeValue}
						onChange={props.typeDropdownValueChangeHandler}
					>
						{reactionTypes.map(item =>
							<MenuItem key={item.name} value={item.name}>
								{item.name}
							</MenuItem>
						)}
					</CoAppSelect>

					<FormHelperText>{props.typeValueError}</FormHelperText>
				</FormControl>

				{
					["popup", "userconfirmationpopup", "managerapprovalpopup"].includes(props.typeValue) ?
						<FormControl sx={{ display: "block", marginTop: "12px", marginBottom: "12px" }} error={props.popupLocationValueError !== ""}>
							<CoAppInputLabel>Pop Up Location</CoAppInputLabel>
							<CoAppSelect
								sx={{ width: "100%" }}
								labelId="popupLocation"
								id="popupLocation"
								label="Pop Up Location"
								value={props.popupLocation}
								onChange={props.popupLocationValueChangeHandler}
							>
								<MenuItem key="center" value="center">
									Center
								</MenuItem>
							</CoAppSelect>
							<FormHelperText>{props.popupLocationValueError}</FormHelperText>
						</FormControl>
						:
						null
				}

				{
					props.typeValue !== "" && !["popup", "userconfirmationpopup", "managerapprovalpopup"].includes(props.typeValue) ?

						<FormControl sx={{ display: "block" }} error={props.fieldValueError !== ""}>
							<CoAppInputLabel id="reactionField">Reaction Field</CoAppInputLabel>
							<CoAppSelect
								sx={{ width: "100%" }}
								labelId="reactionField"
								id="reactionField"
								value={props.fieldValue}
								label="Reaction Field"
								onChange={props.fieldDropdownValueChangeHandler}
								error={props.fieldValueError !== ""}
							>
								{
									props.reactionFields.map(item =>
										<MenuItem key={item.id} value={JSON.stringify(item)}>
											{item.name}
										</MenuItem>
									)
								}
							</CoAppSelect>
							<FormHelperText>{props.fieldValueError}</FormHelperText>
						</FormControl>
						:
						null
				}
				{props.typeValue !== "" && props.typeValue !== "disable" ?
					<FormControl sx={{ margin: "-10px", width: "100%" }}>
						<CoAppDialogTextField
							margin="dense"
							id="reactionText"
							label="Text Displayed in Reaction"
							type="text"
							fullWidth
							variant="filled"
							value={props.reactionText}
							onChange={props.reactionTextInputHandler}
							error={props.reactionTextValueError !== ""}
							helperText={props.reactionTextValueError}
							inputProps={{ maxLength: 255 }}
						/>
					</FormControl>
					:
					null
				}
				{
					props.typeValue !== "" && props.typeValue === "userconfirmationpopup" ?
						<FormControl sx={{ margin: "-10px", width: "100%" }}>
							<CoAppDialogTextField
								margin="dense"
								id="reactionConfirmationText"
								label="User Confirmation Text"
								type="text"
								fullWidth
								variant="filled"
								value={props.userConfirmationText}
								onChange={props.userConfirmationTextInputHandler}
								error={props.userConfirmationTextValueError !== ""}
								helperText={props.userConfirmationTextValueError}
								inputProps={{ maxLength: 20 }}
							/>
						</FormControl>
						:
						null
				}

				<FormControl sx={{ margin: "-10px", width: "100%" }}>
					<CoAppDialogTextField
						margin="dense"
						id="reactionDescription"
						label="Reaction Description (optional)"
						type="text"
						fullWidth
						variant="filled"
						value={props.reactionDesc}
						onChange={props.reactionDescriptionInputHandler}
						inputProps={{ maxLength: 255 }}
					/>
				</FormControl>

			</DialogContent>
			<Divider sx={{ width: "100%" }} />

			<DialogActions sx={{ justifyContent: "space-between", display: "inline" }} >
				<IconButton sx={{ color: "#2FBC70" }} onClick={props.isEdit ? props.deleteReactionHandler : props.dialogCancelHandler}><Delete /></IconButton>
				<CoAppButtonGreenBg variant="outlined" sx={{ marginTop: "10px" }} disabled={props.formHasErrors} onClick={props.isEdit ? props.editSaveReactionHandler : props.saveReactionHandler}>Save</CoAppButtonGreenBg>
			</DialogActions>
		</Dialog>
	);
}