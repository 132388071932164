import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	Box,
	CircularProgress,
	Container,
	Stack,
	Typography,
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import {
	selectOrganizationSsoAndScim
} from "../../../redux/userSlice";
import ConfirmationDialog from "../../global/components/confirmation-dialog";
import DataGridCustomNoRowsOverlay from "../../global/components/datagrid-custom-norows-overlay";
import SearchInput from "../../global/components/search-input";
import ThreeDotMenu from "../../global/components/three-dot-menu";
import {
	CoAppButtonGreenBg,
	CoAppDataGrid,
	CoAppGroupList,
	CoAppH2,
	CoAppMainContainer,
} from "../../global/styled/global.styled";
import { SubMenuContainer } from "../styled/settings.styled";

import RoleCreationDialog from "./role-creation-dialog";

export default function RoleManagement() {
	let authHeader = useAuthHeader();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [dialogDetails, setDialogDetails] = useState({ dialogOpen: false});
	const [newRoleName, setNewRoleName] = useState("");
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const [roleCreationDialogOpen, setRoleCreationDialogOpen] = useState(false);
	const [roleSearchInput, setRoleSearchInput] = useState("");
	const [roleSeed, setRoleSeed] = useState(0);
	const [rolesLoaded, setRolesLoaded] = useState(false);
	const [roles, setRoles] = useState([]);

	const columns = [
		{
			field: "name",
			headerClassName: "data-grid-header",
			headerName: "NAME",
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<div>
					<Typography>{params.value}</Typography>
				</div>
			),
		},
		{
			field: "users",
			headerClassName: "data-grid-header",
			headerName: "USERS",
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<div>
					{ params.value && params.value.length > 0 ? (
						<Typography> {params.value.length} User(s) </Typography>
					) : (
						<Typography>No Users</Typography>
					)
					}
				</div>
			)
		},
		{
			field: "permissions",
			headerClassName: "data-grid-header",
			headerName: "PERMISSIONS",
			editable: false,
			flex: 1.25,
			renderCell: (params) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					{params.value && params.value.length > 0 ? (
						<>
							<Typography sx={{ fontWeight: "600" }}>
								{params.value.length}
							</Typography>
							<CoAppGroupList sx={{ width: "450px" }} title={params.value.map(item => item.name).join(", ")}>
								{params.value.map(item => item.name).join(", ")}
							</CoAppGroupList>
						</>
					) : (
						<Typography>No Permissions</Typography>
					)}
				</div>
			),						
		},
		...(organizationSsoAndScim
			? [
				{
					field: "idpgroups",
					headerClassName: "data-grid-header",
					headerName: "IDP GROUPS",
					editable: false,
					flex: 1.25,
					renderCell: (params) => (
						<div>
							{params.value && params.value.length > 0 ? (
								<>
									<Typography sx={{ fontWeight: "600" }}>
										{params.value.length}
									</Typography>
									<CoAppGroupList sx={{ width: "450px" }} title={params.value.map(item => item.name).join(", ")}>
										{params.value.map(item => item.name).join(", ")}
									</CoAppGroupList>
								</>
							) : (
								<Typography>No Groups</Typography>
							)}
						</div>
					),
				},
			]
			: []),
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Edit",
								optionClickHandler: () => {
									handleOpenRoleEditPage({params});
								},
							},
							{
								name: "Duplicate",
								optionClickHandler: () => {
									toggleDuplicateDialog({params});
								},
							},
							{
								name: "Delete",
								optionClickHandler: () => {
									toggleDeleteDialog({params});
								},
							},
						]}
					/>
				</div>
			),
		},
	];

	const createNewRole = () => {
		let role = { name : newRoleName };
		axios
			.post(apiRoutes.createRole, role, { headers: authHeader })
			.then((res) => {
				if (res.status === 200) {
					navigate(pages.role(res.data.id));
					dispatch(setMessage(messages.ROLE_CREATED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
				} else {
					dispatch(setMessage(messages.ROLE_CREATION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch(setMessage(messages.ROLE_CREATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const deleteRole = (id) => {
		axios
			.delete(apiRoutes.deleteRole + "/" + id, {
				headers: authHeader,
			})
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(messages.ROLE_DELETION_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setRoleSeed(Math.random());
					toggleDeleteDialog();
				} else {
					dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const duplicateRole = (id) => {
		axios
			.post(apiRoutes.copyRole(id), {}, { headers: authHeader })
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(messages.ROLE_DUPLICATION_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setRoleSeed(Math.random());
					toggleDuplicateDialog();
				} else {
					dispatch(setMessage(messages.ROLE_DUPLICATION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch(setMessage(messages.ROLE_DUPLICATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR
				));
			});
	};

	const handleOpenRoleEditPage = (rowObject) => {
		navigate(pages.role(rowObject.params.id));
	};

	const initRoleManagement = () => {
		let roleQueryObject = {
			name: roleSearchInput,
		};
		axios
			.get(apiRoutes.getRoles, {
				headers: authHeader,
				params: roleQueryObject,
			})
			.then((res) => {
				setRoles(res.data);
				setRolesLoaded(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const toggleAddNewDialog = () => {
		setRoleCreationDialogOpen(!roleCreationDialogOpen);
	};

	const toggleDeleteDialog = (rowObject) => {
		if (rowObject) {
			let roleBeingDeleted = rowObject.params.row;

			setDialogDetails({
				cancelClickHandler: () => toggleDeleteDialog(),
				confirmClickHandler: () => deleteRole(roleBeingDeleted.id),
				confirmationTitle: messages.ROLE_DELETION_CONFIRMATION_MSG,
				dialogOpen: true,
			});
		} else {
			setDialogDetails({ dialogOpen: false });
		}
	};

	const toggleDuplicateDialog = (rowObject) => {
		if (rowObject) {
			let roleBeingDuplicated = rowObject.params.row;

			setDialogDetails({
				cancelClickHandler: () => toggleDuplicateDialog(),
				confirmClickHandler: () => duplicateRole(roleBeingDuplicated.id),
				confirmationTitle: messages.ROLE_DUPLICATION_CONFIRMATION_MSG,
				dialogOpen: true,
			});
		} else {
			setDialogDetails({ dialogOpen: false });
		}
	};

	useEffect(() => {
		initRoleManagement();
	}, [roleSearchInput, roleSeed]);

	if (rolesLoaded) {
		return (
			<>
				<CoAppMainContainer sx={{ p: 2, width: "95%"  }}>
					<SubMenuContainer>
						<CoAppH2 sx={{ display: "inline", marginRight: "50px" }}>Roles</CoAppH2>

						{
							roles.length > 0 && (
								<SearchInput
									placeholder="Search roles"
									onChangeHandler={setRoleSearchInput} />
							)
						}

						<CoAppButtonGreenBg
							onClick={toggleAddNewDialog}
							sx={{ float: "right", minWidth: "120px" }}
						>
							ADD ROLE
						</CoAppButtonGreenBg>
					</SubMenuContainer>

					{
						roles.length > 0 && (
							<Box sx={{ width: "100%" }}>
								<CoAppDataGrid
									columns={columns}
									columnHeaderHeight={45}
									disableRowSelectionOnClick
									getRowClassName={(params) =>
										params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
									}
									getRowId={(row) => row.id}
									initialState={{
										pagination: {
											paginationModel: { pageSize: 25, page: 0 },
										},
										pinnedColumns: { right: ["actions"] },
										sorting: {
											sortModel: [{ field: "name", sort: "asc" }],
										},
									}}
									pagination
									rows={roles}
								/>
							</Box>
						)
					}

					{
						roles.length === 0 && (
							<DataGridCustomNoRowsOverlay message="No roles exist yet!" />
						)
					}
				</CoAppMainContainer>

				<RoleCreationDialog
					createRoleHandler={createNewRole}
					roleCreationDialogOpen={roleCreationDialogOpen}
					creationDialogCloseHandler={toggleAddNewDialog}
					roleCreationNameHandler={(e) => setNewRoleName(e.target.value)}
					roleChangeHandler={(e) => setNewRoleName(e.target.value)}
				/>

				<ConfirmationDialog dialogDetails={dialogDetails} />
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}
