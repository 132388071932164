import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Info } from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import messages from "../../constants/messages";
import pages from "../../constants/pages";
import useAuthHeader from "../../helpers/useAuthHeader";
import useRuleFilter from "../../helpers/useRuleFilter";
import {
	selectCurrentPage,
	selectRowsPerPage, selectRuleGridUpdateSeed,
	setCurrentPage, setInitRules,
	setRowsPerPage, setRuleFilters, setRules, setRulesDidLoadFlag,
	setRulesLoadError,
	setTotalPages
} from "../../redux/ruleSlice";
import { CoAppButtonGreenBg, CoAppH1, CoAppMainContainer } from "../global/styled/global.styled";
import TagLibraryComponent from "../tags/tag-library-component";

import { RuleLibraryDivider, RuleLibraryFilterContainer } from "./styled/rule-library.styled";
import RuleDropdown from "./rule-dropdown";
import RuleDropdownMulti from "./rule-dropdown-multi";
import RuleGrid from "./rule-grid";
import RulePagination from "./rule-pagination";
import RuleSearchBar from "./rule-search-bar";

export default function RuleLibrary() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let authHeader = useAuthHeader();

	const updateAndGetRuleFilters = useRuleFilter();
	const [groups, setGroups] = useState([]);
	const currentPage = useSelector(selectCurrentPage);
	const rowsPerPage = useSelector(selectRowsPerPage);
	const gridUpdateSeed = useSelector(selectRuleGridUpdateSeed);

	const initRules = () => {
		let queryObj = updateAndGetRuleFilters("default", currentPage ? currentPage : 1);
		axios.get(apiRoutes.getRules, {
			headers: authHeader,
			params: queryObj
		})
			.then(res => {
				dispatch(setCurrentPage(res.data.pager.currentPage));
				dispatch(setTotalPages(res.data.pager.pages));
				dispatch(setRules(res.data.rules));
				dispatch(setInitRules(res.data.rules));
				dispatch(setRulesDidLoadFlag(true));
				dispatch(setRuleFilters({ page: res.data.pager.currentPage, pageSize: rowsPerPage }));
				dispatch(setRulesLoadError(""));
				dispatch(setRowsPerPage(rowsPerPage));
			})
			.catch(err => dispatch(setRulesLoadError(err.message)));
	};

	const initGroups = () => {
		axios.get(apiRoutes.getGroups, {
			headers: authHeader,
			params: { name: "" }
		})
			.then(res => {
				setGroups(res.data);
			});
	};

	const createNewRule = () => {
		navigate(pages.newRule);
	};

	useEffect(() => {
		initRules();
		initGroups();
	}, [gridUpdateSeed]);

	return (
		<CoAppMainContainer sx={{ p: 2 }}>
			<Grid container spacing={1} justifyContent="space-evenly">
				<Grid item xs={10} />
				<Grid item xs={10}>
					<CoAppH1>
						Rules
						<Tooltip title={messages.RULE_STATE_TOOLTIP} placement={"right-end"}>
							<Info sx={{ marginLeft: { lg: "15px", xl: "30px" } }} fontSize='small' />
						</Tooltip>
					</CoAppH1>
				</Grid>
				<Grid item >
					<CoAppButtonGreenBg
						sx={{ padding: "10px 20px 10px 20px", borderRadius: "10px", marginLeft: { "xl": "-25px" } }}
						onClick={createNewRule}
					>
						ADD NEW
					</CoAppButtonGreenBg>
				</Grid>

				<Grid item xs={10} />

				<RuleLibraryDivider />

				<RuleLibraryFilterContainer container spacing={4} justifyContent='space-between'>
					<Grid item xs={1} sx={{ marginRight: "-70px" }}>
						<RuleDropdownMulti
							DropdownType='Groups'
							Options={groups}
						/>
					</Grid>
					<Grid item xs={2}>
						<RuleDropdown
							DropdownType='Status'
							Options={[{ name: "Active" }, { name: "Inactive" }, { name: "Draft" }, { name: "Deleted" }]}
						/>
					</Grid>
					<RuleSearchBar />
				</RuleLibraryFilterContainer>

				<Grid container spacing={2} justifyContent='space-around' sx={{ marginTop: "10px" }}>
					<Grid item xs={2}>
						<TagLibraryComponent />
					</Grid>
					<RuleGrid />
				</Grid>
				<RulePagination />
			</Grid>
		</CoAppMainContainer>
	);
}