import { CoAppH1 } from "../../../global/styled/global.styled";

import { ScimWizardHeaderContainer, ScimWizardHeaderDivider } from "./styled/scim-wizard.styled";

export default function ScimWizardHeader({ title, count }) {
	return (
		<ScimWizardHeaderContainer>
			<CoAppH1 sx={{ marginLeft: "95px" }}>{count ? `${title} (${count})` : title}</CoAppH1>
			<ScimWizardHeaderDivider />
		</ScimWizardHeaderContainer>
	);
}