import { useState } from "react";
import { useDispatch } from "react-redux";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import useAuthHeader from "../../helpers/useAuthHeader";
import useRuleFilter from "../../helpers/useRuleFilter";
import {
	setRules, setRulesDidLoadFlag,
	setRulesLoadError,
	setTotalPages} from "../../redux/ruleSlice";

import { RuleLibraryInputLabel, RuleLibrarySelect } from "./styled/rule-dropdown.styled";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function RuleDropdownMulti(props) {
	const dispatch = useDispatch();
	let authHeader = useAuthHeader();
	const updateAndGetRuleFilters = useRuleFilter();

	const [dropdownValue, setDropdownValue] = useState([]);

	const handleDropdownSelectionChange = (e) => {
		const { target: { value } } = e;
		setDropdownValue(typeof value === "string" ? value.split(",") : value);
		//get group ids from selected value
		let groupIds = [];
		value.forEach(item => {
			let matchedGroup = props.Options.filter(group => group["name"] === item);
			groupIds = [...groupIds, matchedGroup[0]["id"]];
		});
		let queryObj = updateAndGetRuleFilters("group", groupIds);

		axios.get(apiRoutes.getRules, {
			headers: authHeader,
			params: queryObj, paramsSerializer: { indexes: null }
		}).then(res => {
			//if no data returned, set loading error, else dispatch setters for rules, didLoadFlag and Load Error
			if (res.data.length === 0) {
				dispatch(setRulesLoadError("No rules found with specified filter criteria."));
			} else {
				dispatch(setRules(res.data.rules));
				dispatch(setTotalPages(res.data.pager.paages));
				dispatch(setRulesDidLoadFlag(true));
				dispatch(setRulesLoadError(""));
			}
		}).catch(err => {
			dispatch(setRulesLoadError(err.message));
		});
	};

	return (
		<FormControl>
			<RuleLibraryInputLabel id={props.DropdownType}>{`Select ${props.DropdownType}`}</RuleLibraryInputLabel>
			<RuleLibrarySelect
				sx={{ width: "250px", height: "50px" }}
				labelId={props.DropdownType}
				label={`Select ${props.DropdownType}`}
				value={dropdownValue}
				multiple
				input={<OutlinedInput label={`Select ${props.DropdownType}`} />}
				renderValue={(selected) => selected.join(", ")}
				onChange={handleDropdownSelectionChange}
				MenuProps={MenuProps}
			>
				{
					props.Options.map(item =>
						<MenuItem key={item.id} value={item.name}>
							<Checkbox checked={dropdownValue.indexOf(item.name) > -1} sx={{
								[`&.${checkboxClasses.checked}`]: {
									color: "#A9A9A9",
								}
							}} />
							<ListItemText primary={item.name} />
						</MenuItem>)
				}
			</RuleLibrarySelect>
		</FormControl>
	);
}