import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Cancel, Check, Groups, Label } from "@mui/icons-material";
import {
	Alert, Dialog, DialogActions, DialogContent,
	DialogTitle, Divider, Fade, Grid, Popper, Tooltip
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import messages from "../../constants/messages";
import pages from "../../constants/pages";
import useAuthHeader from "../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../redux/alertSlice";
import { setRuleGridUpdateSeed } from "../../redux/ruleSlice";
import { CoAppDialogIconButton } from "../global/styled/global.styled";

import {
	RuleCardDialogContent, RuleCardGroup, RuleCardGroupsCount,
	RuleCardPopoverGrid,
	RuleCardStatus, RuleCardStatusToggle, RuleCardStructure, RuleCardTag, RuleCardTagContainer,
	RuleCardTagsCount, RuleCardText,
	RuleCardTitle
} from "./styled/rule-card.styled";
import RuleCardActions from "./rule-card-actions";

const RuleCard = memo(function RuleCard(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let authHeader = useAuthHeader();

	const [ruleStatus, setRuleStatus] = useState(props.state);
	const [statusUpdateError, setStatusUpdateError] = useState("");
	const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
	const [openCopyConfirmationDialog, setOpenCopyConfirmationDialog] = useState(false);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

	const handleRuleAlert = (level, message) => {
		dispatch(setLevel(level));
		dispatch(setMessage(message));
		dispatch(setOpen(true));
	};

	const handleStatusToggleChange = () => {
		if (!props.state && props.draftStatus) {
			setStatusUpdateError(messages.TOGGLE_DRAFT_RULE_ERROR_MSG);
			return;
		}
		axios.put(apiRoutes.toggleRuleStatus + "/" + props.id, {}, {
			headers: authHeader
		})
			.then(() => {
				setRuleStatus(ruleStatus ? false : true);
				dispatch(setRuleGridUpdateSeed(Math.random()));
				handleRuleAlert("success", ruleStatus ? messages.RULE_DEACTIVATION_SUCCESS_MSG : messages.RULE_ACTIVATION_SUCCESS_MSG);
			})
			.catch(err => {
				console.log("error: " + err);
				handleRuleAlert("error", ruleStatus ? messages.RULE_DEACTIVATION_ERROR_MSG : messages.RULE_ACTIVATION_ERROR_MSG);
			});
	};

	const handleDeleteRule = () => {
		axios.delete(apiRoutes.deleteRule + "/" + props.id, {
			headers: authHeader
		})
			.then(() => {
				dispatch(setRuleGridUpdateSeed(Math.random()));
				setOpenDeleteConfirmationDialog(false);
				handleRuleAlert("success", messages.RULE_TRASHED_SUCCESS_MSG);
			})
			.catch(err => {
				console.error(err);
				handleRuleAlert("error", messages.RULE_DELETION_ERROR_MSG);
			});
	};

	const handleRestoreRule = () => {
		axios.put(apiRoutes.restoreRule + "/" + props.id, {}, {
			headers: authHeader
		})
			.then(() => {
				dispatch(setRuleGridUpdateSeed(Math.random()));
				handleRuleAlert("success", messages.RULE_RESTORE_SUCCESS_MSG);
			})
			.catch(err => {
				console.error(err);
				handleRuleAlert("error", messages.RULE_RESTORE_ERROR_MSG);
			});
	};

	const handleCopyRule = () => {
		axios.post(apiRoutes.copyRule + "/" + props.id, {}, {
			headers: authHeader
		})
			.then(() => {
				dispatch(setRuleGridUpdateSeed(Math.random()));
				setOpenCopyConfirmationDialog(false);
				handleRuleAlert("success", messages.RULE_COPY_SUCCESS_MSG);
			})
			.catch(err => {
				console.error(err);
				handleRuleAlert("error", messages.RULE_COPY_ERROR_MSG);
			});
	};

	const getShadowEffect = () => {
		if (props.state && !props.draftStatus) {
			return "#2FBD70";
		} else if (!props.state && !props.draftStatus) {
			return "orange";
		} else {
			return "#d32f2f";
		}
	};

	const handleEditRule = () => {
		navigate(pages.editRule(props.id));
	};

	const handlePopoverOpen = (e) => {
		if (props.tags.length <= 1) return;
		setPopoverAnchorEl(e.currentTarget);
		setPopoverOpen(true);
	};

	const handlePopoverClose = (e) => {
		setPopoverAnchorEl(null);
		setPopoverOpen(false);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteConfirmationDialog(false);
	};

	const handleCloseCopyDialog = () => {
		setOpenCopyConfirmationDialog(false);
	};

	useEffect(() => {
		setRuleStatus(props.state);
	}, [props.state]);

	return (
		<Grid item xs={6} >
			<RuleCardStructure shadowColor={props.trashedStatus ? "grey" : getShadowEffect()} isTrashed={props.trashedStatus}
				sx={{ opacity: props.trashedStatus ? 0.7 : 1 }}>

				<RuleCardActions
					openDeleteConfirmationHandler={setOpenDeleteConfirmationDialog}
					openCopyConfirmationHandler={setOpenCopyConfirmationDialog}
					editRuleHandler={handleEditRule}
					restoreRuleHandler={handleRestoreRule}
					copyRuleHandler={handleCopyRule}
					trashedStatus={props.trashedStatus}
				/>
				{
					props.ruleTitle.length < 35 ?
						<RuleCardTitle>{props.ruleTitle}</RuleCardTitle>
						:
						<Tooltip title={props.ruleTitle} placement='top-start'>
							<RuleCardTitle>{props.ruleTitle.substring(0, 25) + "..."}</RuleCardTitle>
						</Tooltip>
				}

				<RuleCardTagContainer onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
					{
						props.tags.length !== 0 ?
							props.tags.slice(0, 1).map(item =>
								<RuleCardTag key={item.id} >
									<Label fontSize='small' sx={{ paddingRight: 1 }} />{item.name.toUpperCase()}
								</RuleCardTag>
							)
							:
							null
					}

					{props.tags.length <= 1 ?
						null
						:
						<RuleCardTagsCount sx={{ color: "#7482A0" }}>+{props.tags.length - 1}</RuleCardTagsCount>
					}
				</RuleCardTagContainer>

				<Popper
					open={popoverOpen}
					anchorEl={popoverAnchorEl}
					sx={{
						pointerEvents: "none",
						marginTop: "-10px"
					}}
					style={{ transform: "translate(20px, -10px)" }}
					transition
				>
					{({ TransitionProps }) => (
						<Fade {...TransitionProps} timeout={350}>
							<RuleCardPopoverGrid title='tags' placement='top-start'>
								{props.tags.map(item =>
									<RuleCardTag sx={{ marginBottom: "2px" }} key={item.id}>
										<Label fontSize='small' sx={{ paddingRight: 1 }} />{item.name.toUpperCase()}
									</RuleCardTag>

								)}
							</RuleCardPopoverGrid>
						</Fade>
					)}
				</Popper>

				<RuleCardText>
					{props.ruleText}
				</RuleCardText>

				<Divider variant='fullWidth' />

				{props.groups.length !== 0 ?
					props.groups.slice(0, 3).map(item =>
						<RuleCardGroup key={item.id}><Groups fontSize='small' sx={{ paddingRight: 1 }} />
							{item.name.toUpperCase()}
						</RuleCardGroup>)
					:
					null
				}

				{props.groups.length <= 3 ?
					null
					:
					<RuleCardGroupsCount>+{props.groups.length - 3}</RuleCardGroupsCount>
				}

				<div>
					<RuleCardStatusToggle
						disabled={props.trashedStatus}
						checked={ruleStatus}
						onChange={handleStatusToggleChange}
						sx={{ float: "right", marginTop: "8px", marginLeft: "5px" }}
					/>
					{
						["#2FBD70", "orange"].includes(getShadowEffect()) ?
							<RuleCardStatus>{getShadowEffect() === "#2FBD70" ? "Active" : props.trashedStatus ? "Deleted" : "Inactive"}</RuleCardStatus>
							:
							<RuleCardStatus>Draft</RuleCardStatus>
					}
				</div>
			</RuleCardStructure>

			<Dialog onClose={() => setStatusUpdateError("")} open={statusUpdateError !== ""}>
				<Alert severity="error">{statusUpdateError}</Alert>
			</Dialog>

			<Dialog onClose={openDeleteConfirmationDialog ? handleCloseDeleteDialog : handleCloseCopyDialog} open={openDeleteConfirmationDialog || openCopyConfirmationDialog}>
				{
					openDeleteConfirmationDialog ?
						<DialogTitle>{messages.RULE_DELETION_CONFIRMATION_MSG}</DialogTitle>
						:
						<DialogTitle>{messages.RULE_COPY_CONFIRMATION_MSG}</DialogTitle>
				}

				<DialogContent sx={{ margin: "auto", display: "inline-block" }}>
					<RuleCardDialogContent>Rule: {props.ruleTitle}</RuleCardDialogContent>
				</DialogContent>

				<DialogActions>
					<CoAppDialogIconButton onClick={openDeleteConfirmationDialog ? handleCloseDeleteDialog : handleCloseCopyDialog}><Cancel /></CoAppDialogIconButton>
					<CoAppDialogIconButton onClick={openDeleteConfirmationDialog ? handleDeleteRule : handleCopyRule}><Check /></CoAppDialogIconButton>
				</DialogActions>
			</Dialog>

		</Grid>
	);
});

export default RuleCard;