import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDescope } from "@descope/react-sdk";
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import prettifyDate from "../../../helpers/prettifyDate";
import useAuthHeader from "../../../helpers/useAuthHeader";
import useAuthorization from "../../../helpers/useAuthorization";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import { selectOrganizationSsoAndScim, selectUser, setPermissions } from "../../../redux/userSlice";
import {
	setFirstName, setGroups,
	setLastName, setOrgId, setPhone, setRoles,
	setUserId
} from "../../../redux/userSlice";
import Unauthorized from "../../global/components/unauthorized";
import { CoAppStatusToggle } from "../../global/styled/global.styled";
import SettingsMenuBack from "../settings-menu-back";

import {
	UserProfileBox, UserProfileCancelButton,
	UserProfileHeaderStructure,
	UserProfileInputLabel,
	UserProfilePersonIcon, UserProfileSaveChangesButton,
	UserProfileStructure, UserProfileTextField, UserProfileTitle,
	UserProfileToggleStatusContainer, UserProfileTypography
} from "./styled/user-profile.styled";

const initialUserDetails = {
	onLoadDetails: {
		active: null,
		firstName: "",
		lastName: "",
		roles: [],
		groups: []
	},
	onEditDetails: {
		active: null,
		firstName: "",
		lastName: "",
		roles: [],
		groups: []
	}
};
export default function UserProfile() {
	let authHeader = useAuthHeader();
	const isAuthorized = useAuthorization(["MANAGE_USERS_AND_GROUPS"]);
	const isSsoAndScimEnabled = useSelector(selectOrganizationSsoAndScim);

	const url = window.location.href.split("?");
	const from = url[1] ? url[1].split("from=")[1] : null;

	const user = useSelector(selectUser);
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { logout } = useDescope();

	const [isEditing, setIsEditing] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const [availableGroups, setAvailableGroups] = useState([]);
	const [availableRoles, setAvailableRoles] = useState([]);
	const [email, setEmail] = useState("");
	const [lastExtensionLogin, setLastExtensionLogin] = useState("");
	const [lastExtensionRefresh, setLastExtensionRefresh] = useState("");
	const [userCreationDate, setUserCreationDate] = useState("");
	const [userDetails, setUserDetails] = useState(initialUserDetails);
	const [userUpdateSeed, setUserUpdateSeed] = useState(0);

	const initUserProfile = () => {
		const isSelf = user.userId === +id ? 1 : 0;
		axios.get(apiRoutes.getUser(isSelf, id, ""), { headers: authHeader })
			.then(async user => {
				let details = {
					onLoadDetails: {
						active: user.data.active,
						firstName: user.data.firstname,
						lastName: user.data.lastname,
						roles: user.data.roles,
						groups: user.data.groups.map(g => g.name),
					},
					onEditDetails: {
						firstName: user.data.firstname,
						lastName: user.data.lastname,
						roles: user.data.roles,
						groups: user.data.groups.map(g => g.name),
					}
				};
				setUserDetails(details);
				setEmail(user.data.email);

				let lastLogin = user.data.lastExtensionLogin === null ? "Never" : prettifyDate(user.data.lastExtensionLogin);
				setLastExtensionLogin(lastLogin);
				let lastRefresh = user.data.lastExtensionRefresh === null ? "Never" : prettifyDate(user.data.lastExtensionRefresh);
				setLastExtensionRefresh(lastRefresh);
				setUserCreationDate(prettifyDate(user.data.createdAt));

				if (isAuthorized) {
					initRolesAndGroupsForEditing();
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	const initRolesAndGroupsForEditing = () => {
		axios.get(apiRoutes.getGroups, { headers: authHeader })
			.then(res => {
				setAvailableGroups(res.data);
			})
			.catch(err => {
				console.log(err);
			});

		axios.get(apiRoutes.getRoles, { headers: authHeader })
			.then(res => {
				setAvailableRoles(res.data);
			})
			.catch(err => {
				console.log(err);
			});
	};

	/**
	 * User confirms account de-activation
	 * - Deactive user
	 * - If currently logged in user, log them out, redirect to login page.
	 * - If not currently logged in user, just de-activate user. 
	 */
	const handleDeactivateAccountClick = () => {
		let userJSON = {
			active: false
		};
		axios.put(apiRoutes.setUserActive + "/" + id, userJSON, {
			headers: authHeader
		}).then(() => {
			if (user.userId === +id) {
				logout();
				dispatch(setUserId(null));
				dispatch(setEmail(null));
				dispatch(setFirstName(null));
				dispatch(setLastName(null));
				dispatch(setPhone(null));
				dispatch(setRoles(null));
				dispatch(setOrgId(null));
				navigate(pages.login);
			}
		}).catch((err) => {
			console.log(err);
			return null;
		});
	};

	const handleSaveChangesClick = () => {
		let userJSON = {};
		const { onLoadDetails, onEditDetails } = userDetails;

		if (onLoadDetails.firstName !== onEditDetails.firstName) {
			userJSON.firstName = onEditDetails.firstName;
		}
		if (onLoadDetails.lastName !== onEditDetails.lastName) {
			userJSON.lastName = onEditDetails.lastName;
		}
		if (JSON.stringify(onLoadDetails.roles) !== JSON.stringify(onEditDetails.roles)) {
			userJSON.roles = onEditDetails.roles;
		}
		if (JSON.stringify(onLoadDetails.groups) !== JSON.stringify(onEditDetails.groups)) {
			userJSON.groups = onEditDetails.groups;
		}

		if (Object.keys(userJSON).length > 0) {
			axios.put(apiRoutes.updateUser + "/" + id, userJSON, {
				headers: authHeader
			}).then((res) => {
				if (user.userId === + id) {
					dispatch(setFirstName(res.data.firstname));
					dispatch(setLastName(res.data.lastname));
					dispatch(setGroups(res.data.groups));
					dispatch(setRoles(res.data.roles));
					dispatch(setPermissions(res.data.permissions));
				}
				handleUpdateUserAlert("success");
			}).catch((err) => {
				handleUpdateUserAlert("error");
				setUserDetails({ ...userDetails, onEditDetails: userDetails.onLoadDetails });
				console.log(err);
				return null;
			});
		}
	};

	const handleUpdateUserAlert = (level) => {
		setUserUpdateSeed(Math.random());
		setIsEditing(false);
		dispatch(setOpen(true));
		dispatch(setMessage(level === "success" ? messages.USER_UPDATE_SUCCESS_MSG : messages.USER_UPDATE_ERROR_MSG));
		dispatch(setLevel(level));
	};

	const handleToggleDialog = () => {
		setIsDialogOpen(isDialogOpen ? false : true);
	};

	const handleValueChange = (e, field) => {
		setUserDetails({ ...userDetails, onEditDetails: { ...userDetails.onEditDetails, [e.target.id !== undefined ? e.target.id : field]: e.target.value } });
		setIsEditing(true);
	};

	const handleCancelButtonClick = () => {
		setUserDetails({ ...userDetails, onEditDetails: userDetails.onLoadDetails });
		setIsEditing(false);
	};

	useEffect(() => {
		if (isAuthorized || user.userId === +id) {
			initUserProfile();
		}
	}, [userUpdateSeed]);

	if (isAuthorized || user.userId === +id) {
		return (
			<UserProfileStructure>
				{
					from === "users"
						?
						<SettingsMenuBack buttonText="All Users" navigateTo={pages.settings + "?from=user"} />
						:
						null
				}

				<UserProfileHeaderStructure>
					<UserProfileTitle>Account Details</UserProfileTitle>
					<UserProfileToggleStatusContainer>
						{userDetails.onLoadDetails.active
							? <p>Active</p> : <p>Inactive</p>
						}
						<CoAppStatusToggle checked={userDetails.onLoadDetails.active} onClick={handleToggleDialog} disabled={!isAuthorized || isSsoAndScimEnabled} />
					</UserProfileToggleStatusContainer>
				</UserProfileHeaderStructure>
				<Divider sx={{ width: "100%" }} />

				<UserProfileBox>
					<Grid container spacing={2}>
						<Grid item xs={1}><UserProfilePersonIcon /></Grid>
						<Grid item xs={3}>
							<UserProfileTextField
								id="firstName"
								label="First Name"
								onChange={handleValueChange}
								value={userDetails.onEditDetails.firstName}
								variant="standard"
								disabled={!isAuthorized || isSsoAndScimEnabled}
								sx={{ width: { md: "160px", xl: "200px" }, marginLeft: { md: "40px", xl: "20px" } }}
							/>
						</Grid>
						<Grid item xs={3.4}>
							<UserProfileTextField
								id="lastName"
								variant="standard"
								label="Last Name"
								value={userDetails.onEditDetails.lastName}
								onChange={handleValueChange}
								disabled={!isAuthorized || isSsoAndScimEnabled}
								sx={{ width: { md: "160px", xl: "200px" }, marginLeft: { md: "0px", xl: "-120px" } }}
							/>
						</Grid>
						<Grid item xs={4}>
							<UserProfileTextField
								disabled={true}
								id="email"
								label="Email"
								isemail="true"
								value={email}
								variant="standard"
								sx={{ width: { md: "550px", xl: "580px" }, marginLeft: { md: "-100px", xl: "-125px" } }}
							/>
						</Grid>
						<Grid item xs={6.4}>
							<FormControl>
								<UserProfileInputLabel id="roles">Role(s)</UserProfileInputLabel>
								<Select
									disabled={!isAuthorized || isSsoAndScimEnabled}
									id="roles"
									labelId="roles"
									multiple
									onChange={(e) => handleValueChange(e, "roles")}
									renderValue={(selected) => selected.join(", ")}
									sx={{ width: { md: "550px", xl: "580px" } }}
									value={userDetails.onEditDetails.roles}
									variant="standard"
								>
									{
										availableRoles.map(role => {
											return (
												<MenuItem key={role.id} value={role.name}>
													<Checkbox checked={userDetails.onEditDetails.roles.indexOf(role.name) > -1} />
													{role.name}
												</MenuItem>
											);
										})
									}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={5}>
							<FormControl>
								<UserProfileInputLabel id="groups">Groups(s)</UserProfileInputLabel>
								<Select
									disabled={!isAuthorized || isSsoAndScimEnabled}
									id="groups"
									labelId="groups"
									multiple
									onChange={(e) => handleValueChange(e, "groups")}
									renderValue={(selected) => selected.join(", ")}
									sx={{ width: { md: "550px", xl: "580px" } }}
									variant="standard"
									value={userDetails.onEditDetails.groups}
								>
									{
										availableGroups.map(group => {
											return (
												<MenuItem key={group.id} value={group.name}>
													<Checkbox checked={userDetails.onEditDetails.groups.indexOf(group.name) > -1} />
													<ListItemText primary={group.name} />
												</MenuItem>);
										})
									}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={3}>
							<UserProfileTypography>Last Extension Login: {lastExtensionLogin}</UserProfileTypography>
						</Grid>
						<Grid item xs={3}>
							<UserProfileTypography>Last Extension Refresh: {lastExtensionRefresh}</UserProfileTypography>
						</Grid>
						<Grid item xs={3}>
							<UserProfileTypography>Created On: {userCreationDate}</UserProfileTypography>
						</Grid>
						<Grid item xs={3}>
							{
								isEditing ?
									<div>
										<UserProfileCancelButton onClick={handleCancelButtonClick}>Cancel</UserProfileCancelButton>
										<UserProfileSaveChangesButton onClick={handleSaveChangesClick}>Save Changes</UserProfileSaveChangesButton>
									</div>
									:
									null
							}
						</Grid>
					</Grid>
				</UserProfileBox>

				<Dialog open={isDialogOpen} onClose={handleToggleDialog}>
					<DialogTitle>Deactivate - Confirmation</DialogTitle>
					<DialogContent>
						<Grid container spacing={1} justifyContent="space-evenly">
							<Grid item xs={12}>
								<Typography>
									Are you sure you want to deactivate this account?
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Button sx={{ backgroundColor: "#2FBD70" }} onClick={handleDeactivateAccountClick}>Yes, deactivate my account</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			</UserProfileStructure >
		);
	} else {
		return (
			<Unauthorized />
		);
	}
}