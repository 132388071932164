import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cancel from "@mui/icons-material/Cancel";
import { Alert, IconButton, Slide, Snackbar } from "@mui/material";

import { selectLevel, selectMessage, selectOpen, setOpen } from "../../../redux/alertSlice";

const AlertTransition = (props) => {
	return <Slide {...props} direction="right" />;
};

export default function AlertSnackbar() {
	const dispatch = useDispatch();
	const level = useSelector(selectLevel);
	const message = useSelector(selectMessage);
	const open = useSelector(selectOpen);

	const handleClose = () => {
		dispatch(setOpen(false));
	};

	const action = (
		<Fragment>
			<IconButton onClose={handleClose}><Cancel /></IconButton>
		</Fragment>
	);

	return (
		<Snackbar
			autoHideDuration={6000}
			open={open}
			action={action}
			onClose={handleClose}
			TransitionComponent={AlertTransition}
		>
			<Alert open={open} onClose={handleClose} severity={level !== "" ? level : "info"} >
				{message}
			</Alert>
		</Snackbar>
	);
}