export default function prettifyDate(date) {
	return new Date(date).toLocaleString(undefined, {
		timeZone: "America/New_York",
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
		hour12: true,
	});
}