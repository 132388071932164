import { Fragment, useEffect } from "react";
import { Alert, Box, Dialog, Stack, Step, StepButton, StepLabel, Stepper, Tooltip } from "@mui/material";

import { CoAppButtonGreenBg, CoAppButtonWhiteBg } from "../../global/styled/global.styled";
import SaveProgressIndicator from "../rules/save-progress-indicator";
import { WizardStepButtonsDiv, WizardStepConnector, WizardStepIconRoot } from "../styled/rule-wizard.styled";

function WizardStepIcon(props) {
	const { active, completed, className } = props;
	if (completed) {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-completedIcon" />
			</WizardStepIconRoot>);
	} else if (active && !completed) {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle-active-incomplete" />
			</WizardStepIconRoot>
		);
	} else if (!active && !completed) {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle-inactive-incomplete" />
			</WizardStepIconRoot>
		);
	} else {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle" />
			</WizardStepIconRoot>
		);
	}
}

export default function WizardStepper(props) {
	const activeStep = props.activeStep;
	const steps = props.steps;
	const stepsCompleted = props.stepsCompleted;
	const handleStepButtonClick = props.handleStepButtonClick;
	const handleSubmit = props.handleSubmit;
	const handleBack = props.handleBack;
	const handleExitEditing = props.handleExitEditing;
	const isSavingProgress = props.isSavingProgress;
	const wizardFormError = props.wizardFormError;
	const setWizardFormError = props.setWizardFormError;

	useEffect(() => {
	}, [isSavingProgress]);

	return (
		<Fragment>
			<WizardStepButtonsDiv>
				<Stack justifyContent="center" sx={{ width: "100%" }} direction="row" >
					<Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
						<CoAppButtonWhiteBg onClick={handleExitEditing}>Exit</CoAppButtonWhiteBg>
					</Box>
					<Stepper connector={<WizardStepConnector />} alternativeLabel nonLinear activeStep={activeStep} sx={{ width: "50%" }}>
						{steps.map(step => (
							<Step completed={stepsCompleted[step.num - 1]} key={step.num}>
								<Tooltip title={step.desc} placement="top">
									<StepButton onClick={() => handleStepButtonClick(step.num)}>
										<StepLabel StepIconComponent={WizardStepIcon} />
									</StepButton>
								</Tooltip>
							</Step>
						))}
					</Stepper>

					<Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
						<Fragment>
							<Box sx={{ flex: "1 1 auto" }} />
							<Fragment>
								<CoAppButtonWhiteBg onClick={handleBack} disabled={activeStep === 0}>
									Back
								</CoAppButtonWhiteBg>

								{activeStep === 4 ? (
									<CoAppButtonGreenBg color="inherit" onClick={handleSubmit}>
										Finish
									</CoAppButtonGreenBg>
								) : (
									<CoAppButtonGreenBg color="inherit" onClick={handleSubmit}>
										Next
									</CoAppButtonGreenBg>
								)}
							</Fragment>
						</Fragment>
					</Box>

					<SaveProgressIndicator activeStep={activeStep} isSavingProgress={isSavingProgress} />

				</Stack>
			</WizardStepButtonsDiv>

			<Dialog onClose={() => setWizardFormError("")} open={wizardFormError !== ""}>
				<Alert severity="error">{wizardFormError}</Alert>
			</Dialog>
		</Fragment>
	);
}