import { Card, styled, Typography } from "@mui/material";

export const FormulaContainer = styled(Card)(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	width: "1125px",
	height: "500px",
	padding: "50px",
	margin: "15px",
	border: "1px solid #A9A9A9",
	borderRadius: theme.spacing(2),
	display: "inline-block",
	[theme.breakpoints.down(1700)]: {
		width: "950px"
	}
}));

export const FormulaTitle = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "1rem",
	fontWeight: "600",
	color: "#7482A0",
	padding: "10px",
	display: "flex"
}));

export const FormulaInput = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "0.75rem",
	fontWeight: "500",
	color: "#7482A0",
	width: "1105px",
	height: "200px",
	border: "solid 1px #A9A9A9",
	borderRadius: theme.spacing(2),
	padding: "10px",
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	[theme.breakpoints.down(1700)]: {
		width: "920px"
	}
}));

export const FormulaItem = styled("div")(({ theme }) => ({
	backgroundColor: "#FFFFFF",
	border: "solid 1px #DDDDDD",
	borderRadius: "5px",
	fontSize: "1rem",
	fontWeight: "600",
	color: "#111111",
	padding: "5px",
	margin: "5px",
	flexGrow: "1",
}));

export const FormulaItemOtherInstance = styled(Typography)(({ theme }) => ({
	color: "#111111",
	fontSize: ".6rem"
}));

export const TrashInput = styled("div")(({ theme }) => ({
	borderRadius: "13px",
	height: "50px",
	padding: "25px",
}));