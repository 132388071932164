import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ChevronRight, ExpandMore, Info } from "@mui/icons-material";
import { TreeView } from "@mui/x-tree-view";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import messages from "../../constants/messages";
import useAuthHeader from "../../helpers/useAuthHeader";
import useRuleFilter from "../../helpers/useRuleFilter";
import { setRuleGridUpdateSeed, setRules, setRulesDidLoadFlag, setRulesLoadError } from "../../redux/ruleSlice";
import { CoAppButtonWhiteBg, CoAppH2, CoAppH5 } from "../global/styled/global.styled";

import {
	StyledTreeItemRoot,
	TagComponentTooltip,
	TagLibraryComponentContainer,
	TagLibraryComponentTitleContainer
} from "./styled/tag-library-component.styled";
import TagDeletionDialog from "./tag-deletion-dialog";
import TagDialog from "./tag-dialog";
import CustomContent from "./tag-tree-item-custom-content";


export default function TagLibraryComponent(props) {
	const dispatch = useDispatch();
	let authHeader = useAuthHeader();
	const updateAndGetRuleFilters = useRuleFilter();

	const [unorderedTags, setUnorderedTags] = useState([]);
	let [orderedTags, setOrderedTags] = useState([]);
	let [tagsLoaded, setTagsLoaded] = useState(false);
	const [tagDialogOpenFlag, setTagDialogOpenFlag] = useState(false);
	const [tagDeletionDialogOpenFlag, setTagDeletionDialogOpenFlag] = useState(false);
	const [currParentTags, setCurrParentTags] = useState([]);
	const [tagName, setTagName] = useState("");
	const [tagParentId, setTagParentId] = useState("");
	const [tagNameError, setTagNameError] = useState({ errorState: false, errorMessage: "" });
	const [tagEditFlag, setTagEditFlag] = useState(false);
	const [selectedTagId, setSelectedTagId] = useState("");
	const [deletedTagData, setDeletedTagData] = useState({});

	const resetValues = () => {
		setTagDialogOpenFlag(false);
		setTagNameError({ errorState: false, errorMessage: "" });
		setTagParentId("");
		setTagName("");
		setTagEditFlag(false);
		setSelectedTagId("");
		setTagDeletionDialogOpenFlag(false);
	};

	const setEditOpenValues = (nodeId) => {
		let existingTag = unorderedTags.filter(tag => tag.id === +nodeId);
		setTagName(existingTag[0]["name"]);
		setSelectedTagId(existingTag[0]["id"]);
		setTagParentId(existingTag[0]["parentId"] === null ? "" : existingTag[0]["parentId"]);
		setTagDialogOpenFlag(true);
		setTagEditFlag(true);
		initParentTags(existingTag[0]["id"]);
	};

	const setDeletionOpenValues = (tagData) => {
		setDeletedTagData(tagData);
		setSelectedTagId(tagData["id"]);
		setTagDeletionDialogOpenFlag(true);
	};

	const deleteExistingTag = () => {
		axios.delete(apiRoutes.deleteTag + "/" + selectedTagId, {
			headers: authHeader,
		}).then(() => {
			resetValues();
			initTags();
			initParentTags("");
			dispatch(setRuleGridUpdateSeed(Math.random()));
		}).catch(err => {
			setTagNameError({ errorState: true, errorMessage: err.response.data.message });
			setTagDialogOpenFlag(true);
		});
	};

	const updateExistingTag = () => {
		let newTagData = {
			name: tagName
		};
		setTagNameError("");
		if (tagParentId !== "") {
			newTagData["parentId"] = tagParentId;
		}
		axios.put(apiRoutes.updateTag + "/" + selectedTagId, newTagData, {
			headers: authHeader
		})
			.then(() => {
				resetValues();
				initTags();
				initParentTags("");
			})
			.catch(err => {
				setTagNameError({ errorState: true, errorMessage: err.response.data.message });
				setTagDialogOpenFlag(true);
			});
	};

	const createNewTag = () => {
		let newTagData = {
			name: tagName
		};
		setTagNameError("");
		if (tagParentId !== "") {
			newTagData["parentId"] = tagParentId;
		}
		axios.post(apiRoutes.createTag, newTagData, {
			headers: authHeader
		})
			.then(() => {
				resetValues();
				initTags();
				initParentTags("");
			})
			.catch(err => {
				setTagNameError({ errorState: true, errorMessage: err.response.data.message });
				setTagDialogOpenFlag(true);
			});
	};

	const initTags = () => {
		setTagsLoaded(false);
		orderedTags = [];
		axios.get(apiRoutes.getTags, {
			headers: authHeader
		})
			.then(res => {
				setUnorderedTags(res.data);
				buildTreeStructure(res.data);
			})
			.catch(err => console.log(err.message));
	};

	const initParentTags = (editedTagId) => {
		axios.get(apiRoutes.getPossibleParentTags, {
			headers: authHeader
		})
			.then(res => {
				if (editedTagId) {
					setCurrParentTags(res.data.filter(item => item.id !== editedTagId));
				} else {
					setCurrParentTags(res.data);
				}
			})
			.catch(err => console.error(err));
	};

	const handleTagNameInputChange = (e) => {
		setTagName(e.target.value);
	};

	const handleAddNewDialogClose = () => {
		resetValues();
		setTagDialogOpenFlag(false);
	};


	const handleAddNewDialogOpen = () => {
		initParentTags("");
		setTagDialogOpenFlag(true);
	};

	const handleParentTagSelectionChange = (e) => {
		setTagParentId(e.target.value);
	};

	/**
	 * Triggered when a user clicks on a tag
	 * Adds tag to queryObj and filtered Rules in the Rule Library component
	 */
	const handleNodeSelect = (event, nodeIds) => {
		let queryObj = updateAndGetRuleFilters("tag", nodeIds);

		axios.get(apiRoutes.getRules, {
			headers: authHeader,
			params: queryObj, paramsSerializer: { indexes: null }
		})
			.then(res => {
				if (res.data.length === 0) {
					dispatch(setRulesLoadError("No rules found."));
				} else {
					dispatch(setRules(res.data.rules));
					dispatch(setRulesDidLoadFlag(true));
					dispatch(setRulesLoadError(""));
				}
			})
			.catch(err => dispatch(setRulesLoadError(err.message)));
	};

	const buildTreeStructure = (unorderedTags) => {
		let childTags = [];
		unorderedTags.forEach(function (tag) {
			if (tag.parentId == null) {
				let parentTag = tag;
				parentTag.children = [];
				orderedTags.push(parentTag);
			} else {
				childTags.push(tag);
			}
		});
		childTags.forEach(function (childTag) {
			var parentTag = orderedTags.filter(obj => obj.id === childTag.parentId);
			parentTag[0].children.push(childTag);
		});
		setOrderedTags(orderedTags);
		setTagsLoaded(true);
	};

	/**
	 * Load tags
	 * Build Tree Structure
	 */
	useEffect(() => {
		initTags();
	}, []);

	function StyledTreeItem(props) {
		return <StyledTreeItemRoot ContentComponent={forwardRef(CustomContent)} {...props} />;
	}

	const renderTree = (item) => (
		<StyledTreeItem
			data-testid={`tag_${item.name}`}
			key={item.id}
			nodeId={item.id.toString()}
			label={item.name}
			ContentProps={{
				count: item.count,
				openEditDialogHandler: setEditOpenValues,
				openDeletionDialogHandler: setDeletionOpenValues
			}}
		>
			{Array.isArray(item.children) ? item.children.map((node) => renderTree(node)) : null}
		</StyledTreeItem>
	);

	return (
		<TagLibraryComponentContainer>
			<TagLibraryComponentTitleContainer>
				<CoAppH2>Tags</CoAppH2>
				<TagComponentTooltip title={messages.TAG_DESELECTION_TOOLTIP_INFO} placement='top-end' sx={{ maxWidth: "250px" }} arrow>
					<Info fontSize='small' />
				</TagComponentTooltip>
				<CoAppButtonWhiteBg onClick={handleAddNewDialogOpen} data-testid="add-new-tag">ADD NEW</CoAppButtonWhiteBg>
				<TagDialog
					openDialogFlag={tagDialogOpenFlag}
					cancelDialogHandler={handleAddNewDialogClose}
					onClick={handleAddNewDialogOpen}
					parentTags={currParentTags}
					parentTagSelectionChangeHandler={handleParentTagSelectionChange}
					tagNameChangeHandler={handleTagNameInputChange}
					tagError={tagNameError}
					saveNewTagHandler={createNewTag}
					parentTagId={tagParentId}
					tagName={tagName}
					editingTagFlag={tagEditFlag}
					updateTagHandler={updateExistingTag}
				/>
				<TagDeletionDialog
					deleteTagHandler={deleteExistingTag}
					deleteTagFlag={tagDeletionDialogOpenFlag}
					tagBeingDeletedData={deletedTagData}
					closeDeletionDialogHandler={resetValues}
				/>
			</TagLibraryComponentTitleContainer>

			{
				tagsLoaded && unorderedTags.length > 0 ?
					<TreeView
						multiSelect
						defaultCollapseIcon={<ExpandMore />}
						defaultExpandIcon={<ChevronRight />}
						onNodeSelect={handleNodeSelect}
						sx={{ marginTop: "15px" }}
					>
						{orderedTags.map(item => renderTree(item))}
					</TreeView>
					:
					<CoAppH5>{tagsLoaded && unorderedTags.length === 0 ? "No tags exist." : "Loading Tags..."}</CoAppH5>
			}
		</TagLibraryComponentContainer>
	);
}