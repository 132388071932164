import { styled } from "@mui/material";

export const LoginContainer = styled("div")(({ theme }) => ({
	background: "#F8F9FA",
	display: "inline-block",
	marginLeft: "35vw",
	marginTop: "22vh",
	[theme.breakpoints.down(1700)]: {
		marginLeft: "28vw",
		marginTop: "12vh"
	}
}));

