import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectOrganizationId } from "../../../../redux/userSlice";
import ConfirmationDialog from "../../../global/components/confirmation-dialog";
import DataGridCustomNoRowsOverlay from "../../../global/components/datagrid-custom-norows-overlay";
import { CoAppH2, CoAppMainContainer, CoAppStack, CoAppStackItem, CoAppStatusToggle } from "../../../global/styled/global.styled";
import { OrganizationManagementItemTitle } from "../../organization/styled/organization-management.styled";
import { SubMenuContainer } from "../../styled/settings.styled";

export default function GroupPlugins(props) {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const organizationId = useSelector(selectOrganizationId);
	const group = props.group;
	const [groupPlugins, setGroupPlugins] = useState([]);

	const [dialogDetails, setDialogDetails] = useState({ dialogOpen: false });

	const deactivatePlugin = (plugin) => {
		axios.put(apiRoutes.updateGroupPlugin(group.id, plugin.id), { active: !plugin.activeForGroup }, { headers: authHeader })
			.then(() => {
				setDialogDetails({ dialogOpen: false });
				init();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(messages.GROUP_PLUGIN_TOGGLE_SUCCESS_MSG));
			})
			.catch((error) => {
				console.log(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.GROUP_PLUGIN_TOGGLE_ERROR_MSG));
			});
	};

	const handleOrganizationalPluginRetrieval = async () => {
		let plugins = [];
		await axios.get(apiRoutes.getPlugins(organizationId), { headers: authHeader })
			.then((response) => {
				if (response.data.length === 0) return;

				const platforms = response.data;

				platforms.forEach(platform => {
					if (!platform.plugins) {
						return;
					}
					platform.plugins.forEach(plugin => {
						if (!plugin.active) return;
						plugins.push({
							id: plugin.id,
							name: plugin.name,
							groups: plugin.groups.map(group => group.name),
							active: plugin.active,
							platformId: platform.id,
						});
					});
				});
			})
			.catch((error) => {
				console.log(error);
			});
		return plugins;
	};

	const handleOpenToggleConfirmDialog = (plugin) => {
		setDialogDetails({
			dialogOpen: true,
			confirmationTitle: `Are you sure you want to disable the ${plugin.name} plugin for the ${group.name} group?`,
			dialogContent: "Are you sure you want to deactivate this plugin?",
			confirmClickHandler: () => deactivatePlugin(plugin),
			cancelClickHandler: () => setDialogDetails({ dialogOpen: false }),
		});
	};

	const init = async () => {
		await axios.get(apiRoutes.getGroupPlugins(group.id), { headers: authHeader })
			.then(async (response) => {
				let orgPlugins = await handleOrganizationalPluginRetrieval();
				let plugins = orgPlugins.map(orgPlugin => {
					const groupPlugin = response.data.find(groupPlugin => groupPlugin.id === orgPlugin.id);
					return {
						...orgPlugin,
						activeForGroup: groupPlugin === undefined ? false : groupPlugin.active
					};

				});
				plugins = plugins.sort((a, b) => a.name.localeCompare(b.name));
				setGroupPlugins(plugins);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		init();
	}, []);

	return (
		<CoAppMainContainer sx={{ p: 2 }}>
			<SubMenuContainer sx={{ justifyContent: "space-between" }}>
				<CoAppH2 sx={{ marginLeft: "40px" }}>Plugins</CoAppH2>
			</SubMenuContainer>
			<Divider sx={{ width: "95%", marginBottom: "10px", marginLeft: "45px" }} />

			{
				groupPlugins.length === 0 && (
					<Box sx={{ width: "100%" }}>
						<DataGridCustomNoRowsOverlay message={"No Plugins Yet!"} />
					</Box>
				)
			}

			{
				groupPlugins.length > 0 && (
					<CoAppStack
						space={1}
						divider={<Divider sx={{ width: "95%" }} />}
						sx={{
							width: "95%",
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 1.97px 20px -0.99px #00000021",
							borderRadius: "20.12px"
						}}
					>
						{groupPlugins.map((plugin, index) => {
							return (
								<CoAppStackItem key={index} sx={{ justifyContent: "flex-start", boxShadow: "none", borderRadius: "0%", mb: "0px" }}>
									<OrganizationManagementItemTitle style={{ width: "200px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{plugin.name}</OrganizationManagementItemTitle>
									<CoAppStatusToggle sx={{ marginLeft: "100px" }} size="medium" checked={plugin.activeForGroup} onChange={() => handleOpenToggleConfirmDialog(plugin)} />
								</CoAppStackItem>
							);
						})}
					</CoAppStack>
				)
			}
			<ConfirmationDialog dialogDetails={dialogDetails} />
		</CoAppMainContainer >
	);
}