import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currentPage: "",
	rowsPerPage: 20,
	totalPages: [],
	rules: [],
	initRules: [],
	ruleLoadError: "",
	rulesDidLoadFlag: false,
	filters: {},
	ruleGridUpdateSeed: null
};

export const ruleSlice = createSlice({
	name: "rule",
	initialState,
	reducers: {
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},
		setRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload;
		},
		setTotalPages: (state, action) => {
			state.totalPages = action.payload;
		},
		setRules: (state, action) => {
			state.rules = action.payload;
		},
		setInitRules: (state, action) => {
			state.initRules = action.payload;
		},
		setRulesLoadError: (state, action) => {
			state.ruleLoadError = action.payload;
		},
		setRulesDidLoadFlag: (state, action) => {
			state.rulesDidLoadFlag = action.payload;
		},
		setRuleFilters: (state, action) => {
			state.filters = action.payload;
		},
		setRuleGridUpdateSeed: (state, action) => {
			state.ruleGridUpdateSeed = action.payload;
		}
	}
});

export const selectCurrentPage = (state) => state.rule.currentPage;
export const selectRowsPerPage = (state) => state.rule.rowsPerPage;
export const selectTotalPages = (state) => state.rule.totalPages;
export const selectRules = (state) => state.rule.rules;
export const selectInitRules = (state) => state.rule.initRules;
export const selectRuleLoadError = (state) => state.rule.ruleLoadError;
export const selectRuleDidLoadFlag = (state) => state.rule.rulesDidLoadFlag;
export const selectRuleFilters = (state) => state.rule.filters;
export const selectRuleGridUpdateSeed = (state) => state.rule.ruleGridUpdateSeed;

export const {
	setCurrentPage,
	setRowsPerPage,
	setTotalPages,
	setRules,
	setInitRules,
	setRulesDidLoadFlag,
	setRulesLoadError,
	setRuleFilters,
	setRuleGridUpdateSeed
} = ruleSlice.actions;

export default ruleSlice.reducer;