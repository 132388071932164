import { useState } from "react";
import { Delete, Edit, Label, LabelOutlined } from "@mui/icons-material";
import { useTreeItem } from "@mui/x-tree-view";
import clsx from "clsx";

import messages from "../../constants/messages";

import { TagComponentTooltip, TagLibraryTreeItemCounter, TagLibraryTreeItemLabel } from "./styled/tag-library-component.styled";

export default function CustomContent(props, ref) {

	const {
		label,
		nodeId,
		expansionIcon,
		displayIcon,
		classes,
		className,
		openDeletionDialogHandler,
		openEditDialogHandler
	} = props;

	const {
		disabled,
		expanded,
		selected,
		handleExpansion,
		handleSelection,
		preventSelection
	} = useTreeItem(nodeId);

	const [isHovered, setIsHovered] = useState(false);

	const handleHoverChange = () => {
		setIsHovered((previousState) => !previousState);
	};

	const handleEditButtonClick = () => {
		openEditDialogHandler(nodeId);
	};
	const handleDeleteButtonClick = () => {
		let nodeData = {
			id: nodeId,
			name: label
		};
		openDeletionDialogHandler(nodeData);
	};

	const handleSelectionChange = (event) => {
		setIsHovered((previousState) => !previousState);
		handleSelection(event);
	};


	const handleExpansionChange = (event) => {
		setIsHovered((previousState) => !previousState);
		handleExpansion(event);
	};

	return (
		<div
			className={clsx(className, classes.root, {
				[classes.expanded]: expanded,
				[classes.selected]: selected,
				[classes.disabled]: disabled,
			})}
			onMouseDown={preventSelection}
			onMouseEnter={handleHoverChange}
			onMouseLeave={handleHoverChange}
			ref={ref}
		>
			<div onClick={handleExpansionChange} className={classes.iconContainer}>
				{displayIcon || expansionIcon}
			</div>

			{selected ?
				<TagComponentTooltip title={messages.TAG_DESELECTION_TOOLTIP_ON_HOVER} placement='top-end' arrow>
					<Label onClick={handleSelectionChange} sx={{ color: "#7482A0" }} />
				</TagComponentTooltip>
				:
				<LabelOutlined onClick={handleSelectionChange} sx={{ color: "#7482A0" }} />
			}

			<TagLibraryTreeItemLabel onClick={handleSelectionChange} className={classes.label} >{label}</TagLibraryTreeItemLabel>
			{isHovered ?
				<>
					<Edit fontSize='small' onClick={handleEditButtonClick} />
					<Delete fontSize='small' onClick={handleDeleteButtonClick} />
				</>
				:
				<TagLibraryTreeItemCounter color="inherit">{props.count}</TagLibraryTreeItemCounter>
			}
		</div>
	);
}