import { useState } from "react";
import { useDispatch } from "react-redux";
import { FormControl, MenuItem } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import useAuthHeader from "../../helpers/useAuthHeader";
import useRuleFilter from "../../helpers/useRuleFilter";
import { setRules, setRulesDidLoadFlag, setRulesLoadError,setTotalPages } from "../../redux/ruleSlice";

import { RuleLibraryInputLabel, RuleLibrarySelect } from "./styled/rule-dropdown.styled";

export default function RuleDropdown(props) {
	const dispatch = useDispatch();
	let authHeader = useAuthHeader();
	const updateAndGetRuleFilters = useRuleFilter();
	const [dropdownValue, setDropdownValue] = useState("");

	const handleDropdownSelectionChange = (e) => {
		setDropdownValue(e.target.value);
		let queryObj = updateAndGetRuleFilters("status", e.target.value);
		axios.get(apiRoutes.getRules, {
			headers: authHeader,
			params: queryObj, paramsSerializer: { indexes: null }
		}).then(res => {
			if (res.data.length === 0) {
				dispatch(setRulesLoadError("No rules found."));
			} else {
				dispatch(setRules(res.data.rules));
				dispatch(setTotalPages(res.data.pager.pages));
				dispatch(setRulesDidLoadFlag(true));
				dispatch(setRulesLoadError(""));
			}
		}).catch(err => {
			dispatch(setRulesLoadError(err.message));
		});
	};

	return (
		<FormControl>
			<RuleLibraryInputLabel id={props.DropdownType}>{`Select ${props.DropdownType}`}</RuleLibraryInputLabel>
			<RuleLibrarySelect
				sx={{ width: "250px", height: "50px" }}
				labelId={props.DropdownType}
				label={`Select ${props.DropdownType}`}
				value={dropdownValue}
				onChange={handleDropdownSelectionChange}
			>
				<MenuItem value=""><em>SHOW ALL</em></MenuItem>
				{props.Options.map((item, ind) => <MenuItem key={ind} value={item.name}>{item.name}</MenuItem>)}
			</RuleLibrarySelect>
		</FormControl>
	);
}