import { Edit } from "@mui/icons-material";
import { alpha, Button, Collapse, styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import { animated, useSpring } from "@react-spring/web";

function TagTransition(props) {
	const style = useSpring({
		from: {
			opacity: 0,
			transform: "translate3d(20px,0,0)",
		},
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
}

export const StyledTreeItemRoot = styled((props) => (
	<TreeItem {...props} TransitionComponent={TagTransition} />
))(({ theme }) => ({
	color: theme.palette.text.secondary,
	[`& .${treeItemClasses.content}`]: {
		width: "90%",
		color: theme.palette.text.secondary,
		borderRadius: "5px",
		fontWeight: theme.typography.fontWeightMedium,
		"&.Mui-expanded": {
			fontWeight: theme.typography.fontWeightRegular
		},
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
		"&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused, &.Mui-selected:hover": {
			backgroundColor: theme.palette.action.selected,
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: "inherit",
			color: "inherit"
		}
	},
	[`& .${treeItemClasses.group}`]: {
		borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.2)}`
	},
	[`& .${treeItemClasses.iconContainer}`]: {
		marginLeft: "1px"
	},
	marginTop: "5px",
	marginBottom: "5px",
}));

export const TagLibraryComponentContainer = styled("div")(({ theme }) => ({
	[theme.breakpoints.down(1700)]: {
		width: "275px"
	},
	width: "400px",
	backgroundColor: "#FFFFFF",
	paddingTop: "10px",
	paddingBottom: "20px",
	borderTop: "solid 1px #DCDCDC",
	fontSize: "1rem",
	lineHeight: "1.43",
	borderRadius: 7,
	boxShadow: "0 1px 3px grey"
}));

export const TagLibraryComponentTitleContainer = styled("div")(({ theme }) => ({
	backgroundColor: "#FFFFFF",
	borderBottom: "solid 1px #DCDCDC",
	fontSize: "1rem",
	paddingBottom: "5px",
	paddingLeft: "10px",
	lineHeight: "1.43",
}));

export const TagLibraryComponentTitleButton = styled(Button)(({ theme }) => ({
	backgroundColor: "#FFFFFF",
	color: "#2FBC70",
	padding: "5px",
	margin: "0px 10px 10px 0px",
	float: "right",
	paddingBottom: "1px",
	paddingTop: "1px",
	":hover": {
		backgroundColor: "#E3E6EC",
	}
}));

export const TagLibraryTreeItem = styled(TreeItem)(({ theme }) => ({
	fontSize: "1rem",
	lineHeight: "1.43",
	color: theme.palette.text.secondary
}));

export const TagLibraryTreeItemLabel = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	lineHeight: "1.43",
	textOverflow: "ellipsis",
	overflow: "hidden",
	whiteSpace: "nowrap"
}));

export const TagLibraryTreeItemCounter = styled(Typography)(({ theme }) => ({
	fontSize: ".6rem",
	lineHeight: ".90",
	color: theme.palette.text.secondary,
	borderRadius: "50%",
	width: "5px",
	height: "5px",
	textAlign: "right"
}));

export const TagIcon = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#E3E6EC" : "#E3E6EC",
	...theme.typography.body2,
	padding: theme.spacing(0.7),
	textAlign: "left",
	fontSize: "0.70rem",
	fontWeight: "700",
	color: "#7482A0",
	maxWidth: "100px",
	float: "left",
	margin: "2px",
	borderRadius: "5px",
	alignItems: "center",
	display: "flex"
}));

export const TagComponentTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: "200px",
	},
});

export const TagDeleteIcon = styled(Edit)(({ theme }) => ({
	fontSize: "small",
	"&:hover": {
		color: "rgb(47, 189, 112)"
	},
}));

export const TagEditIcon = styled(Edit)(({ theme }) => ({
	fontSize: "small",
	"&:hover": {
		color: "rgb(47, 189, 112)"
	},
}));