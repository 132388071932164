import {
	GridToolbarContainer,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";

import { CoAppDataGridToolbarButton } from "../styled/global.styled";

export default function DataGridCustomToolbar(props) {
	const removeAction = props.removeAction;
	const removeActionDisplay = props.removeActionDisplay;
	const removeActionLabel = props.removeActionLabel;
	if (removeActionDisplay) {
		return (
			<GridToolbarContainer>
				<GridToolbarFilterButton />
				<CoAppDataGridToolbarButton onClick={removeAction}>{removeActionLabel}</CoAppDataGridToolbarButton>
			</GridToolbarContainer>
		);
	} else {
		return (
			<GridToolbarContainer>
				<GridToolbarFilterButton />
			</GridToolbarContainer>
		);
	}
}
