import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	Box,
	CircularProgress,
	Container,
	Stack,
	Typography,
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectOrganizationSsoAndScim } from "../../../../redux/userSlice";
import AddItemsToCollectionDialog from "../../../global/components/add-items-to-collection-dialog";
import DataGridCustomNoRowsOverlay from "../../../global/components/datagrid-custom-norows-overlay";
import DataGridCustomToolbar from "../../../global/components/datagrid-custom-toolbar";
import SearchInput from "../../../global/components/search-input";
import ThreeDotMenu from "../../../global/components/three-dot-menu";
import {
	CoAppButtonGreenBg,
	CoAppDataGrid,
	CoAppH2,
	CoAppMainContainer,
} from "../../../global/styled/global.styled";
import { SubMenuContainer } from "../../styled/settings.styled";
import { UserNameCell } from "../../users/styled/user-management.styled";

export default function RoleUsers(props) {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const role = props.role;
	const organizationSsoStatus = useSelector(selectOrganizationSsoAndScim);

	const [addUsersSearchInput, setAddUsersSearchInput] = useState("");
	const [addUsersToggled, setAddUsersToggled] = useState(false);
	const [availableUsers, setAvailableUsers] = useState([]);
	const [roleUsers, setRoleUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [userSearchInput, setUserSearchInput] = useState("");
	const [userSeed, setUserSeed] = useState(1);
	const [usersToAdd, setUsersToAdd] = useState([]);

	const columns = [
		{
			editable: false,
			field: "firstname",
			flex: 1,
			headerClassName: "data-grid-header",
			headerName: "USER",
			pinnable: false,
			renderCell: (params) => (
				<UserNameCell>
					<Typography sx={{ fontWeight: "600" }}>
						{params.row.firstname} {params.row.lastname}
					</Typography>
					<Typography sx={{ color: "grey", fontSize: ".75rem" }}>
						{params.row.email}
					</Typography>
				</UserNameCell>
			),
		},
		{
			editable: false,
			field: "roles",
			flex: 1,
			headerClassName: "data-grid-header",
			headerName: "ROLES",
			pinnable: false,
			renderCell: (params) => (
				<div>
					{params.value.map((role, index) => {
						return <Typography sx={{ fontWeight: "600" }}
							key={index}>{role.name}{index < params.value.length - 1 ? ", " : ""}</Typography>;
					})}
				</div>
			),
		},
		{
			disableColumnMenu: true,
			editable: false,
			field: "groups",
			filterable: false,
			flex: 1,
			headerClassName: "data-grid-header",
			headerName: "GROUPS",
			pinnable: false,
			sortable: false,
			renderCell: (params) => (
				<>
					{params.value && params.value.length > 0 ? (
						<UserNameCell>
							<Typography>{params.value.length} {params.value.length === 1 ? "group" : "groups"}</Typography>
							<Typography
								sx={{ color: "grey", fontSize: ".75rem", minWidth: "40px" }}
							>
								{params.value.map((group, index) => {
									return (
										<span key={index}>
											{group.name}
											{index < params.value.length - 1 ? ", " : ""}
										</span>
									);
								})}
							</Typography>
						</UserNameCell>
					) : (
						null
					)}
				</>
			),
		},
		{
			editable: false,
			field: "createdAt",
			flex: 1,
			headerClassName: "data-grid-header",
			headerName: "CREATED",
			pinnable: false,
			renderCell: (params) => (
				<div>
					<Typography sx={{ fontSize: ".8rem", color: "grey" }}>
						{params.value &&
							new Date(params.value).toLocaleString(undefined, {
								timeZone: "America/New_York",
								year: "numeric",
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
								second: "numeric",
								hour12: true,
							})}
					</Typography>
				</div>
			),
		},
		{
			editable: false,
			field: "lastExtensionLogin",
			flex: 1,
			headerClassName: "data-grid-header",
			headerName: "LAST EXT. LOGIN",
			pinnable: false,
			renderCell: (params) => (
				<div>
					<Typography sx={{ fontSize: ".8rem", color: "grey" }}>
						{params.value &&
							new Date(params.value).toLocaleString(undefined, {
								timeZone: "America/New_York",
								year: "numeric",
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
								second: "numeric",
								hour12: true,
							})}
					</Typography>
				</div>
			),
		},
		{
			editable: false,
			field: "status",
			flex: 0.5,
			headerClassName: "data-grid-header",
			headerName: "STATUS",
			pinnable: false,
			renderCell: (params) => (
				<div>
					{params.row.active ? (
						<Typography
							sx={{ fontSize: ".8rem", fontWeight: "600", color: "#2fbd70" }}
						>
							Active
						</Typography>
					) : (
						<Typography
							sx={{ fontSize: ".8rem", fontWeight: "600", color: "grey" }}
						>
							Inactive
						</Typography>
					)}
				</div>
			),
		},
		{
			field: "actions",
			flex: .1,
			resizable: false,
			type: "actions",
			renderCell: (params) => (
				<div>
					{
						organizationSsoStatus ?
							<ThreeDotMenu
								options={[
									{
										name: "View User",
										optionClickHandler: () => {
											navigate(pages.profile + "/" + params.row.id);
										},
									},
								]}
							/>
							:
							<ThreeDotMenu
								options={[
									{
										name: "Remove From Role",
										optionClickHandler: () => {
											removeUserFromRole(params.row.id);
										},
									},
									{
										name: "View User",
										optionClickHandler: () => {
											navigate(pages.profile + "/" + params.row.id);
										},
									},
								]}
							/>
					}
				</div>
			),
		}
	];

	const addUsersToRole = () => {
		const userIds = usersToAdd.map(u => u.id);
		axios.post(apiRoutes.addUsersToRole(role.id), { userIds: userIds }, {
			headers: authHeader
		}).then(() => {
			setUserSeed(userSeed + 1);
			toggleAddNewDialog();
			setUsersToAdd([]);
			dispatch(setMessage(messages.USERS_ADDED_SUCCESS_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.SUCCESS));
		}).catch(err => {
			console.error(err);
			dispatch(setMessage(messages.USERS_ADDED_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
		});
	};

	const addUserToUserSelection = (user) => {
		let selectedUserRows = [...selectedUsers];
		let userIndex = selectedUserRows.findIndex((row) => row.id === user.id);
		if (userIndex === -1) {
			selectedUserRows.push(user);
		} else {
			selectedUserRows.splice(userIndex, 1);
		}
		setSelectedUsers(selectedUserRows);
	};

	const addUserToUsersToAdd = (user) => {
		let updatedUsersToAdd = [...usersToAdd];
		let userIndex = usersToAdd.findIndex((row) => row.id === user.id);
		if (userIndex === -1) {
			updatedUsersToAdd.push(user);
		} else {
			updatedUsersToAdd.splice(userIndex, 1);
		}
		setUsersToAdd(updatedUsersToAdd);
	};

	const init = () => {
		let userQueryObject = {
			email: userSearchInput ? userSearchInput : null,
			roleId: role.id,
		};
		axios
			.get(apiRoutes.getUsers, {
				headers: authHeader,
				params: userQueryObject,
			})
			.then((res) => {
				setRoleUsers(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const initUsersForAdd = () => {
		axios
			.get(apiRoutes.getUsers, {
				headers: authHeader,
			})
			.then((res) => {
				let users = res.data.filter((user) => {
					return !roleUsers.some((roleUser) => roleUser.id === user.id);
				});
				users = users.map((user) => {
					return {
						id: user.id,
						name: `${user.firstname} ${user.lastname}`
					};
				});
				setAvailableUsers(users);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const removeUserFromRole = (userId) => {
		axios
			.post(apiRoutes.removeUsersFromRole(role.id), { userIds: [userId] }, {
				headers: authHeader,
			})
			.then((response) => {
				if (response.status === 200) {
					init();
					dispatch(setMessage(messages.USER_REMOVED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
				} else {
					dispatch(setMessage(messages.USER_REMOVED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.USER_REMOVED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR
				));
			});
	};

	const removeUsersFromRole = () => {
		const userIds = selectedUsers.map(u => u.id);
		axios
			.post(apiRoutes.removeUsersFromRole(role.id), { userIds: userIds }, {
				headers: authHeader,
			})
			.then((response) => {
				if (response.status === 200) {
					init();
					dispatch(setMessage(messages.USER_REMOVED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
				} else {
					dispatch(setMessage(messages.USER_REMOVED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.USER_REMOVED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR
				));
			});
	};

	const setBulkActions = (rowIds) => {
		if (rowIds.length > 0) {
			rowIds.forEach((rowId) => {
				let user = roleUsers.find((user) => user.id === rowId);
				addUserToUserSelection(user);
			});
		} else {
			setSelectedUsers([]);
		}
	};

	const toggleAddNewDialog = () => {
		if (!addUsersToggled) {
			initUsersForAdd();
			setAddUsersToggled(true);
		} else {
			setAddUsersToggled(false);
			setAvailableUsers([]);
			setUsersToAdd([]);
		}
	};

	useEffect(() => {
		init();
	}, [userSeed, userSearchInput]);

	if (role) {
		return (
			<>
				<CoAppMainContainer sx={{ p: 2 }}>
					<SubMenuContainer>
						<CoAppH2 sx={{ marginRight: "50px" }}>Users</CoAppH2>

						<SearchInput
							placeholder="Search users"
							onChangeHandler={setUserSearchInput}
						/>
						<CoAppButtonGreenBg
							disabled={organizationSsoStatus}
							onClick={toggleAddNewDialog}
							sx={{ float: "right", minWidth: "120px" }}
						>
							ADD USER
						</CoAppButtonGreenBg>
					</SubMenuContainer>

					{
						roleUsers.length > 0 && (
							<Box sx={{ width: "100%" }}>
								<CoAppDataGrid
									checkboxSelection
									columns={columns}
									columnHeaderHeight={45}
									getRowClassName={(params) =>
										params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
									}
									disableAggregation
									disableColumnSelector
									disableDensitySelector
									disableRowGrouping
									disableRowSelectionOnClick
									experimentalFeatures={{ columnGrouping: false }}
									getRowId={(row) => row.id}
									initialState={{
										pagination: {
											paginationModel: { pageSize: 25, page: 0 },
										},
										pinnedColumns: { right: ["actions"] },
									}}
									onRowSelectionModelChange={(params) => {
										setBulkActions(params);
									}}
									pagination
									rows={roleUsers}
									slots={{ toolbar: DataGridCustomToolbar }}
									slotProps={{ toolbar: { removeAction: removeUsersFromRole, removeActionDisplay: selectedUsers.length > 0 ? true : false, removeActionLabel: `Remove ${selectedUsers.length} User(s)` } }}
								/>
							</Box>
						)
					}
					{
						roleUsers.length === 0 && (
							<DataGridCustomNoRowsOverlay message={"No Users Yet!"} />
						)
					}
				</CoAppMainContainer>

				<AddItemsToCollectionDialog
					addButtonTitle="USER(S)"
					addItemsIsToggled={addUsersToggled}
					addItemsToCollection={addUsersToRole}
					addItemsToNewArray={addUserToUsersToAdd}
					dialogSearchPlaceholder="Search users"
					dialogTitle={`Add user(s) to ${role.name} role`}
					items={availableUsers}
					itemsToAdd={usersToAdd}
					searchInput={addUsersSearchInput}
					setSearchInput={setAddUsersSearchInput}
					toggleDialog={toggleAddNewDialog}
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}
