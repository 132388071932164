import { Cancel, Check } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";

import { CoAppDialogIconButton } from "../../global/styled/global.styled";

export default function RoleCreationDialog(props) {
	return (
		<Dialog open={props.roleCreationDialogOpen} onClose={props.creationDialogCloseHandler}>
			<DialogTitle>Add New Role</DialogTitle>
			<DialogContent>
				<FormControl sx={{ display: "flex"}} required>
					<TextField id="role-name" onChange={props.roleCreationNameHandler} placeholder="Role Name" sx={{display: "flex", width: "100%"}}/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<CoAppDialogIconButton onClick={props.creationDialogCloseHandler}><Cancel /></CoAppDialogIconButton>
				<CoAppDialogIconButton onClick={props.createRoleHandler}><Check /></CoAppDialogIconButton>
			</DialogActions>
		</Dialog>
	);
}