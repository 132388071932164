const apiRoutes = {
	// dev env
	getPackageVersion: "/api/test/packageVersion",

	// groups
	addRulesToGroup: (groupId) => `/api/v1/groups/group/${groupId}/rules`,
	addUsersToGroup: "/api/v1/users/addUsersToGroup",
	addPluginsToGroup: (groupId) => `/api/v1/groups/group/${groupId}/plugins`,
	copyGroup: "/api/v1/groups/group/copy",
	createGroup: "/api/v1/groups/",
	deleteGroup: "/api/v1/groups/group",
	getGroupIdpGroups: (groupId) => `/api/v1/groups/group/${groupId}/idpGroups`,
	getGroupById: (groupId) => `/api/v1/groups/group/${groupId}`,
	getGroupByName: "/api/v1/groups/group",
	getGroups: "/api/v1/groups/",
	getDummyGroups: "/api/v1/test/testGetDummyGroups",
	getGroupPlugins: (groupId) => `/api/v1/groups/group/${groupId}/plugins`,
	removeIdpGroupFromGroup: (groupId, idpGroupId) => `/api/v1/groups/group/${groupId}/idpgroups/${idpGroupId}`,
	removePluginFromGroup: (groupId, pluginId) => `/api/v1/groups/group/${groupId}/plugin/${pluginId}`,
	removeRuleFromGroup: (groupId, ruleId) => `/api/v1/groups/group/${groupId}/rule/${ruleId}`,
	updateGroup: (groupId) => `/api/v1/groups/group/${groupId}`,
	updateGroupIdpGroups: (groupId) => `/api/v1/groups/group/${groupId}/idpgroups`,
	updateGroupPlugin: (groupId, pluginId) => `/api/v1/groups/group/${groupId}/plugin/${pluginId}`,

	//organizations
	getOrganization: (orgId) => `/api/v1/organizations/organization/${orgId}`,
	login: "/api/v1/login",
	refresh: "/api/v1/refresh",
	forceLogin: (orgId) => `/api/v1/organizations/organization/${orgId}/forceLogin`,
	killSwitch: (orgId) => `/api/v1/organizations/organization/${orgId}/killSwitch`,
	getExternalGroups: (orgId) => `/api/v1/organizations/organization/${orgId}/externalGroups`,
	ssoAndScim: (orgId) => `/api/v1/organizations/organization/${orgId}/ssoAndScim`,
	sync: (orgId) => `/api/v1/organizations/organization/${orgId}/sync`,
	syncByTenantId: "/api/v1/organizations/syncByTenantId",

	// permissions
	getPermissions: "/api/v1/permissions",

	// plugins
	addGroupsToPlugin: (pluginId) => `/api/v1/plugins/plugin/${pluginId}/groups`,
	getPlugins: (orgId) => `/api/v1/organizations/organization/${orgId}/platforms`,
	getGroupsForPlugin: (pluginId) => `/api/v1/plugins/plugin/${pluginId}/groups`,
	togglePluginStatusForOrganization: (orgId, platformId, pluginId) => `/api/v1/organizations/organization/:${orgId}/platforms/${platformId}/${pluginId}/toggle`,

	// requests
	getRequest: "/api/v1/requests/request",
	requestUpdate: "/api/v1/requests/request",

	// roles
	addUsersToRole: (roleId) => `/api/v1/roles/role/${roleId}/users/add`,
	copyRole: (roleId) => `/api/v1/roles/role/${roleId}/copy`,
	createRole: "/api/v1/roles",
	deleteRole: "/api/v1/roles/role",
	getRoles: "/api/v1/roles",
	getRoleById: (roleId) => `/api/v1/roles/role/${roleId}`,
	getRoleIdpGroups: (roleId) => `/api/v1/roles/role/${roleId}/idpGroups`,
	getRolePermissions: (roleId) => `/api/v1/roles/${roleId}/permissions`,
	removeIdpGroupFromRole: (roleId, idpGroupId) => `/api/v1/roles/role/${roleId}/idpgroups/${idpGroupId}`,
	removeUsersFromRole: (roleId) => `/api/v1/roles/role/${roleId}/users/remove`,
	updateRole: (roleId) => `/api/v1/roles/role/${roleId}`,
	updateRoleIdpGroups: (roleId) => `/api/v1/roles/role/${roleId}/idpGroups`,
	updateRolePermission: (roleId, permissionId) => `/api/v1/roles/role/${roleId}/permissions/${permissionId}`,

	// swagger
	getSwagger: "/api/v1/admin/swagger",

	// tags
	createTag: "/api/v1/tags",
	getTags: "/api/v1/tags",
	getPossibleParentTags: "/api/v1/tags/getPossibleParentTags",
	deleteTag: "/api/v1/tags/tag",
	updateTag: "/api/v1/tags/tag",

	// users
	updateUser: "/api/v1/users/user",
	createUser: "/api/v1/users",
	deleteUser: "/api/v1/users/user",
	getUser: (isSelf, id, email, tenantId) => `/api/v1/users/user?isSelf=${isSelf}&id=${id}&email=${email}&tenantId=${tenantId}`,
	getUsers: "/api/v1/users",
	setUserActive: "/api/v1/users/user/setUserActive",
	setUserRole: "/api/v1/users/user/setUserRole",
	removeUserFromGroup: (userId, groupId) => `/api/v1/users/user/${userId}/group/${groupId} `,

	// rules
	createRule: "/api/v1/rules",
	copyRule: "/api/v1/rules/rule/copy",
	deleteRule: "/api/v1/rules/rule",
	getRule: "/api/v1/rules/rule",
	getRules: "/api/v1/rules",
	getRuleByName: "/api/v1/rules/rule",
	updateRule: "/api/v1/rules/rule",
	toggleRuleStatus: "/api/v1/rules/rule/toggleStatus",
	restoreRule: "/api/v1/rules/rule/restore",

	// rule fields
	ruleFields: "/api/v1/rulefields",
	ruleField: "/api/v1/ruleFields/ruleField",

	// rule locations
	getRuleLocations: "/api/v1/rulelocations/admin",
	getRuleLocationsForWizard: "/api/v1/rulelocations/",
	getRuleLocation: "/api/v1/rulelocations/rulelocation",
	createRuleLocation: "/api/v1/rulelocations",
	updateRuleLocation: "/api/v1/rulelocations/ruleLocation",

	// workstations
	getWorkstations: "/api/v1/workstations",
	workstationKillSwitch: "/api/v1/workstations/killSwitch",
};

export default apiRoutes;