import { Link, styled } from "@mui/material";

export const HeaderMenuItem = styled(Link)(() => ({
	color: "#7482A0",
	fontWeight: "600",
	fontSize: "1rem",
	fontFamily: "\"Roboto\",\"Helvetica\",\"Arial\",sans-serif",
	padding: "15px",
	marginLeft: "25px",
	"&:hover": {
		color: "#2FBD70",
	},

	"&[disabled]": {
		pointerEvents: "none",
		color: "#d0d3d9"
	},
}));


export const DevContainer = styled("div")(() => ({
	color: "#7482A0",
	textAlign: "right"
}));