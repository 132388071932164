import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Box, Divider, FormControlLabel, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectOrganizationId } from "../../../../redux/userSlice";
import AddItemsToCollectionDialog from "../../../global/components/add-items-to-collection-dialog";
import ConfirmationDialog from "../../../global/components/confirmation-dialog";
import DataGridCustomNoRowsOverlay from "../../../global/components/datagrid-custom-norows-overlay";
import SearchInput from "../../../global/components/search-input";
import ThreeDotMenu from "../../../global/components/three-dot-menu";
import { CoAppButtonGreenBg, CoAppDataGrid, CoAppH1, CoAppH2, CoAppMainContainer, CoAppStatusToggle } from "../../../global/styled/global.styled";
import SettingsMenuBack from "../../settings-menu-back";
import { SubMenuContainer } from "../../styled/settings.styled";

export default function PluginGroups() {
	const authHeader = useAuthHeader();
	const { id } = useParams();
	const location = useLocation();
	const organizationId = useSelector(selectOrganizationId);
	const dispatch = useDispatch();
	const currentPlugin = location.state.plugin;

	const [pluginGroups, setPluginGroups] = useState([]);
	const [pluginActive, setPluginActive] = useState(location.state.plugin.active);
	const [dialogDetails, setDialogDetails] = useState({ dialogOpen: false });
	const [groupSearchInput, setGroupSearchInput] = useState("");
	const [addGroupSearchInput, setAddGroupSearchInput] = useState("");
	const [addGroupsOpen, setAddGroupsOpen] = useState(false);
	const [groupsToAdd, setGroupsToAdd] = useState([]);
	const [availableGroups, setAvailableGroups] = useState([]);

	const columns = [
		{
			field: "name",
			headerName: "NAME",
			editable: false,
			headerClassName: "data-grid-header",
			width: 200,
			renderCell: (params) => (
				<div>
					{params.row.name}
				</div>
			)
		},
		{
			field: "users",
			headerName: "USERS",
			editable: true,
			headerClassName: "data-grid-header",
			width: 200,
			renderCell: (params) => (
				<div>
					{params.row.users.length} {params.row.users.length === 1 ? " User" : " Users"}
				</div>
			)
		},
		{
			field: "actions",
			type: "actions",
			width: 50,
			headerClassName: "data-grid-header",
			resizable: false,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Remove",
								optionClickHandler: () => {
									handleOpenRemoveGroupConfirmDialog(params.row);
								}
							},
						]}
					/>
				</div>
			),
		},
	];

	const initPluginGroups = () => {
		axios.get(apiRoutes.getGroupsForPlugin(id),
			{
				headers: authHeader,
				params: { name: groupSearchInput },
				paramsSerializer: { indexes: null }
			}
		)
			.then((response) => {
				setPluginGroups(response.data);
			})
			.catch((error) => {
				console.error("Error fetching plugin groups: ", error);
			});
	};

	const handlePluginStatusToggleChange = () => {
		axios.put(apiRoutes.togglePluginStatusForOrganization(organizationId, currentPlugin.platformId, id), {}, { headers: authHeader })
			.then(() => {
				setPluginActive(!pluginActive);
				setDialogDetails({ dialogOpen: false });
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(pluginActive ? messages.PLUGIN_DISABLED_SUCCESS_MSG : messages.PLUGIN_ENABLED_SUCCESS_MSG));
			})
			.catch((error) => {
				console.error(error);
				setDialogDetails({ dialogOpen: false });
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(pluginActive ? messages.PLUGIN_DISABLED_ERROR_MSG : messages.PLUGIN_ENABLED_ERROR_MSG));
			});
	};

	const handleOpenToggleConfirmDialog = () => {
		setDialogDetails({
			dialogOpen: true,
			confirmationTitle: messages.PLUGIN_TOGGLE_CONFIRMATION_MSG(pluginActive, currentPlugin.name),
			confirmClickHandler: () => handlePluginStatusToggleChange(),
			cancelClickHandler: () => setDialogDetails({})
		});
	};

	const handleRemoveGroupFromPlugin = (group) => {
		axios.delete(apiRoutes.removePluginFromGroup(group.id, id), { headers: authHeader })
			.then(() => {
				setDialogDetails({ dialogOpen: false });
				initPluginGroups();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(messages.GROUP_REMOVAL_SUCCESS_MSG));
			})
			.catch((err) => {
				console.error(err);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.GROUP_REMOVAL_ERROR_MSG));
			});
	};

	const handleOpenRemoveGroupConfirmDialog = (group) => {
		setDialogDetails({
			dialogOpen: true,
			confirmationTitle: `Are you sure you want to remove ${group.name} from ${currentPlugin.name}?`,
			confirmClickHandler: () => handleRemoveGroupFromPlugin(group),
			cancelClickHandler: () => setDialogDetails({})
		});
	};

	const handleAddGroupsToPlugin = () => {
		let postBody = {
			groups: groupsToAdd.map(group => group.id),
			pluginActiveStatus: pluginActive
		};
		axios.post(apiRoutes.addGroupsToPlugin(id), postBody, { headers: authHeader })
			.then(() => {
				setAddGroupsOpen(false);
				setAvailableGroups([]);
				setGroupsToAdd([]);
				initPluginGroups();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(messages.GROUP_ADD_TO_PLUGIN_SUCCESS_MSG(groupsToAdd.length, currentPlugin.name)));
			})
			.catch((err) => {
				console.error(err);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.GROUP_ADD_TO_PLUGIN_ERROR_MSG(groupsToAdd.length, currentPlugin.name)));
			});
	};

	const handleAddGroupsToGroupsToAdd = (group) => {
		let tempGroupsToAdd = [...groupsToAdd];
		let groupIndex = groupsToAdd.findIndex((row) => row.id === group.id);
		if (groupIndex === -1) {
			tempGroupsToAdd.push(group);
		} else {
			tempGroupsToAdd.splice(groupIndex, 1);
		}
		setGroupsToAdd(tempGroupsToAdd);
	};

	const handleOpenAddGroupsDialog = () => {
		axios.get(apiRoutes.getGroups, { headers: authHeader })
			.then((response) => {
				let currentGroups = pluginGroups.map(group => group.id);
				let availableGroups = response.data.filter(group => !currentGroups.includes(group.id));
				setAvailableGroups(availableGroups);
				setAddGroupsOpen(true);
			})
			.catch((error) => {
				console.error("Error fetching groups: ", error);
			});
	};

	const handleAddGroupsDialogToggle = () => {
		setAddGroupsOpen(!addGroupsOpen);
		setAvailableGroups([]);
		setGroupsToAdd([]);
	};

	useEffect(() => {
		initPluginGroups();
	}, [groupSearchInput]);

	return (
		<CoAppMainContainer sx={{ p: 2 }}>
			<CoAppMainContainer>
				<SettingsMenuBack buttonText="All Plugins" navigateTo={pages.settings + "?from=plugins"} />
			</CoAppMainContainer>
			
			<SubMenuContainer>
				<CoAppH1 sx={{ fontWeight: 400 }}>{currentPlugin.name}</CoAppH1>
				<FormControlLabel
					control={
						<CoAppStatusToggle
							checked={pluginActive}
							onClick={() => handleOpenToggleConfirmDialog(currentPlugin.name)}
						/>
					}
					sx={{ pointerEvents: "none" }}
					label={<Typography variant="body3" sx={{ fontSize: "12px", pt: "2px", pl: "2px" }}>{pluginActive ? "Active" : "Inactive"}</Typography>}
					labelPlacement="start"
				/>

			</SubMenuContainer>
			
			<Divider sx={{ width: "100%", marginBottom: "10px" }} />
			
			<SubMenuContainer>
				<CoAppH2>Groups</CoAppH2>
				<SearchInput
					placeholder="Search groups..."
					onChangeHandler={setGroupSearchInput}
				/>

				<CoAppButtonGreenBg sx={{ float: "right", minWidth: "120px" }}
					onClick={handleOpenAddGroupsDialog}>ADD GROUP</CoAppButtonGreenBg>
				
			</SubMenuContainer>
			{
				pluginGroups.length === 0 ? (
					<DataGridCustomNoRowsOverlay message={groupSearchInput === "" ? "No Groups Associated to this Plugin Yet!" : "No Groups Found!"} />
				)
					:
					<Box sx={{ width: "100%" }}>
						<CoAppDataGrid
							columns={columns}
							rows={pluginGroups}
							disableRowSelectionOnClick
							getRowId={(row) => row.id}
							initialState={{
								pagination: {
									paginationModel: { pageSize: 25, page: 0 },
								},
								pinnedColumns: { right: ["actions"] },
								sorting: {
									sortModel: [{ field: "name", sort: "asc" }],
								},
							}}
							pagination
							columnHeaderHeight={45}
							getRowClassName={(params) =>
								params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
							}
						/>
					</Box>
			}
			<ConfirmationDialog dialogDetails={dialogDetails} />
			<AddItemsToCollectionDialog
				addButtonTitle={groupsToAdd.length > 1 ? "Groups" : "Group"}
				addItemsIsToggled={addGroupsOpen}
				addItemsToCollection={handleAddGroupsToPlugin}
				addItemsToNewArray={handleAddGroupsToGroupsToAdd}
				dialogSearchPlaceholder="Search groups..."
				dialogTitle={`Add Groups to ${currentPlugin.name}`}
				items={availableGroups}
				itemsToAdd={groupsToAdd}
				searchInput={addGroupSearchInput}
				setSearchInput={setAddGroupSearchInput}
				toggleDialog={handleAddGroupsDialogToggle}
			/>
		</CoAppMainContainer>
	);
}