import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { selectEditedVersionOfRule, selectRuleLocation, setReactions, setRuleLocation } from "../../../redux/ruleWizardSlice";
import { CoAppButtonGreenBg } from "../../global/styled/global.styled";
import WizardLocationPagination from "../locations/wizard-location-pagination";
import RuleReactionSidebar from "../reactions/rule-reaction-sidebar";
import RuleLocationGrid from "../rules/rule-location-grid";
import {
	RuleImage,
	RuleWizardStepTitle,
	WizardLocationsContainer,
	WizardLocationsSearchField
} from "../styled/rule-wizard.styled";

/**
 * Step 2 in Rule Creation Wizard
 * User selects a location where reactions will be configured
*/
export default function WizardStep2(props) {
	const dispatch = useDispatch();
	let authHeader = useAuthHeader();

	const ruleLocation = useSelector(selectRuleLocation);
	const editedVersionOfRule = useSelector(selectEditedVersionOfRule);

	const [searchInput, setSearchInput] = useState("");
	const [ruleLocations, setRuleLocations] = useState([]);
	const [ruleLocationsLoaded, setRuleLocationLoaded] = useState(false);
	const [ruleLocationsPreviouslyLoaded, setRuleLocationsPreviouslyLoaded] = useState(false);

	const [location, setLocation] = useState({});
	const [locationLoaded, setLocationLoaded] = useState(false);

	const [reactionFields, setReactionFields] = useState([]);
	const [reactionFieldsLoaded, setReactionFieldsLoaded] = useState(false);

	const [reactionTypes, setReactionTypes] = useState([]);

	/**
	 * Location pagination state. 
	 */
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	const handleSearchInputChange = (e) => {
		e.preventDefault();
		let searchValue = e.target.value;
		setSearchInput(searchValue);
		setRuleLocationLoaded(false);

		let queryObj = { page: currentPage };
		if (searchValue) {
			queryObj["name"] = searchValue;
		}

		axios.get(apiRoutes.getRuleLocationsForWizard, {
			headers: authHeader,
			params: queryObj, paramsSerializer: { indexes: null }
		}).then(res => {
			if (res.data.pageOfItems.length === 0) {
				setRuleLocations([]);
				setRuleLocationLoaded(false);
			} else {
				setRuleLocations(res.data.pageOfItems);
				setCurrentPage(parseInt(res.data.pager.currentPage));
				setTotalPages(res.data.pager.totalPages);
				setRuleLocationLoaded(true);
			}
		}).catch(err => {
			console.error(err);
		});
	};

	const initRuleLocations = () => {
		if (!ruleLocationsPreviouslyLoaded) {
			setRuleLocationLoaded(false);
			axios.get(apiRoutes.getRuleLocationsForWizard, {
				headers: authHeader,
				params: { page: 1 }
			})
				.then(res => {
					setCurrentPage(parseInt(res.data.pager.currentPage));
					setTotalPages(res.data.pager.totalPages);
					setRuleLocations(res.data.pageOfItems);
					setRuleLocationLoaded(true);
				})
				.catch(err => console.log(err));

			setRuleLocationsPreviouslyLoaded(true);
		}
	};

	const initSelectedLocation = () => {
		setLocationLoaded(false);
		axios.get(apiRoutes.getRuleLocation + "/" + ruleLocation.id, {
			headers: authHeader
		})
			.then(res => {
				setLocation(res.data);
				setLocationLoaded(true);
				setReactionTypes(res.data.reactiontypes);
			})
			.catch(err => console.log(err));
	};

	const initReactionFields = () => {
		setReactionFieldsLoaded(false);
		axios.get(apiRoutes.ruleFields, {
			headers: authHeader,
			params: {
				locationId: ruleLocation.id
			}
		})
			.then(res => {
				setReactionFields(res.data);
				setReactionFieldsLoaded(true);
			})
			.catch(err => console.log(err));
	};

	const resetLocation = () => {
		dispatch(setRuleLocation(""));
		dispatch(setReactions([]));
		props.handleStepDataChange(true);
		setLocationLoaded(false);
		setReactionFieldsLoaded(false);
	};

	useEffect(() => {
		if (props.editedId && Object.keys(editedVersionOfRule).length > 0) {
			if (ruleLocation) {
				if (editedVersionOfRule.ruleLocation) {
					if (ruleLocation.id !== editedVersionOfRule.ruleLocation.id) {
						props.handleStepDataChange(true);
					}
				} else {
					props.handleStepDataChange(true);
				}
			}
		}
		if (ruleLocation) {
			initSelectedLocation();
			initReactionFields();
		}
		initRuleLocations();

	}, [ruleLocation, searchInput]);

	if (locationLoaded && reactionFieldsLoaded) {
		return (
			<React.Fragment>
				<Grid container justifyContent="space-evenly">
					<Grid item>
						<RuleWizardStepTitle>Location: {location.name}</RuleWizardStepTitle>
						<RuleImage src={location.previewUrl} />
					</Grid>
					<Grid item>
						<CoAppButtonGreenBg onClick={resetLocation} sx={{ margin: "15px" }}>Change Location</CoAppButtonGreenBg>
						<RuleReactionSidebar reactionFields={reactionFields} handleStepDataChange={props.handleStepDataChange}
							editedId={props.editedId} reactionTypes={reactionTypes}
							handleSaveChanges={props.handleSaveChanges} />
					</Grid>
				</Grid>
			</React.Fragment>
		);
	} else {
		return (
			<WizardLocationsContainer>
				<RuleWizardStepTitle sx={{ margin: "10px auto", width: "100%", textAlign: "center" }}>Where should your reaction happen?</RuleWizardStepTitle>
				<WizardLocationsSearchField
					value={searchInput}
					placeholder="Filter locations..."
					onChange={handleSearchInputChange}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						),
					}}
					inputProps={{ maxLength: 500 }}
				/>
				<RuleLocationGrid
					ruleLocationsLoaded={ruleLocationsLoaded}
					ruleLocations={ruleLocations}
					trigger={false}
					editedId={props.editedId}
					selectedCard={{ id: null }}
				/>
				<WizardLocationPagination
					currentPage={currentPage}
					currentPageSetter={setCurrentPage}
					totalPages={totalPages}
					totalPagesSetter={setTotalPages}
					currentSearchInput={searchInput}
					ruleLocationsSetter={setRuleLocations}
					ruleLocationsLoadedSetter={setRuleLocationLoaded}
					isTriggerLocations={false}
				/>
			</WizardLocationsContainer>
		);
	}
}