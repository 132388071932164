import Grid from "@mui/material/Grid";

import { CoAppH5 } from "../../global/styled/global.styled";
import { WizardLocationsGrid } from "../styled/rule-wizard.styled";

import RuleLocationCard from "./rule-location-card";

export default function RuleLocationGrid(props) {
	if (props.ruleLocations.length === 0) {
		return <CoAppH5 sx={{ margin: "auto" }}>No Locations Found...</CoAppH5>;
	} else {
		return (
			<WizardLocationsGrid container spacing={3}>
				{props.ruleLocations.map(item =>
					<Grid item xs="auto" key={item.id} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<RuleLocationCard
							locationId={props.locationId}
							id={item.id} thumbnailUrl={item.previewUrl}
							description={item.description} name={item.name}
							locations={props.ruleLocations}
							setLocationHandler={props.locationSelectionHandler}
							isTrigger={props.trigger}
							triggerCount={item.triggerCount ? true : false}
							editedId={props.editedId}
							selectedCard={item.id === props.selectedCard.id ? true : false}
						/>
					</Grid>
				)}
			</WizardLocationsGrid>
		);
	}
}