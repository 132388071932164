import { Container, styled } from "@mui/material";

export const SidebarContainer = styled(Container)(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	minHeight: "603px",
	width: "300px",
	marginLeft: "125px",
	marginTop: "14px",
	border: "1px solid #A9A9A9",
	borderRadius: theme.spacing(2),
	boxShadow: "0 1px 3px grey",
	overflow: "hidden",
	paddingLeft: "0px!important",
	paddingRight: "0px!important"
}));

export const SidebarTitle = styled("div")(({ theme }) => ({
	width: "100%",
	display: "inline-block",
	fontSize: "1.5rem",
	fontWeight: "600",
	padding: "10px"
}));

export const SidebarRow = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	width: "100%",
	height: "50px",
	padding: "2px",
	display: "inline-block",
	marginLeft: "5px"
}));

export const SidebarRowAddButton = styled("div")(({ theme, hasWhich }) => ({
	color: "#2FBC70",
	float: "right",
	marginRight: "30px",
	marginTop: hasWhich ? "20px" : "5px",
	"&.MuiSvgIcon-root": {
		fontSize: "small",
		verticalAlign: "middle",
		"&:hover": {
			cursor: "pointer",
			background: "none",
			color: "#A9A9A9"
		}
	}
}));

export const SidebarRowFieldName = styled("div")(({ theme }) => ({
	fontSize: "1.2rem",
	fontWeight: "600",
	width: "100%",
	display: "inline"
}));

export const SidebarRowMetadata = styled("div")(({ theme }) => ({
	fontSize: ".8rem",
	fontWeight: "400",
	width: "100%",
	display: "inline",
}));

export const FormulaCustomActions = styled("div")(({ theme }) => ({
	display: "flex",
	height: "50px",
	width: "300px",
	marginLeft: "25px",
	marginTop: "10px"
}));