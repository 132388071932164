import { useEffect,useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { CoAppButtonWhiteBg, CoAppDialogTextField } from "../../global/styled/global.styled";

export default function UserCreationDialog(props) {
	let authHeader = useAuthHeader();
	const [roles, setRoles] = useState([]);

	useEffect(() => {
		axios.get(apiRoutes.getRoles, {
			headers: authHeader,
		})
			.then(response => {
				setRoles(response.data.map(role => role.name));
			})
			.catch(error => {
				console.error("Error fetching roles:", error);
			});
	}, []);

	return (
		<Dialog open={props.creationDialogOpen} onClose={props.creationDialogCloseHandler}>
			<DialogTitle>New User</DialogTitle>
			<DialogContent>
				<Typography sx={{ color: "red" }}>{props.errorMessage}</Typography>
				<FormControl sx={{ display: "flex" }} required>
					<CoAppDialogTextField id="firstname" label="First Name" onChange={props.firstNameChangeHandler} />
				</FormControl>
				<FormControl sx={{ display: "flex" }} required>
					<CoAppDialogTextField id="lastname" label="Last Name" onChange={props.lastNameChangeHandler} />
				</FormControl>
				<FormControl sx={{ display: "flex" }} required>
					<InputLabel>Role</InputLabel>
					<Select
						variant="standard"
						sx={{ display: "flex", width: "100%" }}
						label="Role"
						onChange={props.roleChangeHandler}
					>
						{roles.map(role => (
							<MenuItem key={role} value={role}>{role}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ display: "flex" }} required>
					<CoAppDialogTextField id="email" label="Email Address" onChange={props.emailChangeHandler} />
				</FormControl>
			</DialogContent>
			<DialogActions>
				<CoAppButtonWhiteBg onClick={props.creationDialogCloseHandler} sx={{ padding: "12px", color: "#A1A1A1" }}>Cancel</CoAppButtonWhiteBg>
				<CoAppButtonWhiteBg onClick={props.createUserHandler}>Confirm</CoAppButtonWhiteBg>
			</DialogActions>
		</Dialog>
	);
}