import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messages from "../../../constants/messages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import {
	selectDescription, selectEditedVersionOfRule, selectName,
	selectTags, setDescription, setName, setTags
} from "../../../redux/ruleWizardSlice";
import TagInput from "../../tags/tag-input";
import {
	WizardStep1CardStructure,
	WizardStep1Container,
	WizardStep1HelpText,
	WizardStep1TextField,
	WizardStep1Title
} from "../styled/rule-wizard.styled";

/**
 * Step 1 in Rule Creation Wizard
 * User enters unique rule name, required description, and optional tags
*/
export default function WizardStep1(props) {
	const dispatch = useDispatch();
	let authHeader = useAuthHeader();

	const editedVersionOfRule = useSelector(selectEditedVersionOfRule);
	const currentName = useSelector(selectName);
	const currentDesc = useSelector(selectDescription);
	const currentTags = useSelector(selectTags);
	const [tagsInCurrentOrganization, setTagsInCurrentOrganization] = useState([]);
	const [hasLoaded, setHasLoaded] = useState(false);

	/**
	 * Get all existing tags in org.
	 */
	const initExistingTags = () => {
		if (!hasLoaded) {
			axios.get(apiRoutes.getTags, {
				headers: authHeader
			}).then(res => {
				setTagsInCurrentOrganization(res.data);
			}).catch(err => {
				console.error(err);
			});
		}
		setHasLoaded(true);
	};

	/**
	 * Sets name value/char length error msg on step one of the rule wizard.
	 */
	const setNameVal = (e) => {
		let name = e.target.value;
		if (name.length > 50) {
			props.stepOneErrorChangeHandler({
				...props.errors, nameError: true, nameErrorMessage:
					messages.TOO_MANY_CHARACTERS_ERROR_MSG(50, name.length - 50)
			});
			dispatch(setName(name));
		} else {
			delete props.errors.nameError;
			delete props.errors.nameErrorMessage;
			props.stepOneErrorChangeHandler({ ...props.errors });
			dispatch(setName(name));
		}
	};

	/**
	 * Sets description value/char length error msg on step one of the rule wizard.
	 */
	const setDescriptionVal = (e) => {
		let description = e.target.value;
		if (description.length > 255) {
			props.stepOneErrorChangeHandler({
				...props.errors, descriptionError: true, descriptionErrorMessage:
					messages.TOO_MANY_CHARACTERS_ERROR_MSG(255, description.length - 255)
			});
			dispatch(setDescription(description));
		} else {
			delete props.errors.descriptionError;
			delete props.errors.descriptionErrorMessage;
			props.stepOneErrorChangeHandler({ ...props.errors });
			dispatch(setDescription(description));
		}
	};

	const setCreateTags = (tags) => {
		dispatch(setTags(tags));
		props.handleSaveChanges(null, tags);

	};

	useEffect(() => {
		initExistingTags();
		if (props.editedId && Object.keys(editedVersionOfRule).length > 0) {
			if (currentName !== editedVersionOfRule.name || currentDesc !== editedVersionOfRule.description ||
				currentTags.length !== editedVersionOfRule.tags.length) {
				props.handleStepDataChange(true);
			}
		}
	}, [currentDesc, currentName, currentTags]);


	return (
		<WizardStep1Container>
			<WizardStep1Title>Let&apos;s start by giving this rule a name.</WizardStep1Title>
			<WizardStep1HelpText>Complete Each Step in order to Publish the Rule!</WizardStep1HelpText>

			<WizardStep1CardStructure sx={{ backgroundColor: "#FFF" }}>
				<WizardStep1TextField
					error={props.errors.nameError}
					helperText={props.errors.nameErrorMessage ? props.errors.nameErrorMessage : `${props.name.length}/50`}
					placeholder="Name"
					label="Name"
					fullWidth={true}
					variant="outlined"
					size="small"
					type="text"
					name="ruleName"
					value={props.name}
					onChange={setNameVal}
				/>

				<WizardStep1TextField
					error={props.errors.descriptionError}
					helperText={props.errors.descriptionErrorMessage ? props.errors.descriptionErrorMessage : `${props.description.length}/255`}
					placeholder="Description"
					label="Description"
					fullWidth={true}
					variant="outlined"
					size="small"
					type="text"
					name="description"
					value={props.description}
					onChange={setDescriptionVal}
				/>

				<TagInput createTags={setCreateTags} tags={props.tags} tagsInCurrentOrg={tagsInCurrentOrganization} />
			</WizardStep1CardStructure>
		</WizardStep1Container>
	);
}