import { Button, Grid, Paper, StepConnector, stepConnectorClasses, styled, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

export const WizardContainer = styled("div")(({ theme }) => ({
	width: "100%",
	height: "100%",
	backgroundColor: "#F8F9FA",
	marginBottom: "100px"
}));

export const WizardStepButtonsDiv = styled(Paper)(({ theme }) => ({
	paddingTop: "10px",
	boxShadow: "0px -1px 3px rgba(50, 50, 50, 0.75)",
	width: "100%",
	position: "fixed",
	bottom: 0
}));

export const WizardHeader = styled("div")(({ theme }) => ({
	width: "100%",
	minHeight: "100px",
	backgroundColor: "#FFFFFF",
	borderTop: "solid 1px #DCDCDC",
	borderBottom: "solid 1px #DCDCDC",
	alignItems: "center",
	justifyContent: "center",
	display: "flex"
}));

export const WizardHeaderRuleName = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "1.5rem",
	fontWeight: "700"
}));

export const WizardHeaderRuleDesc = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	fontSize: ".7rem",
	fontWeight: "600",
	width: "500px",
	[theme.breakpoints.down(1700)]: {
		width: "400px"
	}
}));

export const WizardHeaderRuleLocationName = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "1rem",
	fontWeight: "700"
}));

export const WizardHeaderRuleLocationEm = styled("em")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "1rem",
	fontWeight: "700",
	color: theme.palette.text.secondary
}));

export const WizardHeaderRuleLocationDesc = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	fontSize: "0.7rem",
	fontWeight: "600",
	whiteSpace: "pre-wrap",
	width: "700px",
	[theme.breakpoints.down(1700)]: {
		width: "400px"
	}

}));

export const WizardHeaderTag = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#E3E6EC" : "#E3E6EC",
	...theme.typography.body2,
	padding: theme.spacing(0.5),
	textAlign: "left",
	fontSize: "0.70rem",
	fontWeight: "700",
	color: "#7482A0",
	minWidth: "75px",
	float: "left",
	margin: "5px",
	borderRadius: "5px",
	alignItems: "center",
	display: "flex"
}));

export const RuleLocationLargePreview = styled("div")(({ theme }) => ({
	maxHeight: "500px",
	maxWidth: "500px",
	backgroundColor: "#FFFFFF",
	border: "solid 1px #DCDCDC",
	padding: "25px"
}));

export const RuleLocationMediumPreview = styled("div")(({ theme }) => ({
	maxHeight: "200px",
	maxWidth: "200px",
	backgroundColor: "#FFFFFF",
	border: " solid 1px #DCDCDC",
	padding: "25px"
}));

export const RuleImage = styled("img")(({ theme }) => ({
	backgroundColor: "#FFFFFF",
	border: "solid 1px #DCDCDC",
	padding: "25px",
	borderRadius: theme.spacing(0.75),
	boxShadow: "0 1px 3px grey",
	maxWidth: "650px",
	[theme.breakpoints.down(1700)]: {
		width: "800px"
	}
}));

export const BackToLocationButton = styled(Button)(({ theme }) => ({
	my: 2,
	color: "white",
	backgroundColor: "#2FBC70",
	display: "block",
	margin: "10px auto",
	marginTop: "10px"
}));

export const RuleWizardStepTitle = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(1),
	textAlign: "left",
	fontSize: "1.4rem",
	fontWeight: "700",
	color: theme.palette.text.secondary,
	width: "50%",
	margin: "10px",
	marginLeft: "30px",
	marginTop: "30px"
}));

export const WizardStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
	display: "flex",
	height: 40,
	padding: "10px",
	alignItems: "center",
	borderRadius: "50%",
	zIndex: 2,
	"& .WizardStepIcon-completedIcon": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: ownerState.active ? "#FFFFFF" : "#2FBC70",
		border: "12px solid #2FBC70"
	},
	"& .WizardStepIcon-circle": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "#FFFFFF",
		border: "10px solid #2FBC70"
	},
	"& .WizardStepIcon-circle-active-incomplete": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "#FFFFFF",
		border: "10px solid #2FBC70",
		opacity: "0.5"
	},
	"& .WizardStepIcon-circle-inactive-incomplete": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "grey",
		border: "10px solid #eaeaf0"
	}
}));

export const WizardStepConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 28
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#2FBC70"
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#2FBC70",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderTopWidth: 4,
		marginLeft: "-7.5px",
		marginRight: "-10px"
	},
}));

export const WizardSavedProgressIndicator = styled("div")(({ theme }) => ({
	marginLeft: "50px",
	marginTop: "25px",
	fontFamily: "\"Roboto\",\"Helvetica\",\"Arial\",sans-serif",
	fontSize: "10px",
	fontWeight: "500",
}));

/**
 * STEP 1
 */
export const WizardStep1Container = styled("div")(({ theme }) => ({
	width: "100%",
	height: "100%",
	backgroundColor: "#F8F9FA",
	borderTop: "solid 1px #DCDCDC"
}));

export const WizardStep1Title = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	paddingTop: theme.spacing(0.5),
	paddingBottom: theme.spacing(0.25),
	fontSize: "1.4rem",
	fontWeight: "700",
	color: theme.palette.text.secondary,
	width: "50%",
	margin: "10px auto",
	textAlign: "center",
	marginTop: "50px",
	[theme.breakpoints.down(1700)]: {
		marginTop: "20px"
	}
}));

export const WizardStep1HelpText = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	paddingTop: theme.spacing(0),
	fontSize: "1rem",
	fontWeight: "600",
	color: theme.palette.text.secondary,
	width: "50%",
	margin: "10px auto",
	textAlign: "center",
	[theme.breakpoints.down(1700)]: {
		marginTop: "20px"
	}
}));

export const WizardStep1CardStructure = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(5),
	textAlign: "left",
	color: theme.palette.text.secondary,
	boxShadow: "0 1px 3px grey",
	borderRadius: "5px",
	width: "40%",
	margin: "10px auto"
}));

export const WizardStep1TextField = styled(TextField)(({ theme }) => ({
	marginBottom: "15px",
	width: "100%"
}));

/**
 * STEPS 2 and 3
 */
export const WizardLocationsContainer = styled("div")(({ theme }) => ({
	backgroundColor: "#F8F9FA",
	borderTop: "solid 1px #DCDCDC",
	width: "100%",
	display: "grid"
}));

export const WizardLocationsSearchField = styled(TextField)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	fontSize: "2rem",
	fontWeight: "700",
	color: theme.palette.text.secondary,
	margin: "auto",
	backgroundColor: "#FFFFFF",
	borderRadius: "20px",
	display: "inherit",
	maxWidth: "14%"
}));

export const WizardLocationsGrid = styled(Grid)(({ theme }) => ({
	alignItems: "flex-start",
	width: "100%",
}));

export const WizardStep3ItemContainer = styled(Grid)(({ theme }) => ({
	width: "100%"
}));

/**
 * Step 4
 */
export const WizardOperatorItem = styled(Grid)((props) => ({
	border: `${props.selected ? "3px solid #2FBC70" : "1px solid #A9A9A9"}`,
	color: `${props.selected ? "#2FBC70" : "black"}`,
	padding: "2px",
	borderRadius: "5px",
	minWidth: "150px",
	height: "20px",
	margin: "auto",
	textAlign: "center",
	fontSize: "0.7rem",
	display: "flex",
	justifyContent: "center",
	alignContent: "center",
	flexDirection: "column",
	"&:hover": {
		cursor: "pointer",
		background: "none",
		color: "#2FBC70",
		borderColor: "#2FBC70"
	}
}));

export const WizardOperatorGrid = styled(Grid)(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(6, 1fr)",
	gridAutoRows: "auto",
	gridGap: "1rem",
	border: "1px solid #A9A9A9",
	padding: "5px",
	borderRadius: "5px",
	[theme.breakpoints.down(1700)]: {
		gridTemplateColumns: "repeat(5, 1fr)",
	}
}));

export const OperatorTitle = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "1rem",
	fontWeight: "600",
	color: "#7482A0",
	padding: "10px",
	marginTop: "-42px",
}));

export const FormulaHelpText = styled(Typography)(({ theme }) => ({
	fontStyle: "italic",
	fontSize: "0.65rem"
}));

/**
 * Step 5
 */
export const RuleSummary = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(5),
	textAlign: "left",
	color: theme.palette.text.secondary,
	boxShadow: "0 1px 3px grey",
	borderRadius: "5px",
	position: "relative",
	width: "40%",
	margin: "10px auto"
}));

export const RuleFormulaSummary = styled("div")(({ theme }) => ({
	backgroundColor: "#F8F9FA",
	...theme.typography.body2,
	color: "#111111",
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "flex-start",
	margin: "5px",
	padding: "15px"
}));

export const RuleFormulaSummaryItem = styled(Typography)(() => ({
	padding: "5px",
	fontWeight: "400"
}));