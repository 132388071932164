import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { CoAppButtonGreenBg, CoAppButtonWhiteBg, CoAppDialogTextField } from "../../global/styled/global.styled";

export default function CreateGroupDialog({ open, createGroup, errorMessage, groupName, handleGroupNameChange, handleCancel }) {

	return (
		<Dialog open={open} maxWidth="sm" fullWidth={true}>
			<DialogTitle>New Group</DialogTitle>
			<DialogContent>
				<CoAppDialogTextField
					sx={{ width: "95%" }}
					helperText={errorMessage}
					error={errorMessage !== ""}
					value={groupName}
					onChange={handleGroupNameChange}
					placeholder="Group name"
				/>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "flex-end", marginLeft: "5px" }}>
				<CoAppButtonGreenBg onClick={createGroup}>Confirm</CoAppButtonGreenBg>
				<CoAppButtonWhiteBg sx={{ padding: "12px", color: "#A1A1A1" }} onClick={handleCancel}>Cancel</CoAppButtonWhiteBg>
			</DialogActions>
		</Dialog >
	);
}