import { Search } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";

import { CoAppSearchContainer } from "../styled/global.styled";

export default function SearchInput({ placeholder, onChangeHandler, customStyles }) {

	const handleSearchInputChange = (e) => {
		onChangeHandler(e.target.value);
	};

	return (
		<CoAppSearchContainer>
			<Grid item >
				<TextField
					placeholder={placeholder}
					size='small'
					sx={{ backgroundColor: "#FFFFFF", borderRadius: "20px", marginTop: "5px", ...customStyles }}
					onChange={handleSearchInputChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
						style: {
							borderRadius: "20px"
						}
					}}
					inputProps={{ maxLength: 500 }}
				/>
			</Grid>
		</CoAppSearchContainer>
	);
}