const pages = {
	group: (id) => `/settings/group/${id}`,
	editRule: (ruleId) => `/editRule/${ruleId}`,
	groupManagement: "/settings/groups",
	groupPlugins: "/settings/group/plugins",
	groupRules: "/settings/group/rules",
	groupSSO: "/settings/group/sso",
	groupUsers: "/settings/group/users",
	home: "/library",
	login: "/",
	newRule: "/createRule",
	organizationManagement: "/settings/",
	pluginGroups: (id) => `/settings/plugin/${id}`,
	pluginManagement: "/settings/plugins",
	profile: "/profile",
	role: (id) => `/settings/role/${id}`,
	rolePermissions: "/settings/role/permissions",
	roleUsers: "/settings/role/users",
	roleSSO: "/settings/role/sso",
	roleManagement: "/settings/roles",
	scimWizard: "/settings/organization/sso-scim",
	settings: "/settings",
	userManagement: "/settings/users",
	workstationManagement: "/settings/workstations"
};

export default pages;