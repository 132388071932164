import { useDispatch, useSelector } from "react-redux";

import { selectRuleFilters, setRuleFilters } from "../redux/ruleSlice";

export default function useRuleFilter() {
	const dispatch = useDispatch();
	const filters = useSelector(selectRuleFilters);

	/**
	 * 1. Get the current filters from redux.
	 * 2. Update the filters based on current state of redux.
	 */
	const getCurrentlyAppliedFilters = () => {
		let queryObj = {};
		for (let item in filters) {
			queryObj[item] = filters[item];
		}
		queryObj["page"] = filters["page"];
		return queryObj;
	};

	/**
	 * 1. Get the current filters from redux.
	 * 2. Update the filters based on the filterType and newFilterValue.
	 * 3. Dispatch the new filters to redux.
	 * 4. Return the new filters to the caller.
	 */
	const updateFilters = (filterType, newFilterValue) => {
		let queryObj = getCurrentlyAppliedFilters();
		if (filterType === "default") {
			queryObj["page"] = newFilterValue;
			dispatch(setRuleFilters(queryObj));
			return queryObj;
		}

		switch (filterType) {
			case "status":
				switch (newFilterValue) {
					case "Draft":
						queryObj["isDraft"] = 1;
						delete queryObj["active"];
						delete queryObj["isTrashed"];
						break;
					case "Deleted":
						queryObj["isTrashed"] = 1;
						delete queryObj["active"];
						delete queryObj["isDraft"];
						break;
					case "Active":
						queryObj["active"] = 1;
						delete queryObj["isTrashed"];
						delete queryObj["isDraft"];
						break;
					case "Inactive":
						queryObj["active"] = 0;
						queryObj["isTrashed"] = 0;
						queryObj["isDraft"] = 0;
						break;
					case "":
						delete queryObj["isTrashed"];
						delete queryObj["isDraft"];
						delete queryObj["active"];
						break;
					default:
						break;
				}
				break;
			case "tag":
				if (newFilterValue.length === 0) {
					delete queryObj["tagId"];
				} else {
					queryObj["tagId"] = newFilterValue;
				}
				break;
			case "group":
				if (newFilterValue.length === 0) {
					delete queryObj["groupId"];
				} else {
					queryObj["groupId"] = newFilterValue;
				}
				break;
			case "search":
				if (newFilterValue === "") {
					delete queryObj["name"];
				} else {
					queryObj["name"] = newFilterValue;
				}
				break;
			default:
				break;
		}
		dispatch(setRuleFilters(queryObj));
		return queryObj;
	};

	return updateFilters;
}