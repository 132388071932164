import { useSelector } from "react-redux";
import Label from "@mui/icons-material/Label";
import Grid from "@mui/material/Grid";

import { selectDescription, selectName, selectRuleLocation, selectTags } from "../../redux/ruleWizardSlice";

import {
	WizardHeader,
	WizardHeaderRuleDesc,
	WizardHeaderRuleLocationDesc,
	WizardHeaderRuleLocationEm,
	WizardHeaderRuleLocationName,
	WizardHeaderRuleName,
	WizardHeaderTag
} from "./styled/rule-wizard.styled";

export default function RuleWizardHeader() {
	const name = useSelector(selectName);
	const description = useSelector(selectDescription);
	const tags = useSelector(selectTags);
	const ruleLocation = useSelector(selectRuleLocation);

	return (
		<WizardHeader>
			<Grid container sx={{ flex: "center", justifyContent: "space-between", alignItems: "inherit" }}>
				<Grid sx={{ marginLeft: "10px" }}>
					<WizardHeaderRuleName>{name}</WizardHeaderRuleName>
					<WizardHeaderRuleDesc>{description}</WizardHeaderRuleDesc>
				</Grid>
				{
					ruleLocation !== "" && ruleLocation !== null
						?
						<Grid sx={{ paddingLeft: "12px" }}>
							<WizardHeaderRuleLocationName>
								Location:
								<WizardHeaderRuleLocationEm> {ruleLocation.name}</WizardHeaderRuleLocationEm>
							</WizardHeaderRuleLocationName>
							<WizardHeaderRuleLocationDesc>Location Description: {ruleLocation.description}</WizardHeaderRuleLocationDesc>
						</Grid>
						:
						null
				}
				<Grid sx={{ marginRight: "30px" }}>
					{tags.map((tag, index) => <WizardHeaderTag key={index} ><Label fontSize="small" sx={{ paddingRight: 1 }} /> {tag.toUpperCase()}</WizardHeaderTag>)}
				</Grid>
			</Grid>
		</WizardHeader>
	);
}