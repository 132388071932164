import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress, Container, Grid, Stack } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import prettifyDate from "../../../helpers/prettifyDate";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import ConfirmationDialog from "../../global/components/confirmation-dialog";
import SearchInput from "../../global/components/search-input";
import { CoAppDataGrid, CoAppH2, CoAppMainContainer, CoAppStatusToggle } from "../../global/styled/global.styled";
import { SubMenuContainer } from "../styled/settings.styled";

export default function WorkstationManagement() {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();

	const [dialogDetails, setDialogDetails] = useState({ dialogOpen: false });
	const [workstationsLoaded, setWorkstationsLoaded] = useState(false);
	const [workstations, setWorkstations] = useState([]);
	const [workstationSearchInput, setWorkstationSearchInput] = useState("");

	const columns = [
		{
			field: "serialNumber",
			headerName: "SERIAL NUMBER",
			headerClassName: "data-grid-header",
			width: 200,
			editable: false,
		},
		{
			field: "hostName",
			headerName: "COMPUTER NAME",
			headerClassName: "data-grid-header",
			width: 200,
			editable: false,
		},
		{
			field: "ip",
			headerName: "INTERNAL IP",
			headerClassName: "data-grid-header",
			width: 250,
			editable: false,
		},
		{
			field: "macAddress",
			headerName: "MAC ADDRESS",
			headerClassName: "data-grid-header",
			width: 275,
			editable: false,
		},
		{
			field: "lastLogUpload",
			headerName: "LAST LOG UPLOAD",
			headerClassName: "data-grid-header",
			width: 300,
			editable: false,
			renderCell: (params) => (
				<div>
					{params.value &&
						new Date(params.value).toLocaleString(undefined, {
							timeZone: "America/New_York",
							year: "numeric",
							month: "numeric",
							day: "numeric",
							hour: "numeric",
							minute: "numeric",
							second: "numeric",
							hour12: true,
						})}
				</div>
			)
		},
		{
			field: "lastPhoneHome",
			headerName: "LAST SEEN",
			headerClassName: "data-grid-header",
			width: 250,
			editable: false,
			renderCell: (params) => (
				<div>
					{params.value &&
						prettifyDate(params.value)}
				</div>
			)
		},
		{
			field: "killSwitchActive",
			headerName: "KILLSWITCH STATUS",
			headerClassName: "data-grid-header",
			width: 200,
			editable: true,
			type: "boolean",
			renderCell: (params) => (
				<div>
					<CoAppStatusToggle
						checked={params.row.killSwitchActive}
						onChange={() => handleOpenConfirmDialog(params.row.uuid, params.row.serialNumber, params.row.killSwitchActive === true ? "false" : "true")} />
				</div>
			)
		},
	];

	const handleOpenConfirmDialog = (uuid, name, state) => {
		setDialogDetails({
			dialogOpen: true,
			confirmationTitle: messages.WORKSTATION_TOGGLE_CONFIRMATION_MSG(state, name),
			confirmClickHandler: () => handleWorkstationStatusToggle(uuid, state),
			cancelClickHandler: () => setDialogDetails({})
		});
	};

	const initWorkstationManagement = () => {
		let workstationQueryObject = {
			serialNumber: workstationSearchInput ? workstationSearchInput : null,
		};

		axios.get(apiRoutes.getWorkstations, {
			headers: authHeader,
			params: workstationQueryObject,
		}).then((res) => {
			setWorkstations(res.data);
			setWorkstationsLoaded(true);
		}).catch((err) => {
			console.log(err);
			setWorkstationsLoaded(true);
		});
	};

	const handleWorkstationStatusToggle = (uuid, state) => {
		let workstationJSON = {
			killSwitchActive: state
		};
		axios.put(apiRoutes.workstationKillSwitch + "/" + uuid, workstationJSON, {
			headers: authHeader
		}).then(() => {
			setDialogDetails({ dialogOpen: false });
			initWorkstationManagement();
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.SUCCESS));
			dispatch(setMessage(messages.WORKSTATION_STATUS_TOGGLE_SUCCESS_MSG));
		})
			.catch((error) => {
				console.error(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.WORKSTATION_STATUS_TOGGLE_ERROR_MSG));
			});
	};

	useEffect(() => {
		initWorkstationManagement();
	}, [workstationSearchInput]);

	if (workstationsLoaded && workstations) {
		return (
			<CoAppMainContainer sx={{ p: 2, width: "95%" }}>
				<SubMenuContainer>
					<CoAppH2 sx={{ float: "left" }}>Machines</CoAppH2>

					{
						workstations.length > 0 && (
							<SearchInput
								placeholder="Search workstations"
								onChangeHandler={setWorkstationSearchInput} />
						)
					}
				</SubMenuContainer>

				<Grid container spacing={1} justifyContent="stretch">
					<CoAppDataGrid
						columnHeaderHeight={45}
						columns={columns}
						disableRowSelectionOnClick
						getRowId={(row) => row.uuid}
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
						}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 25, page: 0 },
							},
						}}
						pagination
						rows={workstations}
					/>
				</Grid>
				<ConfirmationDialog dialogDetails={dialogDetails} />
			</CoAppMainContainer>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}