import { useSelector } from "react-redux";
import Warning from "@mui/icons-material/Warning";
import { Card } from "@mui/material";

import messages from "../../../constants/messages";
import { selectPermissions } from "../../../redux/userSlice";
import { CoAppBasicContainer, CoAppButtonGreenBg, CoAppH2 } from "../styled/global.styled";

export default function Unauthorized() {
	const permissions = useSelector(selectPermissions);
	return (
		<CoAppBasicContainer>
			<Card
				sx={{
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					height: "497px",
					width: "1364px",
					boxShadow: "0px 1.97px 20px -0.99px #00000021",
					marginTop: "50px"
				}}
			>
				<Warning sx={{ fontSize: "50px", color: "#D32F2F" }} />
				<CoAppH2>{messages.PAGE_ACCESS_DENIED_MSG}</CoAppH2>
				<p style={{ margin: "0px" }}>{messages.PAGE_PERMISSION_DENIED_MSG}</p>
				<p style={{ margin: "0px" }}>{messages.CONTACT_ADMIN_MSG}</p>
				{
					permissions && permissions.includes("ACCESS_MP") ?
						< CoAppButtonGreenBg onClick={() => window.history.back()} sx={{ marginTop: "20px" }}>
							Go Back
						</CoAppButtonGreenBg>
						:
						null
				}
			</Card>
		</CoAppBasicContainer >
	);
}