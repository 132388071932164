import pages from "../../constants/pages";
import { CoAppH1 } from "../global/styled/global.styled";

import { MainMenuContainer, MainMenuItem, SettingMenuContainer, SettingsDivider } from "./styled/settings.styled";
import PrivateSettingsItem from "./private-settings-item";

export default function SettingsMenu({ activeItem, onMenuItemClick }) {
	return (
		<SettingMenuContainer>
			<MainMenuContainer>
				<CoAppH1 sx={{ marginRight: "50px" }}>Settings</CoAppH1>
				<PrivateSettingsItem
					permissions={["MANAGE_SSOSCIM", "MANAGE_ORG_FORCELOGIN", "MANAGE_ORG_KILLSWITCH"]}
					isInclusive={false}
					child={
						<MainMenuItem
							className={activeItem === pages.organizationManagement ? "active" : ""}
							onClick={() => onMenuItemClick(pages.organizationManagement)}>ORGANIZATION
						</MainMenuItem>
					}
				/>
				<PrivateSettingsItem
					permissions={["MANAGE_USERS_AND_GROUPS"]}
					child={
						<>
							<MainMenuItem
								className={activeItem === pages.roleManagement ? "active" : ""}
								onClick={() => onMenuItemClick(pages.roleManagement)}>ROLES
							</MainMenuItem>
							<MainMenuItem
								className={activeItem === pages.userManagement ? "active" : ""}
								onClick={() => onMenuItemClick(pages.userManagement)}>USERS
							</MainMenuItem>
							<MainMenuItem
								className={activeItem === pages.groupManagement ? "active" : ""}
								onClick={() => onMenuItemClick(pages.groupManagement)}>GROUPS
							</MainMenuItem>
						</>
					}
				/>
				<PrivateSettingsItem
					permissions={["MANAGE_WORKSTATIONS"]}
					child={
						<MainMenuItem
							className={activeItem === pages.workstationManagement ? "active" : ""}
							onClick={() => onMenuItemClick(pages.workstationManagement)}>ACCESS CONTROL
						</MainMenuItem>
					}
				/>
				<PrivateSettingsItem
					permissions={["MANAGE_PLUGINS"]}
					child={
						<MainMenuItem
							className={activeItem === pages.pluginManagement ? "active" : ""}
							onClick={() => onMenuItemClick(pages.pluginManagement)}>PLUGINS
						</MainMenuItem>
					}
				/>
			</MainMenuContainer>
			<SettingsDivider />
		</SettingMenuContainer>
	);
}
