import { Restore } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

import { RuleCardActionsCopyButton, RuleCardActionsDeleteButton, RuleCardActionsDiv, RuleCardActionsEditButton } from "./styled/rule-card.styled";

export default function RuleCardActions(props) {
	return (
		<div>
			<RuleCardActionsDiv>
				<Tooltip title="Copy Rule" placement="top" arrow>
					<RuleCardActionsCopyButton
						sx={{
							":hover": { color: "#2FBD70" },
							pointerEvents: props.trashedStatus ? "none" : "auto",
						}}
						onClick={() => props.openCopyConfirmationHandler(true)}
					/>
				</Tooltip>
				<Tooltip title="Edit Rule" placement="top" arrow>
					<RuleCardActionsEditButton
						sx={{
							":hover": { color: "#2FBD70" },
							pointerEvents: props.trashedStatus ? "none" : "auto",
						}}
						onClick={props.editRuleHandler}
					/>
				</Tooltip>
				<Tooltip title="Delete Rule" placement="top" arrow>
					<RuleCardActionsDeleteButton
						sx={{
							":hover": { color: "#2FBD70" },
							pointerEvents: props.trashedStatus ? "none" : "auto",
							paddingRight: props.trashedStatus ? "20px" : "5px"
						}}
						onClick={() => props.openDeleteConfirmationHandler(true)}
					/>
				</Tooltip>
				{props.trashedStatus ?
					<Tooltip title="Restore Rule" placement='top' arrow>
						<Restore sx={{ color: "#2FBD70" }} onClick={props.restoreRuleHandler} />
					</Tooltip>
					:
					null
				}
			</RuleCardActionsDiv>
		</div>
	);
}