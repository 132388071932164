import { createTheme, ThemeProvider } from "@mui/material/styles";

import AlertSnackbar from "./components/global/components/alert-snackbar";
import AppRoutes from "./routes";

const theme = createTheme({
	palette: {
		type: "light",
		primary: {
			main: "#ffffff",
			contrastText: "#111111",
		},
		secondary: {
			main: "#f50057",
		},
		background: {
			default: "#f1f1f1",
		},
		icon: {
			main: "#2fbd70",
		},
		paperTitle: {
			main: "#f50057",
		}
	},
	components: {
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: "#2fbd70",
					"&.Mui-checked": {
						color: "#2fbd70",
					}
				}
			}
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: "1px solid #e0e0e0",
					borderRadius: "5px",
					"& .MuiDataGrid-toolbarContainer": {
						backgroundColor: "#FFFFFF",
						color: "grey",
						"& .MuiButton-text": {
							color: "grey",
						}
					},
					"& .MuiDataGrid-pinnedColumnHeaders": {
						backgroundColor: "#FFFFFF"
					},
					"& .MuiDataGrid-pinnedColumns": {
						backgroundColor: "#FFFFFF"
					}
				},
			},
		},
	}
});


export default function App() {

	return (
		<ThemeProvider theme={theme}>
			<style>{"body { background-color: #F8F9FA; font-family: sans-serif; }"}</style>
			<div>
				<AppRoutes />
				<AlertSnackbar />
			</div>
		</ThemeProvider>
	);
}