import { Route, Routes } from "react-router-dom";

import Extension from "./components/extension";
import FieldManagement from "./components/field-location-management/fields/field-management";
import LocationManagement from "./components/field-location-management/locations/location-management";
import Login from "./components/login";
import ManagerApprovalRequest from "./components/requests/manager-approval-request";
import RuleLibrary from "./components/rule-library";
import RuleWizard from "./components/rule-wizard";
import Settings from "./components/settings";
import Group from "./components/settings/groups/group";
import OrganizationManagement from "./components/settings/organization";
import ScimWizard from "./components/settings/organization/scim-wizard";
import Plugin from "./components/settings/plugins/plugin";
import Role from "./components/settings/roles/role";
import UserProfile from "./components/settings/users/user-profile";
import WorkstationManagement from "./components/settings/workstations/workstation-management";
import Swagger from "./components/swagger";
import ProtectedRoute from "./ProtectedRoute";
import WithNavRoute from "./WithNavRoute";
import WithoutNavRoute from "./WithoutNavRoute";

function AppRoutes() {
	return (
		<Routes>
			<Route element={<WithoutNavRoute />}>
				<Route path="/" element={<Login />} />
				<Route path="/extension" element={<Extension />} />
				<Route exact path="/requests/:action/:requestId" element={<ManagerApprovalRequest />} />
				<Route path="/swagger" element={<ProtectedRoute />}>
					<Route exact path="/swagger" element={<Swagger />} />
				</Route>
				<Route path="/field-mgmt" element={<ProtectedRoute />}>
					<Route exact path="/field-mgmt" element={<FieldManagement />} />
				</Route>
				<Route path="/location-mgmt" element={<ProtectedRoute />}>
					<Route exact path="/location-mgmt" element={<LocationManagement />} />
				</Route>
			</Route>
			<Route element={<WithNavRoute />}>
				<Route path="/createRule" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/createRule" element={<RuleWizard />} />
				</Route>
				<Route path="/editRule/:id" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/editRule/:id" element={<RuleWizard />} />
				</Route>
				<Route exact path="/library" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/library" element={<RuleLibrary />} />
				</Route>
				<Route exact path='/profile/:id' element={<ProtectedRoute permissions={["ACCESS_MP"]} isInclusive={true} />}>
					<Route exact path='/profile/:id' element={<UserProfile />} />
				</Route>
				<Route exact path="/settings" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS", "MANAGE_PLUGINS", "MANAGE_WORKSTATIONS", "MANAGE_ORG_KILLSWITCH", "MANAGE_ORG_FORCE_LOGIN", "MANAGE_SSOSCIM"]} isInclusive={false} />}>
					<Route exact path="/settings" element={<Settings />} />
				</Route>
				<Route path="/settings/group/:id" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/group/:id" element={<Group />} />
				</Route>
				<Route path="/settings/group" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/group" element={<Group />} />
				</Route>
				<Route exact path="/settings/organization" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_ORG_KILLSWITCH", "MANAGE_ORG_FORCELOGIN", "MANAGE_SSOSCIM"]} isInclusive={false} />}>
					<Route exact path="/settings/organization" element={<OrganizationManagement />} />
				</Route>
				<Route path="/settings/role/:id" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/role/:id" element={<Role />} />
				</Route>
				<Route path="/settings/organization/sso-scim" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_SSOSCIM"]} isInclusive={true} />}>
					<Route exact path="/settings/organization/sso-scim" element={<ScimWizard />} />
				</Route>
				<Route exact path="/settings/workstations" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_WORKSTATIONS"]} isInclusive={true} />}>
					<Route exact path="/settings/workstations" element={<WorkstationManagement />} />
				</Route>
				<Route path="/settings/plugin/:id" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_PLUGINS"]} isInclusive={true} />}>
					<Route exact path="/settings/plugin/:id" element={<Plugin />} />
				</Route>
			</Route>
		</Routes >
	);
}

export default AppRoutes;