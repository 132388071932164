import { useEffect } from "react";
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, OutlinedInput, Stack, Typography } from "@mui/material";

import { CoAppButtonGreenBg, CoAppButtonWhiteBg } from "../styled/global.styled";

export default function AddItemsToCollectionDialog( props ) {

	const addButtonTitle = props.addButtonTitle;
	const addItemsIsToggled = props.addItemsIsToggled;
	const addItemsToCollection = props.addItemsToCollection;
	const addItemsToNewArray = props.addItemsToNewArray;
	const dialogSearchPlaceholder = props.dialogSearchPlaceholder;
	const dialogTitle = props.dialogTitle;
	const items = props.items;
	const itemsToAdd = props.itemsToAdd;
	const searchInput = props.searchInput;
	const setSearchInput = props.setSearchInput;
	const toggleDialog = props.toggleDialog;

	useEffect(() => {
	}, [addItemsIsToggled]);

	if (addItemsIsToggled && items.length > 0) {
		return (
			<Dialog open={addItemsIsToggled} onClose={toggleDialog} sx={{ minHeight: 200, maxHeight: 800 }}>
				<Box sx={{ p: 2, overflowY: "auto" }}>
					<DialogTitle variant="h6">{dialogTitle}</DialogTitle>
					<OutlinedInput
						id="search"
						placeholder={dialogSearchPlaceholder}
						onChange={(e) => setSearchInput(e.target.value)}
						sx={{ width: "100%" }}
					/>
					<DialogContent>
						<FormControl sx={{ m: 1, width: 300, height: 300 }}>
							<FormGroup>
								{items
									.filter((item) =>
										`${item.name}`
											.toLowerCase()
											.includes(searchInput.toLowerCase())
									)
									.map((item) => (
										<FormControl
											key={item.id}
											sx={{
												m: 1,
												display: "flex",
												alignItems: "left",
												justifyContent: "flex-start"
											}}
										>
											<FormControlLabel
												control={<Checkbox checked={itemsToAdd.includes(item)}
													onChange={() => addItemsToNewArray(item)}
													name={item.name} />}
												label={`${item.name}`}
												sx={{ width: "100%", display: "inline" }}/>
										</FormControl>
									))}
							</FormGroup>
						</FormControl>
					</DialogContent>
				</Box>
				<Stack direction="row" spacing={2} sx={{ borderTop: "1px solid #ccc", padding: "15px", display: "flex", justifyContent: "flex-end" }}>
					<CoAppButtonWhiteBg onClick={toggleDialog}>CANCEL</CoAppButtonWhiteBg>
					<CoAppButtonGreenBg disabled={itemsToAdd.length === 0} onClick={addItemsToCollection}>ADD {itemsToAdd.length > 0 ? itemsToAdd.length : null } {addButtonTitle}</CoAppButtonGreenBg>
				</Stack>
			</Dialog>
		);
	} else {
		return (
			<Dialog open={addItemsIsToggled} onClose={toggleDialog} sx={{ minHeight: 200, maxHeight: 800 }}>
				<Box sx={{ p: 2, overflowY: "auto" }}>
					<DialogTitle variant="h6">{dialogTitle}</DialogTitle>
					<DialogContent>
						<Typography variant="body1">No items to add.</Typography>
					</DialogContent>
				</Box>
				<Stack direction="row" spacing={2} sx={{ borderTop: "1px solid #ccc", padding: "15px", display: "flex", justifyContent: "flex-end" }}>
					<CoAppButtonWhiteBg onClick={toggleDialog}>CANCEL</CoAppButtonWhiteBg>
				</Stack>
			</Dialog>
		);
	}
}