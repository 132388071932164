import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Cancel, Check, PriorityHigh } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import useAuthorization from "../../../helpers/useAuthorization";
import { selectUser } from "../../../redux/userSlice";
import {
	selectOrganizationForceLogin,
	selectOrganizationKillSwitch,
	selectOrganizationSsoAndScim,
	setOrganizationForceLogin,
	setOrganizationKillSwitch,
	setOrganizationSsoAndScim
} from "../../../redux/userSlice";
import ConfirmationDialog from "../../global/components/confirmation-dialog";
import ThreeDotMenu from "../../global/components/three-dot-menu";
import { CoAppH4, CoAppMainContainer, CoAppStack, CoAppStackItem } from "../../global/styled/global.styled";

import {
	KillSwitchDialogContent,
	OrganizationManagementItemStatusText,
	OrganizationManagementItemTitle,
} from "./styled/organization-management.styled";

export default function OrganizationManagement() {
	const isAuthorizedForSsoAndScim = useAuthorization(["MANAGE_SSOSCIM"], false);
	const isAuthorizedForForceLogin = useAuthorization(["MANAGE_ORG_FORCELOGIN"], false);
	const isAuthorizedForKillSwitch = useAuthorization(["MANAGE_ORG_KILLSWITCH"], false);
	let dispatch = useDispatch();
	let navigate = useNavigate();
	let authHeader = useAuthHeader();
	const currentUser = useSelector(selectUser);
	const organizationForceLogin = useSelector(selectOrganizationForceLogin);
	const organizationKillSwitch = useSelector(selectOrganizationKillSwitch);
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const [confirmationDialogContent, setConfirmationDialogContent] = useState({ dialogOpen: false });
	const [ssoAndScimConfiguredDate, setSsoAndScimConfiguredDate] = useState(null);
	const [forceLogin, setForceLogin] = useState(false);
	const [killSwitch, setKillSwitch] = useState(false);

	const [forceLoginIsToggled, setForceLoginIsToggled] = useState(false);
	const [killSwitchIsToggled, setKillSwitchIsToggled] = useState(false);

	const confirmForceLoginToggle = () => {
		let forceLogin = organizationForceLogin ? false : true;

		let orgJSON = {
			forceLogin: forceLogin
		};

		axios.put(apiRoutes.forceLogin(currentUser.organizationId), orgJSON, {
			headers: authHeader
		}).then(() => {
			dispatch(setOrganizationForceLogin(forceLogin));
			setForceLoginIsToggled(false);
		}).catch((err) => {
			console.log(err);
			return null;
		});
	};

	const configureSsoAndScim = () => {
		navigate(pages.scimWizard);
	};

	const confirmSsoAndScimConfiguration = () => {
		let dialogContent = {
			dialogOpen: true,
			confirmationMessage: "",
			confirmationListItems: [
				messages.SCIM_SETUP_MESSAGE_ONE,
				messages.SCIM_SETUP_MESSAGE_TWO,
				messages.SCIM_SETUP_MESSAGE_THREE,
				messages.SCIM_SETUP_MESSAGE_FOUR,
				messages.SCIM_SETUP_MESSAGE_FIVE
			],
			confirmationTitle: "Things to know before you proceed:",
			confirmClickHandler: () => {
				configureSsoAndScim();
			},
			cancelClickHandler: () => {
				setConfirmationDialogContent({});
			}
		};
		setConfirmationDialogContent(dialogContent);
	};

	const confirmKillSwitchToggle = () => {
		let killSwitch = organizationKillSwitch ? false : true;

		let orgJSON = {
			killswitch: killSwitch
		};

		axios.put(apiRoutes.killSwitch(currentUser.organizationId), orgJSON, {
			headers: authHeader
		}).then((res) => {
			dispatch(setOrganizationKillSwitch(killSwitch));
			setKillSwitch(res.data);
			setKillSwitchIsToggled(false);
		}).catch((err) => {
			console.log(err);
			setKillSwitch(false);
			return null;
		});
	};

	const disableSsoAndScim = () => {
		axios.delete(apiRoutes.ssoAndScim(currentUser.organizationId), { headers: authHeader })
			.then(res => {
				navigate(pages.organizationManagement);
				dispatch(setOrganizationSsoAndScim(false));
				setConfirmationDialogContent({});
			})
			.catch(err => console.error(err));
	};

	const getForceLoginStatus = () => {
		if (organizationForceLogin === undefined) {
			axios.get(apiRoutes.forceLogin(currentUser.organizationId), {
				headers: authHeader
			}).then((res) => {
				dispatch(setOrganizationForceLogin(res.data));
				setForceLogin(res.data);
				setForceLoginIsToggled(false);
			}).catch((err) => {
				console.log(err);
				setForceLogin(false);
			});
		} else {
			setForceLogin(organizationForceLogin);
		}
	};

	const handleForceLoginSwitchToggle = () => {
		setForceLoginIsToggled(forceLoginIsToggled ? false : true);
	};

	const handleDisableSsoAndScim = () => {
		let dialogContent = {
			dialogOpen: true,
			confirmationMessage: "Removing SSO will remove all syncing between your provider and CoApp. All states will be frozen to their current state.",
			confirmationTitle: "Warning - Are you sure?",
			confirmClickHandler: () => {
				disableSsoAndScim();
			},
			cancelClickHandler: () => {
				setConfirmationDialogContent({});
			}
		};
		setConfirmationDialogContent(dialogContent);
	};

	const handleKillSwitchToggle = () => {
		setKillSwitchIsToggled(killSwitchIsToggled ? false : true);
	};

	const getKillSwitchStatus = () => {
		if (organizationKillSwitch === undefined) {
			axios.get(apiRoutes.killSwitch(currentUser.organizationId), {
				headers: authHeader
			}).then((res) => {
				dispatch(setOrganizationKillSwitch(res.data));
				setKillSwitchIsToggled(false);
			}).catch((err) => {
				console.log(err);
				return null;
			});
		} else {
			setKillSwitch(organizationKillSwitch);
		}
	};

	const getSsoAndScimStatus = () => {
		axios.get(apiRoutes.ssoAndScim(currentUser.organizationId), {
			headers: authHeader
		}).then((res) => {
			dispatch(setOrganizationSsoAndScim(res.data.status));
			setSsoAndScimConfiguredDate(res.data.configuredOn);
		}).catch((err) => {
			console.log(err);
			return null;
		});
	};

	const syncUsers = () => {
		axios.post(apiRoutes.sync(currentUser.organizationId), {}, {
			headers: authHeader

		}).then(() => {
			console.log("Syncing users");
		}).catch((err) => {
			console.log(err);
			return null;
		});
	};

	useEffect(() => {
		if (isAuthorizedForForceLogin)
			getForceLoginStatus();
		if (isAuthorizedForKillSwitch)
			getKillSwitchStatus();
		if (isAuthorizedForSsoAndScim)
			getSsoAndScimStatus();
	}, [organizationKillSwitch, organizationForceLogin, organizationSsoAndScim]);

	return (
		<CoAppMainContainer sx={{ p: 2 }}>
			<CoAppStack spacing={1}>
				{
					isAuthorizedForSsoAndScim ?
						<CoAppStackItem style={{ justifyContent: "space-between" }}>
							<OrganizationManagementItemTitle>
								{
									organizationSsoAndScim ?
										<ul>
											<li style={{ listStyleType: "none", fontSize: "1rem", marginLeft: "-40px" }}>SSO + SCIM</li>
											<li style={{ listStyleType: "none", color: "#A2A2A2", fontSize: "0.7rem", marginLeft: "-40px" }}>Manage groups within the Groups tab.</li>
										</ul>
										:
										"SSO + SCIM"

								}
							</OrganizationManagementItemTitle>
							<OrganizationManagementItemStatusText>
								{
									organizationSsoAndScim ?
										<ul>
											<li style={{ listStyleType: "none", color: "#2FBC70", fontSize: "1rem", fontWeight: "600", paddingLeft: "100px" }}>Configured</li>
											<li style={{ listStyleType: "none", color: "#A2A2A2", fontSize: "0.7rem" }}>Office 365 - configured {new Date(ssoAndScimConfiguredDate).toLocaleDateString()}</li>
										</ul>
										:
										"Not Configured"

									

								}

								{
									organizationSsoAndScim ?
										<ThreeDotMenu options={[{ name: "Disable", optionClickHandler: handleDisableSsoAndScim }, { name: "Sync Now", optionClickHandler: () => { syncUsers(); } } ]} />
										:
										<ThreeDotMenu options={[{ name: "Configure", optionClickHandler: confirmSsoAndScimConfiguration }]} />
								}
							</OrganizationManagementItemStatusText>
						</CoAppStackItem>
						:
						null
				}
				{
					isAuthorizedForKillSwitch ?
						<CoAppStackItem style={{ justifyContent: "space-between" }}>
							<OrganizationManagementItemTitle>Browser Extension Kill Switch</OrganizationManagementItemTitle>
							<OrganizationManagementItemStatusText>
								{killSwitch ? "Enabled" : "Disabled"}
								<ThreeDotMenu options={[{ name: killSwitch ? "Disable" : "Enable", optionClickHandler: handleKillSwitchToggle }]} />
							</OrganizationManagementItemStatusText>
						</CoAppStackItem>
						:
						null
				}
				{isAuthorizedForForceLogin ?
					<CoAppStackItem style={{ justifyContent: "space-between" }}>
						<OrganizationManagementItemTitle>Browser Extension Force Login</OrganizationManagementItemTitle>
						<OrganizationManagementItemStatusText>
							{forceLogin ? "Enabled" : "Disabled"}
							<ThreeDotMenu options={[{ name: forceLogin ? "Disable" : "Enable", optionClickHandler: handleForceLoginSwitchToggle }]} />
						</OrganizationManagementItemStatusText>
					</CoAppStackItem>
					:
					null

				}
			</CoAppStack>
			<Dialog open={killSwitchIsToggled} onClose={handleKillSwitchToggle} sx={{ overflow: "hidden" }} fullWidth={true} maxWidth={"md"}>
				{killSwitch ? (
					<React.Fragment>
						<DialogTitle>Are you sure you want to deactivate the Browser Extension Kill Switch?</DialogTitle>
						<DialogContent>
							<KillSwitchDialogContent>
								<PriorityHigh sx={{ color: "red" }} />
								<CoAppH4>This will enable the browser extension across your entire organization.</CoAppH4>
								<PriorityHigh sx={{ color: "red" }} />
							</KillSwitchDialogContent>

						</DialogContent>
						<DialogActions>
							<IconButton sx={{ color: "#2FBC70" }} onClick={handleKillSwitchToggle}><Cancel /></IconButton>
							<IconButton sx={{ color: "#2FBC70" }} onClick={confirmKillSwitchToggle}><Check /></IconButton>
						</DialogActions>
					</React.Fragment>
				) : (
					<React.Fragment>
						<DialogTitle>Are you sure you want to activate the Browser Extension Kill Switch?</DialogTitle>
						<DialogContent>
							<KillSwitchDialogContent>
								<PriorityHigh sx={{ color: "red" }} />
								<CoAppH4>This will disable the browser extension across your entire organization.</CoAppH4>
								<PriorityHigh sx={{ color: "red" }} />
							</KillSwitchDialogContent>

						</DialogContent>
						<DialogActions>
							<IconButton sx={{ color: "#2FBC70" }} onClick={handleKillSwitchToggle}><Cancel /></IconButton>
							<IconButton sx={{ color: "#2FBC70" }} onClick={confirmKillSwitchToggle}><Check /></IconButton>
						</DialogActions>
					</React.Fragment>
				)}
			</Dialog>

			<Dialog open={forceLoginIsToggled} onClose={handleKillSwitchToggle} sx={{ overflow: "hidden" }} fullWidth={true} maxWidth={"md"}>
				{organizationForceLogin ? (
					<React.Fragment>
						<DialogTitle>Are you sure you want to deactivate Force Login?</DialogTitle>
						<DialogContent>
							<KillSwitchDialogContent>
								<PriorityHigh sx={{ color: "red" }} />
								<CoAppH4>This will enable users to work without the Browser Extension active.</CoAppH4>
								<PriorityHigh sx={{ color: "red" }} />
							</KillSwitchDialogContent>

						</DialogContent>
						<DialogActions>
							<IconButton sx={{ color: "#2FBC70" }} onClick={handleForceLoginSwitchToggle}><Cancel /></IconButton>
							<IconButton sx={{ color: "#2FBC70" }} onClick={confirmForceLoginToggle}><Check /></IconButton>
						</DialogActions>
					</React.Fragment>
				) : (
					<React.Fragment>
						<DialogTitle>Are you sure you want to activate the Force Login?</DialogTitle>
						<DialogContent>
							<KillSwitchDialogContent>
								<PriorityHigh sx={{ color: "red" }} />
								<CoAppH4>This will force all users to authenticate in the Chrome Extension.</CoAppH4>
								<PriorityHigh sx={{ color: "red" }} />
							</KillSwitchDialogContent>

						</DialogContent>
						<DialogActions>
							<IconButton sx={{ color: "#2FBC70" }} onClick={handleForceLoginSwitchToggle}><Cancel /></IconButton>
							<IconButton sx={{ color: "#2FBC70" }} onClick={confirmForceLoginToggle}><Check /></IconButton>
						</DialogActions>
					</React.Fragment>
				)}
			</Dialog>
			<ConfirmationDialog dialogDetails={confirmationDialogContent} />
		</CoAppMainContainer >
	);
}