import { Fragment, useState } from "react";
import { Alert, Autocomplete, Dialog } from "@mui/material";

import { CoAppDialogTextField } from "../global/styled/global.styled";

export default function TagInput(props) {

	const [tagAlertOpen, setTagAlertOpen] = useState(false);

	/**
	 * Listen for any change in tag field.
	 * 1. Validate the new tag is not over 20 characters, pop alert and clear text if so.
	 * 2. Inhibit MUI"s Autocomplete from removing a selected tag if it is selected again, while still allowing removal.
	 * 3. If checks are passed, add tag to redux state.
	 */
	const handleChange = (e, newValue) => {
		if (newValue.length !== 0) {
			if (newValue[newValue.length - 1].length > 20) {
				setTagAlertOpen(true);
				return;
			}
		}
		if (props.tags.includes(newValue[newValue.length - 1]) && newValue.length > props.tags.length) {
			return;
		} else {
			props.createTags(newValue);
		}
	};

	const handleTagAlertToggle = () => {
		tagAlertOpen ? setTagAlertOpen(false) : setTagAlertOpen(true);
	};

	return (
		<Fragment>
			<Autocomplete
				multiple
				freeSolo
				isOptionEqualToValue={(option, value) => option.value === value}
				onChange={handleChange}
				value={props.tags}
				id="tags-standard"
				options={props.tagsInCurrentOrg.map(tag => tag.name)}
				getOptionLabel={(option) => option}
				renderInput={(params) => (
					<CoAppDialogTextField
						{...params}
						variant="standard"
						label="Tags"
						helperText="Select from existing tags or create your own by typing and pressing ' ENTER' when finished."
					/>
				)}

			/>
			<Dialog onClose={handleTagAlertToggle} open={tagAlertOpen}>
				<Alert severity="error">Tags must be 20 characters or less.</Alert>
			</Dialog>
		</Fragment>
	);
}
