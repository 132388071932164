import { Cancel } from "@mui/icons-material";
import { Card, CardMedia, Popover, styled } from "@mui/material";

export const RuleLocationCardStructure = styled(Card)(({ theme, selectedCard }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	width: "250px",
	height: "200px",
	padding: "10px",
	margin: "15px",
	border: "1px solid #A9A9A9",
	borderRadius: theme.spacing(1),
	cursor: "pointer",
	"&:focus-within": {
		border: "solid 2px #2FBD70"
	},
	boxShadow: selectedCard ? "0 4px 40px #2FBD70" : "none"

}));

export const RuleLocationCardTitle = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "1rem",
	fontWeight: "700",
	color: "#7482A0",
	":hover": {
		textDecoration: "underline"
	}
}));

export const RuleLocationCardDesc = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "0.75rem",
	fontWeight: "500",
	color: "#7482A0",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	overflow: "hidden"
}));

export const RuleLocationPopoverDesc = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "0.75rem",
	fontWeight: "500",
	color: "#7482A0",
	maxWidth: "700px",
	whiteSpace: "pre-wrap",
	marginTop: "10px",
	[theme.breakpoints.down(1700)]: {
		maxWidth: "400px"
	}
}));

export const RuleLocationPopoverImage = styled("img")(({ theme }) => ({
	backgroundColor: "#FFFFFF",
	border: "solid 1px #DCDCDC",
	padding: "25px",
	borderRadius: theme.spacing(0.75),
	width: "800px",
	height: "650px",
	[theme.breakpoints.down(1700)]: {
		maxWidth: "500px",
		height: "400px"
	}
}));

export const RuleLocationCancel = styled(Cancel)(({ theme }) => ({
	float: "right",
	color: "#2FBD70",
	margin: "2px",
	":hover": {
		backgroundColor: "grey",
		borderRadius: "50%"
	}
}));

export const RuleLocationCardPreview = styled(CardMedia)(({ theme, hasTriggers }) => ({
	...theme.typography.body2,
	maxHeight: "150px",
	marginBottom: "10px",
	border: hasTriggers ? "solid 3px #2FBD70" : "none",
	marginLeft: hasTriggers ? "-3px" : "none",
	borderRadius: theme.spacing(1)
}));

export const RuleLocationPopover = styled(Popover)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
}));

export const RuleLocationPopoverContent = styled("div")(({ theme }) => ({
	padding: "25px"
}));