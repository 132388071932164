import { Clear, Delete } from "@mui/icons-material";
import {
	Dialog, DialogActions, DialogContent, DialogTitle, Divider,
	FormControl, FormHelperText, IconButton, InputLabel, MenuItem
} from "@mui/material";

import { CoAppButtonGreenBg, CoAppDialogTextField, CoAppInputLabel, CoAppSelect } from "../../global/styled/global.styled";


export default function TriggerDialog(props) {
	return (
		<Dialog open={props.openDialogFlag} onClose={props.cancelDialogHandler}>

			<DialogActions sx={{ justifyContent: "space-between" }}>
				<DialogTitle sx={{ fontWeight: "700" }}>{props.openAsEditFlag ? "Edit Trigger" : "Add Trigger"}</DialogTitle>
				<IconButton sx={{ color: "#2FBC70" }} onClick={props.cancelHandler}><Clear /></IconButton>
			</DialogActions>

			<Divider sx={{ width: "100%" }} />

			<DialogContent sx={{ width: "325px" }}>
				<InputLabel id="triggerName"></InputLabel>
				<CoAppDialogTextField
					error={props.triggerNameValueError !== ""}
					fullWidth
					helperText={props.triggerNameValueError}
					inputProps={{ maxLength: 255 }}
					label="Name this trigger"
					labelId="triggerName"
					onChange={props.triggerNameInputHandler}
					sx={{ margin: 0 }}
					type='text'
					variant="outlined"
					value={props.nameOfTrigger}
				/>

				<FormControl sx={{ display: "block", marginTop: "10px" }} error={props.triggerFieldValueError !== ""}>
					<CoAppInputLabel id="triggerField">Field</CoAppInputLabel>
					<CoAppSelect
						labelId="triggerField"
						label="Field"
						onChange={props.fieldDropdownSelectionChangeHandler}
						sx={{ width: "100%" }}
						value={props.nameOfTriggerField}
					>
						{
							props.allTriggerFields.map(item =>
								<MenuItem key={item.id} value={JSON.stringify(item)}>
									{item.name}
								</MenuItem>
							)
						}
					</CoAppSelect>
					<FormHelperText>{props.triggerFieldValueError}</FormHelperText>
				</FormControl>
			</DialogContent>

			<Divider sx={{ width: "100%" }} />

			<DialogActions sx={{ justifyContent: "space-between" }}>
				<IconButton sx={{ color: "#2FBC70" }} onClick={props.openAsEditFlag ? props.deleteTriggerHandler : props.cancelDialogHandler}><Delete /></IconButton>
				<CoAppButtonGreenBg variant='outlined' disabled={props.formHasErrors} sx={{ marginTop: "10px" }}
					onClick={props.openAsEditFlag ? props.saveEditHandler : props.saveHandler}>SAVE</CoAppButtonGreenBg>
			</DialogActions>

		</Dialog>
	);
}