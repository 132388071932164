import { useState } from "react";
import { CopyAll, Delete, Edit, Visibility } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function ThreeDotMenu({ options }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<IconButton
				aria-label="more"
				id="long-button"
				aria-controls={open ? "three-dot-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="three-dot-menu"
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				onClose={handleClose}
				onClick={handleClose}
				anchorEl={anchorEl}
				open={open}
			>
				{options.map((option) => (
					<MenuItem key={option.name} onClick={option.optionClickHandler}>
						{ option.name.includes("Edit") ? <Edit sx={{marginRight: "5px", fontSize: "16px" }}/> : null } 
						{ option.name.includes("Delete") || option.name.includes("Remove") ? <Delete sx={{marginRight: "5px", fontSize: "16px" }} /> : null }
						{ option.name.includes("Duplicate") ? <CopyAll sx={{marginRight: "5px", fontSize: "16px" }} /> : null }
						{ option.name.includes("View") ? <Visibility sx={{marginRight: "5px", fontSize: "16px" }} /> : null }
						{option.name}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}