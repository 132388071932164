import { Divider, Grid, styled, Typography } from "@mui/material";

export const RuleLibraryDivider = styled(Divider)(({ theme }) => ({
	[theme.breakpoints.up(1700)]: {
		marginLeft: "0px",
		width: "92%"
	},
	width: "93.5%",
	marginLeft: "25px"
}));

export const RuleLibraryFilterContainer = styled(Grid)(({ theme }) => ({
	marginTop: "10px",
	marginLeft: "20px",
	marginRight: "25px",
	[theme.breakpoints.up(1700)]: {
		marginLeft: "45px",
		marginRight: "77px"
	}
}));

export const RuleLibraryPaginationContainer = styled("div")(({ theme }) => ({
	display: "flex",
	marginLeft: "375px",
	[theme.breakpoints.up(1700)]: {
		marginLeft: "475px"
	},
	padding: "15px"
}));

export const RuleLibraryTotalRowsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	padding: "15px"
}));

export const RuleLibraryPaginationTotalRows = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	padding: "5px"
}));
