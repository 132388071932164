import { styled } from "@mui/material";

export const PermissionCategoryTitle = styled("div")(() => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	margin: "30px 30px 15px 20px"
}));

export const PermissionNameDescContainer = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
}));

export const RoleTitle = styled("div")(() => ({
	fontSize: "1rem",
	marginLeft: "10px",
	fontWeight: "600"
}));

export const RoleSubtitle = styled("div")(() => ({
	color: "#A2A2A2",
	fontSize: ".75rem",
	marginLeft: "10px",
	fontWeight: "400"
}));