import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { CircularProgress, Container, FormControlLabel, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import { selectOrganizationId } from "../../../redux/userSlice";
import ConfirmationDialog from "../../global/components/confirmation-dialog";
import DataGridCustomNoRowsOverlay from "../../global/components/datagrid-custom-norows-overlay";
import SearchInput from "../../global/components/search-input";
import { CoAppDataGrid, CoAppGroupList, CoAppH2, CoAppMainContainer, CoAppOpenItemRightArrow, CoAppStatusToggle } from "../../global/styled/global.styled";
import { SubMenuContainer } from "../styled/settings.styled";

export default function PluginManagement() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const authHeader = useAuthHeader();
	const organizationId = useSelector(selectOrganizationId);
	const [pluginsLoaded, setPlugingsLoaded] = useState(false);
	const [dialogDetails, setDialogDetails] = useState({ dialogOpen: false });
	const [pluginSearchInput, setPluginSearchInput] = useState("");

	const [plugins, setPlugins] = useState([]);
	
	const columns = [
		{
			field: "name",
			headerName: "NAME",
			headerClassName: "data-grid-header",
			width: 200,
			editable: false,
		},
		{
			field: "groups",
			headerName: "GROUPS",
			headerClassName: "data-grid-header",
			width: 200,
			editable: false,
			renderCell: (params) => (
				<div style={{ marginTop: "7px" }}>
					{params.value && params.value.length === 0 ? "" : params.value.length}
					{params.value.length > 1 ? " Groups" : params.value.length === 0 ? "" : " Group"}
					<CoAppGroupList title={params.value.join(", ")} >{params.value.join(", ")}</CoAppGroupList>
				</div>
			)
		},
		{
			field: "active",
			headerName: "STATUS",
			headerClassName: "data-grid-header",
			width: 200,
			editable: false,
			renderCell: (params) => (
				<FormControlLabel
					sx={{ pointerEvents: "none" }}
					label={<Typography variant="body3" sx={{ fontSize: "12px", pt: "2px", pl: "2px" }}>{params.value ? "Active" : "Inactive"}</Typography>}
					control={
						<CoAppStatusToggle
							checked={params.value}
							onChange={() => handleOpenConfirmDialog(params.value, params.row.name, params.row.id, params.row.platformId)}
							size="small"
						/>
					}
				/>
			)
		},
		{
			field: "actions",
			type: "actions",
			width: 50,
			resizable: false,
			renderCell: (params) => (
				<CoAppOpenItemRightArrow onClick={() => handleOpenPlugin(params.row)}>
					<KeyboardArrowRight />
				</CoAppOpenItemRightArrow>
			)
		}
	];

	const handlePluginStatusToggleChange = (pluginId, platformId, state) => {
		axios.put(apiRoutes.togglePluginStatusForOrganization(organizationId, platformId, pluginId), {}, { headers: authHeader })
			.then(() => {
				setDialogDetails({ dialogOpen: false });
				initPluginManagement();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(state ? messages.PLUGIN_DISABLED_SUCCESS_MSG : messages.PLUGIN_ENABLED_SUCCESS_MSG));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(state ? messages.PLUGIN_DISABLED_ERROR_MSG : messages.PLUGIN_ENABLED_ERROR_MSG));
			});
	};

	const handleOpenConfirmDialog = (state, pluginName, pluginId, platformId) => {
		setDialogDetails({
			dialogOpen: true,
			confirmationTitle: messages.PLUGIN_TOGGLE_CONFIRMATION_MSG(state, pluginName),
			confirmClickHandler: () => handlePluginStatusToggleChange(pluginId, platformId, state),
			cancelClickHandler: () => setDialogDetails({})
		});
	};

	const handleOpenPlugin = (plugin) => {
		navigate(pages.pluginGroups(plugin.id), { state: { plugin: plugin } });
	};

	const initPluginManagement = () => {
		let pluginQueryObject = {
			name: pluginSearchInput
		};

		axios.get(apiRoutes.getPlugins(organizationId),
			{
				headers: authHeader,
				params: pluginQueryObject,
				paramsSerializer: { indexes: null }
			}
		)
			.then((response) => {
				let platforms = response.data;
				let plugins = [];
				platforms.forEach(platform => {
					if (!platform.plugins) {
						return;
					}
					platform.plugins.forEach(plugin => {
						plugins.push({
							id: plugin.id,
							name: plugin.name,
							groups: plugin.groups.map(group => group.name),
							active: plugin.active,
							platformId: platform.id
						});
					});
				});

				setPlugins(plugins);
				setPlugingsLoaded(true);
			})
			.catch((error) => {
				console.error(error);
			});

	};

	useEffect(() => {
		initPluginManagement();
	}, [pluginSearchInput]);

	if (pluginsLoaded) {
		return (
			<CoAppMainContainer sx={{ p: 2, width: "95%" }}>
				<SubMenuContainer>
					<CoAppH2 sx={{ float: "left" }}>Plugins</CoAppH2>

					<SearchInput
						customStyles={{ marginTop: "-3px", float: "right" }}
						placeholder="Search plugins"
						onChangeHandler={setPluginSearchInput}
					/>
				</SubMenuContainer>

				{
					plugins.length === 0 ? (
						<DataGridCustomNoRowsOverlay message={pluginSearchInput === "" ? "No Plugins Yet!" : "No Plugins Found!"} />
					)
						:
						<Grid container spacing={1} justifyContent="stretch">
							<CoAppDataGrid
								columnHeaderHeight={45}
								columns={columns}
								rows={plugins}
								disableRowSelectionOnClick
								getRowId={(row) => row.id}
								initialState={{
									pagination: {
										paginationModel: { pageSize: 25, page: 0 },
									},
									pinnedColumns: { right: ["actions"] },
									sorting: {
										sortModel: [{ field: "name", sort: "asc" }],
									},
								}}
								pagination
								getRowClassName={(params) =>
									params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
								}
							/>
						</Grid>
				}
				<ConfirmationDialog dialogDetails={dialogDetails} />
			</CoAppMainContainer >
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}