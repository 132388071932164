const messages = {
	CONTACT_ADMIN_MSG: "Please contact your organization's administrator for assistance.",
	EMAIL_ALREADY_EXISTS_ERROR_MSG: "This email is already taken.",
	EMAIL_OR_PASSWORD_INCORRECT_ERROR_MSG: "Email and/or password incorrect, please try again.",
	FIELD_IS_REQUIRED_ERROR_MSG: "This field is required.",
	FORMULA_MISCONFIGURATION_MSG: "Incomplete trigger/formula configurations have been detected that invalidate this rule formula, please select an option below to proceed.",
	GROUP_NAME_ALREADY_EXISTS_ERROR_MSG: "A group with this name already exists in your organization.",
	GROUP_NAME_UPDATED_SUCCESS_MSG: "Group name was updated successfully.",
	GROUP_NAME_UPDATE_ERROR_MSG: "Error during group update occured, please try again later.",
	GROUP_ADD_TO_PLUGIN_SUCCESS_MSG: (num, pluginName) => `Group${num > 1 ? "s" : ""} ${num > 1 ? "were" : "was"} added to the ${pluginName} plugin successfully.`,
	GROUP_ADD_TO_PLUGIN_ERROR_MSG: (num, pluginName) => `Error while adding group${num > 1 ? "s" : ""} to the ${pluginName} plugin, please try again later.`,
	GROUP_CREATED_SUCCESS_MSG: "Group was created successfully.",
	GROUP_ADDITION_SUCCESS_MSG: "Plugins were added to the group successfully.",
	GROUP_ADDITION_ERROR_MSG: "Error during plugin addition occured, please try again later.",
	GROUP_CREATION_ERROR_MSG: "Error during group creation occured, please try again later.",
	GROUP_DELETION_ERROR_MSG: "Error during group deletion occured, please try again later.",
	GROUP_DELETED_SUCCESS_MSG: "Group was deleted successfully.",
	GROUP_REMOVAL_SUCCESS_MSG: "Group was removed successfully.",
	GROUP_REMOVAL_ERROR_MSG: "Error during group removal occured, please try again later.",
	GROUP_PLUGIN_TOGGLE_SUCCESS_MSG: "Plugin status was toggled successfully.",
	GROUP_PLUGIN_TOGGLE_ERROR_MSG: "Error during plugin status toggle occured, please try again later.",
	IDP_GROUPS_ADDITION_SUCCESS_MSG: "IdP groups were added successfully.",
	IDP_GROUPS_ADDITION_ERROR_MSG: "Error during IdP group addition occured, please try again later.",
	INVALID_EMAIL_ERROR_MSG: "Email address is invalid.",
	INVALID_PHONE_ERROR_MSG: "Phone number is invalid.",
	LOCATION_LARGE_PREVIEW_TOOLTIP: "Click to view a larger preview of this location.",
	PAGE_ACCESS_DENIED_MSG: "Page can't be accessed.",
	PAGE_PERMISSION_DENIED_MSG: "You do not have permission to access this page.",
	PERMISSION_ADDED_ERROR_MSG: "Permission could not be added, please try again later.",
	PERMISSION_ADDED_SUCCESS_MSG: "Permission was added succesfully",
	PERMISSION_REMOVED_ERROR_MSG: "Permission could not be removed, please try again later.",
	PERMISSION_REMOVED_SUCCESS_MSG: "Permission was removed succesfully",
	PLUGIN_REMOVED_SUCCESS_MSG: "Plugin was removed successfully.",
	PLUGIN_REMOVED_ERROR_MSG: "Error during plugin removal occured, please try again later.",
	PLUGIN_DISABLED_SUCCESS_MSG: "Plugin was disabled successfully.",
	PLUGIN_DISABLED_ERROR_MSG: "Error during plugin disablement occured, please try again later.",
	PLUGIN_ENABLED_SUCCESS_MSG: "Plugin was enabled successfully.",
	PLUGIN_ENABLED_ERROR_MSG: "Error during plugin enablement occured, please try again later.",
	PLUGIN_TOGGLE_CONFIRMATION_MSG: (state, pluginName) => `Are you sure you want to ${state ? "disable" : "enable"} the ${pluginName} plugin for your organization?`,
	ROLE_CREATED_SUCCESS_MSG: "Role was created successfully.",
	ROLE_CREATION_ERROR_MSG: "Error during role creation occured, please try again later.",
	ROLE_DELETION_CONFIRMATION_MSG: "Are you sure you want to delete this role?",
	ROLE_DELETION_ERROR_MSG: "Error during role deletion, please try again later.",
	ROLE_DELETION_SUCCESS_MSG: "Role was deleted successfully.",
	ROLE_DUPLICATION_CONFIRMATION_MSG: "Are you sure you want to duplicate this role?",
	ROLE_DUPLICATION_ERROR_MSG: "Error while duplicating role, please try again later.",
	ROLE_DUPLICATION_SUCCESS_MSG: "Role was duplicated successfully",
	ROLE_NAME_UPDATED_MSG: "Role was updated successfully.",
	ROLE_NAME_UPDATE_ERROR_MSG: "Error updating role. Please try again later.",
	RULE_ADDED_SUCCESS_MSG: "Rule was added successfully.",
	RULE_ADDED_ERROR_MSG: "Error during rule addition occured, please try again later.",
	RULE_REMOVED_SUCCESS_MSG: "Rule was removed successfully.",
	RULE_REMOVED_ERROR_MSG: "Error during plugin removal occured, please try again later.",
	SCIM_SETUP_MESSAGE_ONE: "Enabling SCIM removes the ability to manually configure users in CoApp. Instead, user management must occur exclusively through groups in your Identity Provider (IDP).",
	SCIM_SETUP_MESSAGE_TWO: "Existing groups that are not mapped to an IDP group will remain, however, all existing users assigned to those groups will be unassociated once you complete SCIM setup.",
	SCIM_SETUP_MESSAGE_THREE: "Additionally, any users that are in CoApp but don't come over with a group assignment from your IDP will be deleted.",
	SCIM_SETUP_MESSAGE_FOUR: "It's important to note that after setting up SCIM, users may be required to reauthenticate.",
	SCIM_SETUP_MESSAGE_FIVE: "We encourage you to create an \"Admin\" specific group for individuals who require elevated \"superuser\" permissions.",
	TAG_DESELECTION_TOOLTIP_INFO: "Hold \"CTRL\" while clicking the tag icon to filter on multiple tags and to deselect your selected tag(s) one at a time.",
	TAG_DESELECTION_TOOLTIP_ON_HOVER: "Trying to deselect this tag? Hold \"CTRL\" while clicking the tag icon to deselect.",
	TRIGGER_CONFIGURATION_TOOLTIP: "Select a location to define variables for that page. Highlighted locations are currently selected for trigger configuration. Locations images with borders have at least one trigger configured.",
	TRIGGER_NO_LOCATION_SELECTED_ERROR_MSG: "Please select a location to configure triggers for.",
	TOGGLE_DRAFT_RULE_ERROR_MSG: "You cannot activate a rule that is in a draft state, please finish configuring this rule before taking this action.",
	TOO_MANY_CHARACTERS_ERROR_MSG: (limit, overage) => `Character limit is ${limit}, you are over by ${overage} character(s).`,
	USER_CREATION_ERROR_MSG: "Error during user creation occured, please try again later.",
	USER_CREATION_SUCCESS_MSG: "User was created successfully.",
	USER_DELETION_SUCCESS_MSG: "User was deleted successfully.",
	USER_DELETION_ERROR_MSG: "Error during user deletion occured, please try again later.",
	USER_REMOVED_SUCCESS_MSG: "User was removed successfully.",
	USER_REMOVED_ERROR_MSG: "Error during user removal occured, please try again later.",
	USER_UPDATE_SUCCESS_MSG: "User was updated successfully.",
	USER_UPDATE_ERROR_MSG: "Error during user update occured, please try again later.",
	USERS_ADDED_SUCCESS_MSG: "Users were added successfully.",
	USERS_ADDED_ERROR_MSG: "Error during user addition occured, please try again later.",
	USER_TOGGLE_CONFIRMATION_MSG: (state, name) => `Are you sure you want to ${state === "false" ? "disable" : "enable"} ${name}?`,
	USER_TOGGLE_SUCCESS_MSG: "User status was toggled successfully.",
	USER_TOGGLE_ERROR_MSG: "Error during user status toggle occured, please try again later.",
	RULE_ACTIVATION_ERROR_MSG: "Error activating rule, please try again later.",
	RULE_ACTIVATION_SUCCESS_MSG: "Rule was activated successfully.",
	RULE_DEACTIVATION_ERROR_MSG: "Error disabling rule, please try again later.",
	RULE_DEACTIVATION_SUCCESS_MSG: "Rule was deactivated successfully.",
	RULE_CHANGES_DETECTED_MSG: "Changes have been detected on this step, please select an option below to proceed.",
	RULE_COPY_CONFIRMATION_MSG: "Are you sure you want to copy this rule?",
	RULE_COPY_ERROR_MSG: "Error copying rule, please try again later.",
	RULE_COPY_SUCCESS_MSG: "Rule was copied successfully.",
	RULE_DELETION_CONFIRMATION_MSG: "Are you sure you want to delete this rule?",
	RULE_DELETION_ERROR_MSG: "Error during rule deletion occured, please try again later.",
	RULE_DELETION_SUCCESS_MSG: "Rule was deleted successfully.",
	RULE_FORMULA_REQUIRED_ERROR_MSG: "A formula cannot be empty, please configure your formula contents to proceed.",
	RULE_INVALID_FORMULA_ERROR: "Your formula is invalid, please review and fix the issue before proceeding.",
	RULE_LOCATION_REQUIRED_ERROR_MSG: "A location must be selected to proceed to reaction setup.",
	RULE_METADATA_ERROR_MSG: "The name and/or description of your rule are invalid, please resolve before proceeding.",
	RULE_NAME_UNIQUE_ERROR_MSG: "A rule with this name exists for your organization, rule names must be unique.",
	RULE_PARENTHESES_ERROR: (openings, closings) => `Your formula has more ${openings.length > closings.length ? "opening than closing parentheses" : "closing than opening parentheses"}, please review your formula.`,
	RULE_REACTION_REQUIRED_ERROR_MSG: "At least one reaction must be configured for the selected location to proceed.",
	RULE_RESTORE_ERROR_MSG: "Error during rule restoration occured, please try again later.",
	RULE_RESTORE_SUCCESS_MSG: "Rule was restored successfully.",
	RULE_STATE_TOOLTIP: "Green shadow indicates the rule is in an active state, yellow indicates the rule is inactive, and red indicates that the rule is a draft.",
	RULE_TRASHED_SUCCESS_MSG: "Rule was sent to trash successfully.",
	RULE_TRIGGER_REQUIRED_ERROR_MSG: "At least one trigger must be configured for this rule.",
	WORKSTATION_STATUS_TOGGLE_SUCCESS_MSG: "Workstation status was toggled successfully.",
	WORKSTATION_STATUS_TOGGLE_ERROR_MSG: "Error during workstation status toggle occured, please try again later.",
	WORKSTATION_TOGGLE_CONFIRMATION_MSG: (state, name) => `Are you sure you want to ${state === "false" ? "disable" : "enable"} ${name}?`,
};

export default messages;