import { styled } from "@mui/material";

export const GroupActionsComponent = styled("div")(() => ({
	display: "flex",
	color: "gray",
	"& svg": {
		padding: "5px",
		":hover": {
			backgroundColor: "lightgrey",
			borderRadius: "50%"

		}
	}
}));

export const UserNameCell = styled("div")(() => ({
	display: "flex",
	alignItems: "flex-start",
	flexWrap: "wrap",
	width: "100px",
}));
