import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Container, Stack, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import { selectOrganizationSsoAndScim } from "../../../redux/userSlice";
import ConfirmationDialog from "../../global/components/confirmation-dialog";
import DataGridCustomNoRowsOverlay from "../../global/components/datagrid-custom-norows-overlay";
import SearchInput from "../../global/components/search-input";
import ThreeDotMenu from "../../global/components/three-dot-menu";
import { CoAppButtonGreenBg, CoAppDataGrid, CoAppH2, CoAppMainContainer, CoAppStatusToggle } from "../../global/styled/global.styled";
import { SubMenuContainer } from "../styled/settings.styled";

import { UserNameCell } from "./styled/user-management.styled";
import UserCreationDialog from "./user-creation-dialog";
import UserDeletionDialog from "./user-deletion-dialog";

export default function UserManagement() {
	const authHeader = useAuthHeader();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [dialogDetails, setDialogDetails] = useState({ dialogOpen: false });
	const organizationSsoStatus = useSelector(selectOrganizationSsoAndScim);
	const [organizationUsersLoaded, setOrganizationUsersLoaded] = useState(false);
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [userBeingDeleted, setUserBeingDeleted] = useState({});
	const [userCreationDialogOpen, setUserCreationDialogOpen] = useState(false);
	const [userCreationEmail, setUserCreationEmail] = useState("");
	const [userCreationFirstName, setUserCreationFirstName] = useState("");
	const [userCreationLastName, setUserCreationLastName] = useState("");
	const [userCreationRole, setUserCreationRole] = useState("");
	const [userCreationErrorMessage, setUserCreationErrorMessage] = useState("");
	const [userDeletionDialogOpen, setUserDeletionDialogOpen] = useState(false);
	const [userSearchInput, setUserSearchInput] = useState("");
	const [userSeed, setUserSeed] = useState(1);

	const columns = [
		{
			field: "firstname",
			headerClassName: "data-grid-header",
			headerName: "USER",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<UserNameCell>
					<Typography sx={{ fontWeight: "600" }}>{params.row.firstname} {params.row.lastname}</Typography>
					<Typography sx={{ color: "grey", fontSize: ".75rem" }}>{params.row.email}</Typography>
				</UserNameCell>
			),
		},
		{
			field: "roles",
			headerClassName: "data-grid-header",
			headerName: "ROLES",
			editable: true,
			flex: 1,
			renderCell: (params) => (
				<>
					{params.value && params.value.length > 0 ?
						<UserNameCell>
							<Typography>{params.value.length} {params.value.length === 1 ? "role" : "roles"}</Typography>
							<Typography sx={{ color: "grey", fontSize: ".75rem", minWidth: "75px" }}>
								{params.value.map((group, index) => {
									return <span key={index}>{group.name}{index < params.value.length - 1 ? ", " : ""}</span>;
								})}
							</Typography>
						</UserNameCell>
						: null
					}
				</>
			),
		},
		{
			field: "groups",
			headerClassName: "data-grid-header",
			headerName: "GROUPS",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<>
					{params.value && params.value.length > 0 ?
						<UserNameCell>
							<Typography>{params.value.length} {params.value.length === 1 ? "group" : "groups"}</Typography>
							<Typography sx={{ color: "grey", fontSize: ".75rem" }}>
								{params.value.map((group, index) => {
									return <span key={index}>{group.name}{index < params.value.length - 1 ? ", " : ""}</span>;
								})}
							</Typography>
						</UserNameCell>
						: null
					}
				</>
			),
		},
		{
			field: "createdAt",
			headerClassName: "data-grid-header",
			headerName: "CREATED",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<div>
					<Typography sx={{ fontSize: ".8rem", color: "grey" }}>
						{params.value &&
							new Date(params.value).toLocaleString(undefined, {
								timeZone: "America/New_York",
								year: "numeric",
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
								second: "numeric",
								hour12: true,
							})}
					</Typography>
				</div>
			),
		},
		{
			field: "lastExtensionLogin",
			headerClassName: "data-grid-header",
			headerName: "LAST LOGIN",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<div>
					<Typography sx={{ fontSize: ".8rem", color: "grey" }}>
						{params.value &&
							new Date(params.value).toLocaleString(undefined, {
								timeZone: "America/New_York",
								year: "numeric",
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
								second: "numeric",
								hour12: true,
							})}
					</Typography>
				</div>
			),
		},
		...(organizationSsoStatus
			? [
				{
					field: "idpGroups",
					headerClassName: "data-grid-header",
					headerName: "IDPGROUPS",
					editable: false,
					flex: 1,
				},
			]
			: []),
		{
			field: "status",
			headerClassName: "data-grid-header",
			headerName: "STATUS",
			editable: false,
			flex: 0.5,
			renderCell: (params) => (
				<div>
					<CoAppStatusToggle
						checked={params.row.active}
						onChange={() => handleOpenConfirmDialog(params.row.id, params.row.firstname + " " + params.row.lastname, params.row.active === true ? "false" : "true")}
						disabled={organizationSsoStatus}
					/>
				</div>
			),
		},
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					{
						organizationSsoStatus ?
							<ThreeDotMenu
								options={[
									{
										name: "View User",
										optionClickHandler: () => {
											handleOpenUserEditPage({ params });
										},
									},
								]}
							/>
							:
							<ThreeDotMenu
								options={
									[
										{
											name: "Edit",
											optionClickHandler: () => {
												handleOpenUserEditPage({ params });
											},
										},
										{
											name: "Delete",
											optionClickHandler: () => {
												handleOpenUserDeletionDialog({ params });
											},
										},
									]
								}
							/>
					}
				</div>
			)
		}
	];

	const handleOpenConfirmDialog = (id, name, state) => {
		setDialogDetails({
			dialogOpen: true,
			confirmationTitle: messages.USER_TOGGLE_CONFIRMATION_MSG(state, name),
			confirmClickHandler: () => handleUserStatusToggle(id, state),
			cancelClickHandler: () => setDialogDetails({})
		});
	};

	const handleOpenUserEditPage = (rowObject) => {
		navigate(pages.profile + "/" + rowObject.params.id + "?from=users");
	};

	const handleCloseUserDeletionDialog = () => {
		setUserDeletionDialogOpen(false);
		setUserBeingDeleted({});
	};

	const handleOpenUserDeletionDialog = (rowObject) => {
		let userBeingDeleted = rowObject.params.row;
		setUserBeingDeleted(userBeingDeleted);
		setUserDeletionDialogOpen(true);
		setUserCreationErrorMessage("");
	};

	const handleUserStatusToggle = (id, state) => {
		let userJSON = {
			active: state
		};
		axios.put(apiRoutes.setUserActive + "/" + id, userJSON, {
			headers: authHeader
		}).then(() => {
			setDialogDetails({ dialogOpen: false });
			initUserManagement();
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.SUCCESS));
			dispatch(setMessage(messages.USER_TOGGLE_SUCCESS_MSG));
		})
			.catch((error) => {
				console.error(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.USER_TOGGLE_ERROR_MSG));
			});
	};

	const createUser = () => {
		if (!userCreationEmail || !userCreationRole) {
			setUserCreationErrorMessage("Please enter a valid email and role");
		} else {
			setUserCreationErrorMessage("");

			let userCreationObject = {
				newUserEmail: userCreationEmail,
				newUserFirstName: userCreationFirstName,
				newUserLastName: userCreationLastName,
				newUserRole: userCreationRole,
			};
			axios.post(apiRoutes.createUser, userCreationObject, {
				headers: authHeader,
			}).then(() => {
				toggleAddNewDialog();
				setUserSeed(Math.random());
				dispatch(setMessage(messages.USER_CREATION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			}).catch((err) => {
				console.error(err);
				dispatch(setMessage(err.response.data.message || messages.USER_CREATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
		}
	};

	const deleteUser = () => {
		axios.delete(apiRoutes.deleteUser + "/" + userBeingDeleted.id, {
			headers: authHeader
		}).then(() => {
			handleCloseUserDeletionDialog();
			setUserSeed(Math.random());
			dispatch(setMessage(messages.USER_DELETION_SUCCESS_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.SUCCESS));
		}).catch(err => {
			console.error(err);
			dispatch(setMessage(messages.USER_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
		});
	};

	const initUserManagement = () => {
		let userQueryObject = {
			email: userSearchInput ? userSearchInput : null,
		};

		axios.get(apiRoutes.getUsers, {
			headers: authHeader,
			params: userQueryObject,
			paramsSerializer: { indexes: null }
		}).then((res) => {
			setOrganizationUsers(res.data);
			setOrganizationUsersLoaded(true);
		}).catch((err) => {
			console.log(err);
		});
	};

	const toggleAddNewDialog = () => {
		setUserCreationDialogOpen(!userCreationDialogOpen);
	};

	useEffect(() => {
		initUserManagement();
	}, [userSeed, userSearchInput]);

	if (organizationUsersLoaded) {
		return (
			<CoAppMainContainer sx={{ p: 2, width: "95%" }}>
				<SubMenuContainer>
					<CoAppH2 sx={{ display: "inline", marginRight: "50px" }}>Users</CoAppH2>

					<SearchInput
						placeholder="Search users"
						onChangeHandler={setUserSearchInput} />

					{
						organizationSsoStatus ?
							<CoAppButtonGreenBg disabled sx={{ float: "right", minWidth: "120px" }}>ADD USER</CoAppButtonGreenBg>
							:
							<CoAppButtonGreenBg onClick={toggleAddNewDialog} sx={{ float: "right", minWidth: "120px" }}>ADD USER</CoAppButtonGreenBg>
					}
				</SubMenuContainer>

				{
					organizationUsers.length > 0 && (
						<Box sx={{ width: "100%" }}>
							<CoAppDataGrid
								columnHeaderHeight={45}
								columns={columns}
								disableRowSelectionOnClick
								getRowClassName={(params) =>
									params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
								}
								getRowId={(row) => row.id}
								initialState={{
									pagination: {
										paginationModel: { pageSize: 25, page: 0 },
									},
									pinnedColumns: { right: ["actions"] },
								}}
								pagination
								rows={organizationUsers}
							/>
						</Box>
					)
				}

				{
					organizationUsers.length === 0 && (
						<DataGridCustomNoRowsOverlay message="No users yet!" />
					)
				}

				<UserDeletionDialog
					deletionDialogOpen={userDeletionDialogOpen}
					deletionDialogCloseHandler={handleCloseUserDeletionDialog}
					deletedUser={userBeingDeleted}
					deleteUserHandler={deleteUser}
				/>

				<UserCreationDialog
					createUserHandler={createUser}
					creationDialogOpen={userCreationDialogOpen}
					creationDialogCloseHandler={toggleAddNewDialog}
					emailChangeHandler={(e) => setUserCreationEmail(e.target.value)}
					firstNameChangeHandler={(e) => setUserCreationFirstName(e.target.value)}
					lastNameChangeHandler={(e) => setUserCreationLastName(e.target.value)}
					roleChangeHandler={(e) => setUserCreationRole(e.target.value)}
					errorMessage={userCreationErrorMessage}
				/>
				<ConfirmationDialog dialogDetails={dialogDetails} />
			</CoAppMainContainer>

		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}