import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment, Tooltip } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messages from "../../../constants/messages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { selectTriggers } from "../../../redux/ruleWizardSlice";
import { CoAppH5 } from "../../global/styled/global.styled";
import WizardLocationPagination from "../locations/wizard-location-pagination";
import RuleLocationGrid from "../rules/rule-location-grid";
import { RuleWizardStepTitle, WizardLocationsContainer, WizardLocationsSearchField, WizardStep3ItemContainer } from "../styled/rule-wizard.styled";
import RuleTriggerSidebar from "../triggers/trigger-sidebar";

/**
 * Step 3 in Rule Creation Wizard
 * User creates triggers based on locations and fields in the UI
 * 1. User selects a Location for 1+ triggers
 * 2. User creates 1+ triggers in relation to the selected location
 * 3. User can select another location for more triggers...
 */
export default function WizardStep3(props) {
	let authHeader = useAuthHeader();

	// locations to populate grid for user to choose from
	const [locations, setLocations] = useState([]);
	const [locationsLoaded, setLocationsLoaded] = useState(false);

	// user can select 1 location at a time to build triggers
	const [locationSelected, setLocationSelected] = useState(false);
	const [location, setLocation] = useState({});

	const [triggerFields, setTriggerFields] = useState([]);

	const triggers = useSelector(selectTriggers);

	const [searchInput, setSearchInput] = useState("");

	/**
	 * Location pagination state. 
	 */
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	const handleSearchInputChange = (e) => {
		e.preventDefault();
		let searchValue = e.target.value;
		setSearchInput(searchValue);
		setLocationsLoaded(false);

		let queryObj = { page: currentPage };
		if (searchValue) {
			queryObj["name"] = searchValue;
		}

		axios.get(apiRoutes.getRuleLocationsForWizard, {
			headers: authHeader,
			params: queryObj, paramsSerializer: { indexes: null }
		}).then(res => {
			if (res.data.pageOfItems.length === 0) {
				setLocations([]);
				setLocationsLoaded(false);
			} else {
				setLocations(res.data.pageOfItems);
				setCurrentPage(parseInt(res.data.pager.currentPage));
				setTotalPages(res.data.pager.totalPages);
				setLocationsLoaded(true);
				initTriggerCount(res.data.pageOfItems);
			}
		}).catch(err => {
			console.error(err);
		});
	};

	const initLocations = async () => {
		await axios.get(apiRoutes.getRuleLocationsForWizard, {
			headers: authHeader,
			params: { page: currentPage, ruleId: props.editedId }
		}).then(res => {
			setCurrentPage(parseInt(res.data.pager.currentPage));
			setTotalPages(res.data.pager.totalPages);
			setLocations(res.data.pageOfItems);
			setLocationsLoaded(true);
			initTriggerCount(res.data.pageOfItems);
		}).catch(err => {
			console.log(err);
		});
	};

	/**
	 * initTriggerCount after editing triggers and returning to locations
	 * If the location contains a trigger, display the location with fancy border
	 * 
	 */
	const initTriggerCount = (loadedLocations) => {
		let locationArr = [];
		if (loadedLocations && loadedLocations.length) {
			locationArr = loadedLocations;
		} else {
			locationArr = locations;
		}
		if (locationArr.length > 0 && triggers && triggers.length > 0) {
			let filteredArr = locationArr;
			for (let trigger of triggers) {
				for (let location of locationArr) {
					if (trigger.location.id === location.id && !location.triggerCount) {
						let locationUpdated = JSON.parse(JSON.stringify(location));
						locationUpdated.triggerCount = 1;
						var index = locationArr.findIndex(el => el.id === location.id);
						filteredArr = filteredArr.filter(function (el) { return el.id !== location.id; });
						filteredArr.splice(index, 0, locationUpdated);
						filteredArr.join();
						setLocations(filteredArr);
					}
				}
			}
		} else if (triggers.length === 0 && locationArr.length > 0) {
			let updatedLocations = locationArr.map(location => {
				let tempLoc = location;
				if ("triggerCount" in tempLoc) {
					delete tempLoc.triggerCount;
				}
				return tempLoc;
			});
			setLocations(updatedLocations);
		}
	};

	const setSelectedLocation = (e) => {
		for (let location of locations) {
			if (location.id === parseInt(e.target.id)) {
				setLocation(location);
				initTriggerFields(location);
			}
		}
		setLocationSelected(true);
	};

	const initTriggerFields = (selectedLocation) => {
		axios.get(apiRoutes.ruleFields, {
			headers: authHeader,
			params: { locationId: selectedLocation.id }
		}).then(res => {
			setTriggerFields(res.data);
		}).catch(err => {
			console.log(err);
		});
	};

	useEffect(() => {
		initLocations();
	}, [locationSelected, triggers]);

	return (
		<WizardLocationsContainer>
			<RuleWizardStepTitle sx={{ margin: "auto", width: "100%" }}>
				Define Variables to Trigger Reactions
				<Tooltip title={messages.TRIGGER_CONFIGURATION_TOOLTIP} placement={"right-end"}>
					<Info sx={{ paddingLeft: "15px" }} fontSize="small" />
				</Tooltip>
			</RuleWizardStepTitle>
			<>
				<Grid sx={{ display: "inline-flex" }}>
					<WizardStep3ItemContainer item>
						<WizardLocationsSearchField
							placeholder="Filter locations..."
							value={searchInput}
							onChange={handleSearchInputChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							inputProps={{ maxLength: 500 }}
						/>
						{
							!locationsLoaded && locations.length !== 0 ?
								<CoAppH5 variant="h4" sx={{ margin: "auto" }}>Loading locations...</CoAppH5>
								:
								null
						}
						<RuleLocationGrid
							selectedCard={location}
							ruleLocationsLoaded={locationsLoaded}
							ruleLocations={locations}
							locationSelectionHandler={setSelectedLocation}
							trigger={true}
						/>
						<WizardLocationPagination
							currentPage={currentPage}
							currentPageSetter={setCurrentPage}
							totalPages={totalPages}
							totalPagesSetter={setTotalPages}
							currentSearchInput={searchInput}
							ruleLocationsSetter={setLocations}
							ruleLocationsLoadedSetter={setLocationsLoaded}
							isTriggerLocations={true}
							initTriggerCount={initTriggerCount}
						/>
					</WizardStep3ItemContainer>
					<RuleTriggerSidebar
						location={location}
						triggerFields={triggerFields}
						handleStepDataChange={props.handleStepDataChange}
						editedId={props.editedId}
						handleTriggerFieldLoad={initTriggerFields}
						handleSaveChanges={props.handleSaveChanges}
					/>
				</Grid>
			</>

		</WizardLocationsContainer>
	);
}