import { CopyAll, Delete, Edit } from "@mui/icons-material";
import { Grid, styled, Switch, Typography } from "@mui/material";


export const RuleCardStructure = styled("div")(({ theme, shadowColor }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "left",
	color: theme.palette.text.secondary,
	minHeight: "175px",
	minWidth: "450px",
	boxShadow: `0 4px 8px ${shadowColor}`,
	overflow: "hidden",
	borderRadius: "5px",
	position: "relative",
}));

export const RuleCardGroup = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#EAF8F1" : "#EAF8F1",
	...theme.typography.body2,
	padding: theme.spacing(0.75),
	textAlign: "left",
	fontSize: "0.80rem",
	fontWeight: "700",
	color: "#2FBD70",
	minWidth: "50px",
	float: "left",
	margin: "1px",
	borderRadius: "5px",
	marginTop: "5px",
	alignItems: "center",
	display: "flex",
	[theme.breakpoints.down(1700)]: {
		fontSize: "0.60rem",
	}
}));

export const RuleCardTagContainer = styled("div")(({ theme }) => ({
	paddingLeft: "7px",
	display: "inline-block",
	width: "100%",
	whiteSpace: "nowrap"
}));

export const RuleCardPopoverGrid = styled(Grid)(({ theme }) => ({
	maxWidth: "450px",
	display: "inline-flex",
	padding: "5px",
	marginTop: "-30px",
	marginLeft: "-130px",
	[theme.breakpoints.down(1700)]: {
		maxWidth: "300px"
	}
}));

export const RuleCardTag = styled("span")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#E3E6EC" : "#E3E6EC",
	...theme.typography.body2,
	padding: theme.spacing(0.75),
	textAlign: "left",
	fontSize: "0.80rem",
	fontWeight: "700",
	color: "#7482A0",
	minWidth: "50px",
	height: "15px",
	float: "left",
	marginRight: "2px",
	marginBottom: "-10px",
	borderRadius: "5px",
	alignItems: "center",
	display: "inline-flex",
}));

export const RuleCardStatusToggle = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2FBD70" : "#2FBD70",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

export const RuleCardTitle = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	paddingTop: theme.spacing(1),
	paddingLeft: theme.spacing(1),
	textAlign: "left",
	fontSize: "1.3rem",
	fontWeight: "700",
	color: theme.palette.text.secondary,
	maxWidth: "100%",
	float: "left",
	marginRight: "10px",
	cursor: "default"
}));

export const RuleCardText = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "left",
	fontSize: "0.95rem",
	color: theme.palette.text.secondary,
	width: "95%",
	minHeight: "60px",
	display: "flex",
}));

export const RuleCardStatus = styled("div")(({ theme }) => ({
	fontStyle: "italic",
	float: "right",
	marginTop: "10px"
}));

export const RuleCardActionsDiv = styled("div")(({ theme }) => ({
	float: "right",
	marginLeft: "5px",
	"&:hover": {
		cursor: "pointer"
	},
}));

export const RuleCardActionsCopyButton = styled(CopyAll)(({ theme }) => ({
	paddingRight: "20px",
	paddingTop: theme.spacing(1),
	transform: "scale(1.2)",
}));

export const RuleCardActionsEditButton = styled(Edit)(({ theme }) => ({
	paddingRight: "20px",
	transform: "scale(1.2)",
}));

export const RuleCardActionsDeleteButton = styled(Delete)(({ theme }) => ({
	transform: "scale(1.2)",
}));

export const RuleCardDialogContent = styled("b")(({ theme }) => ({
	fontFamily: "sans-serif"
}));

export const RuleCardGroupsCount = styled(Typography)(({ theme }) => ({
	color: "#2FBD70",
	display: "inline",
	marginLeft: "2px",
	fontWeight: 550,
	fontSize: "11px",
	position: "absolute",
	top: "157px",
	paddingLeft: "5px"
}));

export const RuleCardTagsCount = styled(Typography)(({ theme }) => ({
	display: "inline",
	fontWeight: 550,
	fontSize: "11px",
	marginTop: "5px",
	position: "absolute",
	paddingLeft: "5px"
}));