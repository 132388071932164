import { CoAppInlineInput, CoAppInlineInputHeader } from "../styled/global.styled";

export default function HeaderWithInlineEdit( props ) {
	const isEditing = props.isEditing;
	const handleOnClickEvent = props.handleOnClickEvent;
	const handleValueChange = props.handleValueChange;
	const handleValueSubmit = props.handleValueSubmit;
	const headerValue = props.headerValue;
	const inputValue = props.inputValue;

	if (isEditing) {
		return (
			<CoAppInlineInput
				autoFocus
				onChange={handleValueChange}
				onBlur={handleValueSubmit}
				type="text"
				value={inputValue}
			/>
		);
	} else {
		return (
			<CoAppInlineInputHeader sx={{ marginRight: "50px" }} onClick={handleOnClickEvent}>
				{headerValue}
			</CoAppInlineInputHeader>
		);
	}
}