import { Cancel, Check, Label } from "@mui/icons-material";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

import { CoAppDialogIconButton } from "../global/styled/global.styled";

import { TagIcon } from "./styled/tag-dialog.styled";

export default function TagDeletionDialog(props) {
	return (
		<Dialog open={props.deleteTagFlag} onClose={props.closeDeletionDialogHandler}>
			<DialogTitle>Are you sure you want to delete this tag?</DialogTitle>
			<TagIcon><Label />{props.tagBeingDeletedData.name}</TagIcon>
			<DialogActions>
				<CoAppDialogIconButton onClick={props.closeDeletionDialogHandler}><Cancel /></CoAppDialogIconButton>
				<CoAppDialogIconButton onClick={props.deleteTagHandler}><Check /></CoAppDialogIconButton>
			</DialogActions>
		</Dialog>
	);
}