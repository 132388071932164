import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import ConfirmationDialog from "../../../global/components/confirmation-dialog";
import { CoAppMainContainer, CoAppStack, CoAppStackItem, CoAppStatusToggle } from "../../../global/styled/global.styled";

import { PermissionCategoryTitle, PermissionNameDescContainer, RoleSubtitle, RoleTitle } from "./styled/role.styled";

export default function RolePermissions(props) {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [dialogDetails, setDialogDetails] = useState({ dialogOpen: false });
	const [permissions, setPermissions] = useState([]);
	const [permissionSeed, setPermissionSeed] = useState("");
	const [role, setRole] = useState({});

	const handleOpenConfirmDialog = (permission) => {
		setDialogDetails({
			cancelClickHandler: () => setDialogDetails({ dialogOpen: false }),
			confirmationTitle: `Are you sure you want to ${permission.active ? "remove" : "add"} ${permission.friendlyName} ?`,
			confirmClickHandler: () => togglePermission(permission),
			dialogOpen: true
		});
	};

	const init = () => {
		if (id) {
			axios.get(apiRoutes.getRoleById(id), { headers: authHeader })
				.then((response) => {
					setRole(response.data);
					axios.get(apiRoutes.getPermissions, { headers: authHeader })
						.then((res) => {
							res.data.forEach(permission => {
								response.data.permissions.forEach(rolePermission => {
									if (rolePermission.name === permission.name) {
										permission.active = true;
									}
								});
							});
							setPermissions(res.data);
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const togglePermission = (permission) => {
		axios.put(apiRoutes.updateRolePermission(role.id, permission.id), { active: !permission.active }, { headers: authHeader })
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(permission.active ? messages.PERMISSION_REMOVED_SUCCESS_MSG : messages.PERMISSION_ADDED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setDialogDetails({ dialogOpen: false });
					setPermissionSeed(Math.random());
				} else {
					dispatch(setMessage(permission.active ? messages.PERMISSION_REMOVED_ERROR_MSG : messages.PERMISSION_ADDED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(permission.active ? messages.PERMISSION_REMOVED_ERROR_MSG : messages.PERMISSION_ADDED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	useEffect(() => {
		init();
	}, [permissionSeed]);

	const groupedPermissions = {};
	permissions.forEach(permission => {
		if (!groupedPermissions[permission.category]) {
			groupedPermissions[permission.category] = [];
		}
		groupedPermissions[permission.category].push(permission);
	});

	return (
		<>
			<CoAppMainContainer sx={{ p: 2 }}>
				{Object.keys(groupedPermissions).map(category => (
					<div key={category}>
						<PermissionCategoryTitle>{category}</PermissionCategoryTitle>
						<CoAppStack space={1} sx={{ width: "100%" }}>
							{groupedPermissions[category].map((permission, index) => (
								<CoAppStackItem key={index}>
									<PermissionNameDescContainer>
										<RoleTitle>{permission.friendlyName}</RoleTitle>
										<RoleSubtitle>{permission.description}</RoleSubtitle>
									</PermissionNameDescContainer>
									<CoAppStatusToggle checked={permission.active} onChange={() => handleOpenConfirmDialog(permission)} sx={{ marginRight: "10px" }} />
								</CoAppStackItem>
							))}
						</CoAppStack>
					</div>
				))}
			</CoAppMainContainer>

			<ConfirmationDialog dialogDetails={dialogDetails} />
		</>
	);
}