import { Button, InputLabel, Select, styled, TextField } from "@mui/material";

export const SaveTagButton = styled(Button)(({ theme }) => ({
	color: "white",
	backgroundColor: "#2FBC70",
	margin: "10px",
	borderRadius: "10px",
	padding: "10px",
	"&:hover": {
		backgroundColor: "white",
		border: "1px solid #2FBC70",
		color: "black",
		fontWeight: "400"
	}
}));

export const CancelTagButton = styled(Button)(({ theme }) => ({
	color: "#7482A0",
	backgroundColor: "#E3E6EC",
	margin: "10px",
	borderRadius: "10px",
	padding: "10px",
	"&:hover": {
		backgroundColor: "white",
		color: "black",
		border: "1px solid #7482A0",
		fontWeight: "400"
	}
}));

export const DeleteTagButton = styled(Button)(({ theme }) => ({
	color: "white",
	backgroundColor: "red",
	margin: "10px",
	borderRadius: "10px",
	padding: "10px",
	"&:hover": {
		backgroundColor: "white",
		border: "1px solid red",
		color: "black",
		fontWeight: "400"
	}
}));

export const TagDialogTextField = styled(TextField)(() => ({
	"& .MuiOutlinedInput-root": {
		"&.Mui-focused fieldset": {
			border: "1px solid #A9A9A9",
		},
	},
	"& label.Mui-focused": {
		color: "#A9A9A9"
	},
	"&:hover fieldset": {
		border: "1px solid #A9A9A9"
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "1px solid #A9A9A9",
		borderRadius: "10px",
		"&:hover fieldset": {
			border: "1px solid #A9A9A9"
		}
	},
}));

export const TagDialogSelect = styled(Select)(() => ({
	"&.MuiOutlinedInput-root": {
		"& fieldset": {
			border: "1px solid #A9A9A9"
		},
		"&:hover fieldset": {
			border: "1px solid #A9A9A9"
		},
		"&.Mui-focused fieldset": {
			border: "1px solid #A9A9A9"
		}
	},
	width: "100%",
	borderRadius: "10px"
}));
export const TagDialogInputLabel = styled(InputLabel)(() => ({
	"&.MuiInputLabel-root": {
		color: "#A9A9A9"
	}
}));

export const TagIcon = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#E3E6EC" : "#E3E6EC",
	...theme.typography.body2,
	padding: theme.spacing(0.5),
	textAlign: "left",
	fontSize: "0.70rem",
	fontWeight: "700",
	color: "#7482A0",
	width: "auto",
	margin: "auto",
	borderRadius: "5px",
	alignItems: "center",
	display: "flex"
}));