import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectOrganizationSsoAndScim } from "../../../../redux/userSlice";
import ConfirmationDialog from "../../../global/components/confirmation-dialog";
import HeaderWithInlineEdit from "../../../global/components/header-with-inline-edit";
import { CoAppButtonWhiteBg, CoAppMainContainer } from "../../../global/styled/global.styled";
import SettingsMenuBack from "../../settings-menu-back";
import { MainMenuContainer, MainMenuItem, SettingMenuContainer, SettingsDivider } from "../../styled/settings.styled";

import RolePermissions from "./role-permissions";
import RoleSSO from "./role-sso";
import RoleUsers from "./role-users";

export default function Role() {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id } = useParams();

	const [deleteDialogDetails, setDeleteDialogDetails] = useState({ dialogOpen: false });
	const [isEditing, setIsEditing] = useState(false);
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const [role, setRole] = useState({});
	const [roleNameInput, setRoleNameInput] = useState("");
	const [rolePermissions, setRolePermissions] = useState([]);
	const [selectedMenuItem, setSelectedMenuItem] = useState(null);

	const menuItems = [
		{ key: "users", label: "USERS", value: pages.roleUsers },
		{ key: "permissions", label: "PERMISSIONS", value: pages.rolePermissions },
		organizationSsoAndScim ? { key: "sso", label: "SSO/SCIM CONFIGURATION", value: pages.roleSSO } : null
	].filter(item => item !== null);

	const confirmRoleDeletion = () => {
		axios.delete(apiRoutes.deleteRole + "/" + role.id, {
			headers: authHeader
		}).then((res) => {
			if (res.status === 200) {
				dispatch(setMessage(messages.ROLE_DELETION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				navigate(pages.settings + "?from=roles");
			} else {
				console.log(res);
				dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			}
		}).catch((err) => {
			console.log(err);
			dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
		});
	};

	const handleMenuItemClick = (menuItem) => {
		setSelectedMenuItem(menuItem);
	};

	const handleRoleNameEdit = () => {
		setIsEditing(true);
		setRoleNameInput(role.name);
	};

	const handleRoleNameChange = (e) => {
		let value = e.target.value;
		if (value.trim() === "") {
			return;
		}

		setRoleNameInput(value);
		axios.put(apiRoutes.updateRole(id), { name: value }, { headers: authHeader })
			.then(() => {
				dispatch(setMessage(messages.ROLE_NAME_UPDATED_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch(() => {
				dispatch(setMessage(messages.ROLE_NAME_UPDATE_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const handleRoleNameSubmit = () => {
		setIsEditing(false);
		setRole({ ...role, name: roleNameInput });
	};

	const initRole = () => {
		if (id) {
			axios.get(apiRoutes.getRoleById(id), { headers: authHeader })
				.then((response) => {
					setRole(response.data);
					setRolePermissions(response.data.permissions);
					setSelectedMenuItem(pages.roleUsers);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const toggleDeleteRoleDialog = () => {
		if (!deleteDialogDetails.dialogOpen) {
			let dialogContent = {
				dialogOpen: true,
				confirmationMessage: "",
				confirmationListItems: [],
				confirmationTitle: messages.ROLE_DELETION_CONFIRMATION_MSG,
				confirmClickHandler: () => {
					confirmRoleDeletion();
				},
				cancelClickHandler: () => {
					toggleDeleteRoleDialog();
				}
			};
			setDeleteDialogDetails(dialogContent);
		} else {
			setDeleteDialogDetails({dialogOpen: false});
		}
	};

	useEffect(() => {
		initRole();
	}, []);

	if (role) {
		return (
			<>
				<CoAppMainContainer sx={{ p: 2 }}>
					<SettingsMenuBack buttonText="All Roles" navigateTo={pages.settings + "?from=roles" } />
				</CoAppMainContainer>
				<CoAppMainContainer sx={{ p: 2 }}>
					<SettingMenuContainer>
						<MainMenuContainer>
							<HeaderWithInlineEdit
								isEditing={isEditing}
								handleOnClickEvent={handleRoleNameEdit}
								handleValueChange={handleRoleNameChange}
								handleValueSubmit={handleRoleNameSubmit}
								headerValue={role.name}
								inputValue={roleNameInput} />

							{menuItems.map((menuItem) => (
								menuItem && menuItem.value &&
								<MainMenuItem
									key={menuItem.key}
									className={selectedMenuItem === menuItem.value ? "active" : ""}
									onClick={() => handleMenuItemClick(menuItem.value)}
								>
									{menuItem.label}
								</MainMenuItem>
							))}
						</MainMenuContainer>

						<CoAppButtonWhiteBg onClick={toggleDeleteRoleDialog}><Delete /> Delete Role</CoAppButtonWhiteBg>
						<SettingsDivider />
					</SettingMenuContainer>
				</CoAppMainContainer>

				<CoAppMainContainer sx={{ p: 2 }}>
					{selectedMenuItem === pages.roleUsers && <RoleUsers role={role} />}
					{selectedMenuItem === pages.rolePermissions && <RolePermissions permissions={rolePermissions} role={role} />}
					{selectedMenuItem === pages.roleSSO && <RoleSSO role={role} />}
				</CoAppMainContainer>

				<ConfirmationDialog dialogDetails={deleteDialogDetails} />
			</>
		);
	} else {
		<></>;
	}
}