import { Container, IconButton, Stack, styled, Typography } from "@mui/material";

/**
 * Reaction sidebar specific.
 */
export const RuleReactionSidebarContainer = styled(Container)(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	height: "700px",
	width: "300px",
	paddingLeft: "5px!important",
	paddingRight: "5px!important",
	padding: "10px",
	border: "1px solid #A9A9A9",
	borderRadius: theme.spacing(2),
	boxShadow: "0 1px 3px grey",
	marginTop: "70px"
}));
/**
 * End reaction sidebar specific.
 */


/**
 * Trigger sidebar specific.
 */
export const RuleTriggerSidebarContainer = styled(Container)(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	height: "600px",
	width: "300px",
	paddingLeft: "5px!important",
	paddingRight: "0px!important",
	padding: "10px",
	border: "1px solid #A9A9A9",
	borderRadius: theme.spacing(2),
	boxShadow: "0 1px 3px grey",
	marginTop: "18px",
	marginRight: "100px"
}));
/**
 * End trigger sidebar specific.
 */


/**
 * Rule wizard sidebars agnostic.
 */
export const RuleSidebarContents = styled(Stack)(({ step }) => ({
	overflowY: "auto",
	overflowX: "hidden",
	maxHeight: step === 2 ? "500px" : "500px",
	"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
		backgroundColor: "lightgrey",
		width: "0.8em",
		borderRadius: 8
	},
	"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
		borderRadius: 8,
		backgroundColor: "#6b6b6b"
	},
	"&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
		backgroundColor: "#2FBC70",
	},
	"&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
		backgroundColor: "#2FBC70",
	},
	"&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#2FBC70",
	},

}));


export const RuleSidebarTitle = styled(Typography)(({ theme }) => ({
	display: "inline",
	fontWeight: "600",
	fontSize: "1.5rem",
	textAlign: "center"
}));


export const RuleSidebarRow = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	width: "90%",
	padding: "5px",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between"
}));

export const RuleSidebarRowActions = styled("div")(({ theme }) => ({
	float: "right",
	margin: "auto 0"
}));

export const RuleSidebarRowButton = styled(IconButton)(({ theme }) => ({
	color: "#2FBC70",
	verticalAlign: "middle",

	"&.MuiSvgIcon-root": {
		verticalAlign: "middle",
		"&:hover": {
			cursor: "pointer",
			background: "none",
			color: "#A9A9A9"
		}
	}
}));

export const RuleSidebarRowFieldName = styled("div")(({ theme }) => ({
	fontSize: "1.2rem",
	fontWeight: "600",
}));

export const RuleSidebarRowType = styled("div")(({ theme }) => ({
	fontSize: ".8rem",
	fontWeight: "400",
	color: "#7482A0"
}));
/**
 * End rule wizard sidebars agnostic.
 */